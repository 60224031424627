import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import logo from '../../assets/logo.png';
import { prontuarioReset } from '../../redux/features/prontuario/prontuarioSlice';
import { ergoReset } from '../../redux/features/laudo/ergoSlice';
import { ecoReset } from '../../redux/features/laudo/ecoSlice';
import { ecgReset } from '../../redux/features/laudo/ecgSlice';
import { stressReset } from '../../redux/features/laudo/stressSlice';
import { certificateReset } from '../../redux/features/certificate/certificateSlice';
import { laboratorioReset } from '../../redux/features/prontuario/laboratorioSlice';
import { fetalReset } from '../../redux/features/laudo/fetalSlice';
import { transReset } from '../../redux/features/laudo/transSlice';
import { pacienteReset } from '../../redux/features/prontuario/pacienteSlice';
import { medicoReset } from '../../redux/features/prontuario/medicoSlice';
import { agendaReset } from '../../redux/features/prontuario/agendaSlice';
import { faturamentoReset } from '../../redux/features/context/faturamentoSlice';
import { convenioReset } from '../../redux/features/context/convenioSlice';
import { procedimentoReset } from '../../redux/features/context/procedimentoSlice';
import { holterReset } from '../../redux/features/laudo/holterSlice';
import { mapaReset } from '../../redux/features/laudo/mapaSlice';
import { signOutMed } from '../../store/modules/auth/doctor/actions';
import { signOutFunc } from '../../store/modules/auth/funcionario/actions';
import { signOutPac } from '../../store/modules/auth/patient/actions';
import history from '../../services/history';

export default function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(prontuarioReset());
    dispatch(laboratorioReset());
    dispatch(ergoReset());
    dispatch(ecoReset());
    dispatch(ecgReset());
    dispatch(fetalReset());
    dispatch(stressReset());
    dispatch(transReset());
    dispatch(pacienteReset());
    dispatch(medicoReset());
    dispatch(convenioReset());
    dispatch(procedimentoReset());
    dispatch(agendaReset());
    dispatch(faturamentoReset());
    dispatch(certificateReset());
    dispatch(holterReset());
    dispatch(mapaReset());
    dispatch(signOutMed());
    dispatch(signOutPac());
    dispatch(signOutFunc());
  }, [dispatch]);

  const handleClickICM = event => {
    event.preventDefault();
    window.location.target = '_blank';
    window.location.href = '//www.icm.com.br';
  };

  return (
    <div className="row justify-content-md-center">
      <div className="mt-5">
        <h3 className="text-center text-success font-weight-bold">
          Instituto do Coração de Marília
        </h3>
        <div className="text-center">
          <img src={logo} alt="logo" width={70} height={70} />
        </div>
        <Card className="m-2">
          <Card.Body>
            <Card.Title>Paciente</Card.Title>
            <Button
              variant="outline-success"
              size="lg"
              block
              onClick={() => history.push('/plogin')}
            >
              Login Paciente
            </Button>
          </Card.Body>
        </Card>
        <Card className="m-2">
          <Card.Body>
            <Card.Title>Hospital - Exames Internado e Amb.</Card.Title>
            <Button
              variant="outline-success"
              size="lg"
              block
              onClick={() => history.push('/externo')}
            >
              Login Hospital
            </Button>
          </Card.Body>
        </Card>
        <Card className="m-2">
          <Card.Body>
            <Card.Title>Médicos do Corpo Clínico do ICM</Card.Title>
            <Button
              variant="outline-success"
              size="lg"
              block
              onClick={() => history.push('/mlogin')}
            >
              Login Médico
            </Button>
          </Card.Body>
        </Card>
        <Card className="m-2">
          <Card.Body>
            <Card.Title>Funcionários do ICM</Card.Title>
            <Button
              variant="outline-success"
              size="lg"
              block
              onClick={() => history.push('/flogin')}
            >
              Login Funcionário
            </Button>
          </Card.Body>
        </Card>
        <Card className="m-2">
          <Card.Body>
            <Button
              variant="outline-danger"
              size="lg"
              block
              onClick={handleClickICM}
            >
              Sair
            </Button>
          </Card.Body>
        </Card>
        <p className="text-center mt-3 mb-3 text-muted">
          <a
            href="https://www.instagram.com/moraestechnology/"
            className="fa fa-instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            ©MoraesTech v280 - 14/10/2024
          </a>
        </p>
      </div>
    </div>
  );
}
