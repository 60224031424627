import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import validarCPF from 'validar-cpf';
import history from '../../../../services/history';
import api from '../../../../services/api';
import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { cpf, senha } = payload;

    if (!validarCPF(cpf)) {
      toast.error('CPF do Paciente é inválido.');
      return;
    }

    const response = yield call(api.post, 'psessions', { cpf, senha });
    const { token, paciente } = response.data;
    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, paciente));

    history.push('/dashboardpac');
  } catch (error) {
    toast.error(`Falha na autenticação do paciente! Verifique seus dados.`);
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.patient;
  if (token) api.defaults.headers.Authorization = `Bearer ${token}`;
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@patient/SIGN_IN_REQUEST', signIn),
  takeLatest('@patient/SIGN_OUT', signOut),
]);
