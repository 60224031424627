import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Form } from 'react-bootstrap';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import styles from '../../../styles/styles.module.css';
import medicoAgenda from '../../../util/medicoAgenda';

export default function FormFechamento() {
  const { fechamento } = useSelector(state => state.faturamento);
  const medicosSorted = useMemo(
    () =>
      medicoAgenda
        .slice(0, 12)
        .sort((a, b) => a.medico.localeCompare(b.medico)),
    []
  );
  const dataFormatada = useMemo(
    () =>
      fechamento[0]?.DATALAUDO &&
      format(addDays(parseISO(fechamento[0].DATALAUDO), 1), 'dd/MM/yyyy', {
        locale: pt,
      }),
    [fechamento]
  );
  const [filterFechamento, setFilterFechamento] = useState(fechamento);
  const [selectedMedico, setSelectedMedico] = useState([]);
  const [showFechamento, setShowFechamento] = useState(true);

  function removeDiv(divName) {
    let newdiv = document.createElement('div');
    let originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.print();
    document.body.innerHTML = originalContents;
  }

  // Função para agrupar os dados por médico e por data
  function agruparDadosPorMedicoEData(dados) {
    const relatorio = {};
    dados.forEach(item => {
      const chaveMedico = `${item.CODMEDICO}-${item.NOMEMED}`;
      const data = new Date(item.DATALAUDO).toLocaleDateString('pt-BR');

      if (!relatorio[chaveMedico]) relatorio[chaveMedico] = {};

      if (!relatorio[chaveMedico][data]) relatorio[chaveMedico][data] = [];

      relatorio[chaveMedico][data].push(item);
    });
    return relatorio;
  }

  // Função para calcular o total de valores por médico
  function calcularTotalPorMedico(relatorio) {
    const totalPorMedico = {};
    for (const medico in relatorio) {
      totalPorMedico[medico] = {};
      for (const data in relatorio[medico]) {
        totalPorMedico[medico][data] = relatorio[medico][data].reduce(
          (total, item) => total + item.VLRLAUDO,
          0
        );
      }
    }
    return totalPorMedico;
  }

  // Função para formatar o relatório em tabela HTML separada por médico com valores em reais e largura de colunas padronizada
  function formatarRelatorioPorMedicoHTML(relatorio, totalPorMedico) {
    let valorTotalGeral = 0;
    let resultado = '';
    for (const medico in relatorio) {
      resultado += `<h5>Médico: ${medico.split('-')[1]}</h5>`;
      resultado +=
        '<table border="1" style="width: 100%; border-collapse: collapse;"><tr><th style="width: 40%;">Paciente</th><th style="width: 40%;">Procedimento</th><th style="width: 20%;">Valor</th></tr>';
      for (const data in relatorio[medico]) {
        valorTotalGeral += totalPorMedico[medico][data];
        // eslint-disable-next-line
        relatorio[medico][data].forEach(item => {
          resultado += `<tr><td>${item.NOMEPAC}</td><td>${
            item.DESCPROCEDI
          }</td><td>${item.VLRLAUDO.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}</td></tr>`;
        });
        resultado += `<tr><td colspan="2" align="right"><strong>Total:&nbsp;</strong>  </td><td><strong>${totalPorMedico[
          medico
        ][data].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}</strong></td></tr>`;
      }
      resultado += '</table>';
    }

    resultado += `<strong style="display: block; text-align: center;">Total geral: ${valorTotalGeral.toLocaleString(
      'pt-BR',
      { style: 'currency', currency: 'BRL' }
    )}</strong>`;

    return resultado;
  }

  const handleSelectMedico = agenda => {
    setSelectedMedico([...selectedMedico, agenda.codigo]);
    setFilterFechamento(fechamento);
    setShowFechamento(false);
  };

  const handleFilterFechamento = () => {
    const filterFat = filterFechamento.filter(item =>
      selectedMedico.includes(item.CODMEDICO)
    );
    setFilterFechamento(filterFat);
    setShowFechamento(true);
  };

  const handleLimparFiltros = () => {
    setSelectedMedico([]);
    setFilterFechamento(fechamento);
    setShowFechamento(false);
  };

  useEffect(() => {
    // Obtém o relatório agrupado por médico e por data
    const relatorioAgrupado = agruparDadosPorMedicoEData(filterFechamento);

    // Calcula o total de valores por médico
    const totalPorMedico = calcularTotalPorMedico(relatorioAgrupado);

    // Formata e exibe o relatório em tabela HTML
    const relatorioHTML = formatarRelatorioPorMedicoHTML(
      relatorioAgrupado,
      totalPorMedico
    );

    if (showFechamento) {
      const fechamentoDiv = document.getElementById('fechamento');
      if (fechamentoDiv) fechamentoDiv.innerHTML = relatorioHTML;
    }
  }, [filterFechamento, showFechamento]);

  return (
    <Card id="cardHeader" style={{ marginTop: 10 }}>
      <div id="printMe">
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            FECHAMENTO DIÁRIO - {dataFormatada}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="text-center">
            {medicosSorted.map(agenda => (
              <Form.Check
                id={agenda.codigo}
                key={agenda.codigo}
                inline
                checked={selectedMedico.includes(agenda.codigo)}
                type="checkbox"
                label={agenda.medico}
                size="sm"
                color="outline-success"
                onClick={() => handleSelectMedico(agenda)}
              />
            ))}
          </div>
        </Card.Body>
        <div id="removeMe" className="d-grid gap-2 text-center">
          <Button variant="success" onClick={handleFilterFechamento}>
            Filtrar Fechamento
          </Button>{' '}
          <Button variant="primary" onClick={handleLimparFiltros}>
            Limpar Filtros
          </Button>{' '}
        </div>
      </div>
      <hr></hr>

      <div id="fechamento"></div>

      <div id="removeMe" className="text-center mt-3">
        <Button variant="outline-primary" onClick={() => removeDiv('removeMe')}>
          Imprimir
        </Button>
      </div>
    </Card>
  );
}
