import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';
import {
  Form,
  Col,
  Button,
  Card,
  Modal,
  Spinner,
  Alert,
  Badge,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdDeleteForever } from 'react-icons/md';
import history from '../../../services/history';
import api from '../../../services/api';
import { deleteScan, getScan } from '../../../services/carotidas';
import dblaudo from '../../../util/laudo';
import CustomFileInput from '../../../components/CustomFileInput';

export default function FormCarotida() {
  const location = useLocation();
  const { codLaudo } = location.state;
  const { data: carotida, error, isLoading } = useSWR('carotida', () =>
    getScan(codLaudo)
  );
  const medico = useSelector(state => state.doctor.profile);
  const [showUpload, setShowUpload] = useState(false);
  const [defaultValues, setDefaultValues] = useState(carotida && carotida);
  const [fileToUpload, setFileToUpload] = useState([]);
  const [gravado, setGravado] = useState('');
  const valuesRef = useRef([]);

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  useEffect(() => {
    setDefaultValues(carotida);
    setGravado(carotida?.carotida[0].GRAVADO);
  }, [carotida]);

  const deleteLocalItem = (index, tipo) => {
    const updatedArray = defaultValues[tipo].filter((_, i) => i !== index);
    setDefaultValues(prev => ({ ...prev, [tipo]: updatedArray }));
  };

  const deleteScanFromDB = async (item, type) => {
    try {
      await deleteScan(item, type);
      mutate('carotida');
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const goToRowAbove = tipo => {
    const focusMap = {
      cid: 'ccdCodAnatomia',
      ced: 'cidCodAnatomia',
      avd: 'cedCodAnatomia',
      cce: 'avdCodAnatomia',
      cie: 'cceCodAnatomia',
      cee: 'cieCodAnatomia',
      ave: 'ceeCodAnatomia',
    };

    const prevElementId = focusMap[tipo];
    if (prevElementId) document.getElementById(prevElementId)?.focus();
  };

  const goToRowBelow = (event, tipo) => {
    if (event.key === 'Enter') {
      const element = document.getElementById(tipo);
      if (element) {
        element.focus();
        element.select();
      }
    }
  };

  const goToRowBelowOnArrowDown = tipo => {
    setDefaultValues(prevState => ({
      ...prevState,
      [tipo]: [...prevState[tipo], valuesRef.current[tipo]],
    }));

    const focusMap = {
      ccd: 'cidCodAnatomia',
      cid: 'cedCodAnatomia',
      ced: 'avdCodAnatomia',
      avd: 'cceCodAnatomia',
      cce: 'cieCodAnatomia',
      cie: 'ceeCodAnatomia',
      cee: 'aveCodAnatomia',
    };

    const nextElementId = focusMap[tipo];
    if (nextElementId) document.getElementById(nextElementId)?.focus();
  };

  const handleAnatomia = async (event, tipo) => {
    event.persist(); // Prevent the event from being reused
    event.preventDefault();

    if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'Enter'].includes(event.key)) {
      const anatomia = dblaudo.scan.anatomia.find(
        a => a.CODANATOMIA === Number(event.target.value)
      );

      if (!anatomia) return;

      valuesRef.current = {
        tipo,
        CODSCAN: codLaudo,
        [tipo]: {
          ...valuesRef.current[tipo],
          ANATOMIA: anatomia.ANATOMIA ?? null,
          CODANATOMIA: anatomia.CODANATOMIA ?? null,
          CODSCAN: codLaudo,
        },
      };

      if (event.key === 'ArrowUp') {
        goToRowAbove(tipo);
      } else if (event.key === 'ArrowRight') {
        // Conditional navigation based on CODANATOMIA
        if (anatomia.CODANATOMIA === 44) {
          document.getElementById(`${tipo}Medida`).focus();
        } else {
          document.getElementById(`${tipo}CodVelocidade`).focus();
        }
      } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
        goToRowBelowOnArrowDown(tipo);
      }
    }
  };

  const handleMedida = (event, tipo) => {
    event.preventDefault();

    if (event.key === 'ArrowUp') goToRowAbove(tipo);

    if (['ArrowRight', 'Enter'].includes(event.key)) {
      valuesRef.current = {
        tipo,
        CODSCAN: codLaudo,
        [tipo]: {
          ...valuesRef.current[tipo],
          MEDIDA: event?.target?.value ?? null,
        },
      };

      const nextField = document.getElementById(`${tipo}CodVelocidade`);
      if (nextField) {
        nextField.focus();
        nextField.select();
      }
    }
  };

  const handleVelocidade = async (event, tipo) => {
    event.persist();
    event.preventDefault();

    if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'Enter'].includes(event.key)) {
      const velocidade = dblaudo.scan.velocidade.find(
        v => v.CODVELOCIDADE === Number(event.target.value)
      );

      if (!velocidade) return;

      valuesRef.current = {
        tipo,
        CODSCAN: codLaudo,
        [tipo]: {
          ...valuesRef.current[tipo],
          VELOCIDADE: velocidade.VELOCIDADE ?? null,
          CODVELOCIDADE: velocidade.CODVELOCIDADE ?? null,
          CODSCAN: codLaudo,
        },
      };

      if (event.key === 'ArrowUp') {
        goToRowAbove(tipo);
      } else if (event.key === 'ArrowRight') {
        document.getElementById(`${tipo}CodIntensidade`).focus();
      } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
        goToRowBelowOnArrowDown(tipo);
      }
    }
  };

  const handleIntensidade = async (event, tipo) => {
    event.persist(); // Prevent the event from being reused
    event.preventDefault();

    if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'Enter'].includes(event.key)) {
      const intensidade = dblaudo.scan.intensidade.find(
        i => i.CODINTENSIDADE === Number(event.target.value)
      );

      if (!intensidade) return;

      valuesRef.current = {
        tipo,
        CODSCAN: codLaudo,
        [tipo]: {
          ...valuesRef.current[tipo],
          INTENSIDADE: intensidade.INTENSIDADE ?? null,
          CODINTENSIDADE: intensidade.CODINTENSIDADE ?? null,
          CODSCAN: codLaudo,
        },
      };

      if (event.key === 'ArrowUp') {
        goToRowAbove(tipo);
      } else if (event.key === 'ArrowRight') {
        document.getElementById(`${tipo}CodAnatomia`).focus();
      } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
        goToRowBelowOnArrowDown(tipo);
      }
    }
  };

  const handleGravar = async e => {
    e.preventDefault();
    try {
      const { data } = await api.post(
        `/carotida/${carotida.CODSCAN}`,
        defaultValues
      );
      mutate('carotida');
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  async function handleLiberarLaudo() {
    try {
      const response = await api.put(`/atividade/liberacao/`, {
        codLaudo: carotida.CODSCAN,
        codPac: carotida.CODPAC,
      });
      toast.success(response?.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }

  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    try {
      const encodedFilename = encodeURIComponent(file.name);
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/carotida/laudo/${codLaudo}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
      toast.success('Arquivo enviado com sucesso!');
    } catch (error) {
      toast.error('Erro ao enviar arquivo!');
    }
  }

  const handleDefaultAnatomia = () => {
    setDefaultValues(prevValues => ({
      ...prevValues,
      ccd: [
        {
          ...(prevValues.ccd?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      cid: [
        {
          ...(prevValues.cid?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      ced: [
        {
          ...(prevValues.ced?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      avd: [
        {
          ...(prevValues.avd?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      cce: [
        {
          ...(prevValues.cce?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      ave: [
        {
          ...(prevValues.ave?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      cie: [
        {
          ...(prevValues.cie?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      cee: [
        {
          ...(prevValues.cee?.[0] || {}),
          ANATOMIA: 'Anatomia e fluxos normais.',
          CODANATOMIA: 1,
          INTENSIDADE: null,
          CODINTENSIDADE: null,
          VELOCIDADE: null,
          CODVELOCIDADE: null,
          CODSCAN: codLaudo,
        },
      ],
      carotida: [
        {
          ...prevValues.carotida[0],
          CODSCAN: codLaudo,
          OBSERVACOES: prevValues.carotida[0].OBSERVACOES || '',
        },
        ...(prevValues.carotida?.slice(1) || []),
      ],
    }));
  };

  if (error) {
    return (
      <Alert variant="danger" style={{ marginTop: 10 }}>
        <Alert.Heading>Erro ao carregar laudo!</Alert.Heading>
        <p>Tente novamente.</p>
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <Spinner animation="border" variant="dark" size="lg" />
      </div>
    );
  }

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE SCAN DE CARÓTIDAS</Card.Title>
          <Badge variant={gravado === 'SIM' ? 'success' : 'warning'}>
            {gravado === 'SIM' ? 'GRAVADO' : 'NÃO GRAVADO'}
          </Badge>
        </Card.Header>

        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${defaultValues?.carotida[0]?.CODPAC} - ${defaultValues?.carotida[0]?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${defaultValues?.carotida[0]?.dataNasc}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {defaultValues?.carotida[0]?.SEXOPAC}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${defaultValues?.carotida[0]?.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {defaultValues?.carotida[0]?.SIGLACONV}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod Laudo: </strong>
            {defaultValues?.carotida[0]?.CODSCAN}
            &nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {defaultValues?.carotida[0]?.dataScanFmt}
            <br></br>
            <strong className="mr-auto">Médico que Realizou: Dr. </strong>
            {defaultValues?.carotida[0]?.Realizou}
            <br></br>
            <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
            {defaultValues?.carotida[0]?.Solicitante}
            <Card.Header className="text-center" style={{ marginTop: 5 }}>
              <Button onClick={() => handleDefaultAnatomia()}></Button>
              <Card.Title>
                <strong>
                  <u>SISTEMA CAROTÍDEO DIREITO</u>
                </strong>
              </Card.Title>
            </Card.Header>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="ccd">
                  <u className="mr-auto">Carótida Comum Direita: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="ccdCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            // Ensure handleAnatomia finishes before going to the next row
                            await handleAnatomia(event, 'ccd');
                            goToRowBelow(event, 'cidCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'ccd');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="ccdCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'ccd');
                            goToRowBelow(event, 'cidCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'ccd');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="ccdCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'ccd');
                            goToRowBelow(event, 'cidCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'ccd');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'ccd')
                    .map(tipo => (
                      <div key={tipo}>
                        {Array.isArray(defaultValues[tipo]) &&
                          defaultValues[tipo]?.map(
                            (item, index) =>
                              item?.CODSCAN === codLaudo &&
                              (item?.CODANATOMIA === 44 ? (
                                <div key={index} className="row">
                                  <div className="col-12">
                                    {item?.ANATOMIA} {item?.MEDIDA} mm{' '}
                                    {item?.VELOCIDADE} {item?.INTENSIDADE} -{' '}
                                    <MdDeleteForever
                                      color="red"
                                      cursor="pointer"
                                      onClick={() => {
                                        gravado === 'SIM'
                                          ? deleteScanFromDB(item, 'ccd')
                                          : deleteLocalItem(index, tipo);
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div key={index} className="row">
                                  <div className="col-12">
                                    {`${
                                      item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                    }${
                                      item?.CODANATOMIA && item?.CODVELOCIDADE
                                        ? ' - '
                                        : ''
                                    }${
                                      item?.CODVELOCIDADE
                                        ? item?.CODVELOCIDADE
                                        : ''
                                    }${
                                      item?.CODVELOCIDADE &&
                                      item?.CODINTENSIDADE
                                        ? ' - '
                                        : ''
                                    }${
                                      item?.CODINTENSIDADE
                                        ? item?.CODINTENSIDADE
                                        : ''
                                    }`}
                                    : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                    {item?.INTENSIDADE} -{' '}
                                    <MdDeleteForever
                                      color="red"
                                      cursor="pointer"
                                      onClick={() => {
                                        gravado === 'SIM'
                                          ? deleteScanFromDB(item, 'ccd')
                                          : deleteLocalItem(index, tipo);
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                          )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="cid">
                  <u className="mr-auto">Carótida Interna Direita: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="cidCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            // Ensure handleAnatomia finishes before going to the next row
                            await handleAnatomia(event, 'cid');
                            goToRowBelow(event, 'cedCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'cid');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cidCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            // Ensure handleAnatomia finishes before going to the next row
                            await handleVelocidade(event, 'cid');
                            goToRowBelow(event, 'cedCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'cid');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cidCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            // Ensure handleAnatomia finishes before going to the next row
                            await handleIntensidade(event, 'cid');
                            goToRowBelow(event, 'cedCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'cid');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'cid')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo && (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'cid')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="ced">
                  <u className="mr-auto">Carótida Externa Direita: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="cedCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleAnatomia(event, 'ced');
                            goToRowBelow(event, 'avdCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'ced');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cedCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'ced');
                            goToRowBelow(event, 'avdCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'ced');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cedCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'ced');
                            goToRowBelow(event, 'avdCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'ced');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'ced')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo && (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'ced')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="avd">
                  <u className="mr-auto">Artéria Vertebral Direita: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="avdCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleAnatomia(event, 'avd');
                            goToRowBelow(event, 'cceCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'avd');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="avdCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'avd');
                            goToRowBelow(event, 'cceCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'avd');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="avdCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'avd');
                            goToRowBelow(event, 'cceCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'avd');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'avd')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo && (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'avd')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Card.Header className="text-center">
              <Card.Title>
                <strong>
                  <u>SISTEMA CAROTÍDEO ESQUERDO</u>
                </strong>
              </Card.Title>
            </Card.Header>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="cce">
                  <u className="mr-auto">Carótida Comum Esquerda: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="cceCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleAnatomia(event, 'cce');
                            goToRowBelow(event, 'cieCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'cce');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cceMedida">
                      <Form.Label>
                        <strong>Medida</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleMedida(event, 'cce');
                            goToRowBelow(event, 'cieCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleMedida(event, 'cce');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cceCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'cce');
                            goToRowBelow(event, 'cieCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'cce');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cceCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'cce');
                            goToRowBelow(event, 'cieCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'cce');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'cce')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo &&
                            (item?.CODANATOMIA === 44 ? (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {item?.ANATOMIA} {item?.MEDIDA} mm{' '}
                                  {item?.VELOCIDADE} {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'cce')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'cce')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="cie">
                  <u className="mr-auto">Carótida Interna Esquerda: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="cieCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleAnatomia(event, 'cie');
                            goToRowBelow(event, 'ceeCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'cie');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cieCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'cie');
                            goToRowBelow(event, 'ceeCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'cie');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="cieCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'cie');
                            goToRowBelow(event, 'ceeCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'cie');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'cie')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo && (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'cie')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="cee">
                  <u className="mr-auto">Carótida Externa Esquerda: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="ceeCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleAnatomia(event, 'cee');
                            goToRowBelow(event, 'aveCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'cee');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="ceeCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'cee');
                            goToRowBelow(event, 'aveCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'cee');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="ceeCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'cee');
                            goToRowBelow(event, 'aveCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'cee');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'cee')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo && (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'cee')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="ave">
                  <u className="mr-auto">Artéria Vertebral Esquerda: </u>
                  <Form.Row>
                    <Form.Group as={Col} sm controlId="aveCodAnatomia">
                      <Form.Label>
                        <strong>Anatomia</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleAnatomia(event, 'ave');
                            goToRowBelow(event, 'aveCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleAnatomia(event, 'ave');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="aveCodVelocidade">
                      <Form.Label>
                        <strong>Velocidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleVelocidade(event, 'ave');
                            goToRowBelow(event, 'aveCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleVelocidade(event, 'ave');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm controlId="aveCodIntensidade">
                      <Form.Label>
                        <strong>Intensidade</strong>
                      </Form.Label>
                      <Form.Control
                        onKeyDown={async event => {
                          if (
                            event.key === 'ArrowDown' ||
                            event.key === 'Enter'
                          ) {
                            await handleIntensidade(event, 'ave');
                            goToRowBelow(event, 'aveCodAnatomia');
                          }
                          if (['ArrowUp', 'ArrowRight'].includes(event.key)) {
                            handleIntensidade(event, 'ave');
                          }
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>
                  {Object.keys(defaultValues || {})
                    .filter(tipo => tipo === 'ave')
                    .map(tipo => (
                      <div key={tipo}>
                        {defaultValues[tipo]?.map(
                          (item, index) =>
                            item?.CODSCAN === codLaudo && (
                              <div key={index} className="row">
                                <div className="col-12">
                                  {`${
                                    item?.CODANATOMIA ? item?.CODANATOMIA : ''
                                  }${
                                    item?.CODANATOMIA && item?.CODVELOCIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE
                                      ? item?.CODVELOCIDADE
                                      : ''
                                  }${
                                    item?.CODVELOCIDADE && item?.CODINTENSIDADE
                                      ? ' - '
                                      : ''
                                  }${
                                    item?.CODINTENSIDADE
                                      ? item?.CODINTENSIDADE
                                      : ''
                                  }`}
                                  : {item?.ANATOMIA} {item?.VELOCIDADE}{' '}
                                  {item?.INTENSIDADE} -{' '}
                                  <MdDeleteForever
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => {
                                      gravado === 'SIM'
                                        ? deleteScanFromDB(item, 'ave')
                                        : deleteLocalItem(index, tipo);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="editDescricao">
                  <Form.Label>
                    <strong>OBSERVAÇÕES</strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={defaultValues?.carotida[0]?.OBSERVACOES}
                    onChange={e => {
                      e.persist();
                      setDefaultValues(prevValues => ({
                        ...prevValues,
                        carotida: [
                          {
                            ...prevValues.carotida[0],
                            OBSERVACOES: e.target.value,
                          },
                          ...prevValues.carotida.slice(1),
                        ],
                      }));
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Card.Footer className="text-center">
              <Button variant="success" onClick={e => handleGravar(e)}>
                Gravar
              </Button>{' '}
              <Button onClick={handleLiberarLaudo}>Liberar Laudo</Button>{' '}
              <Button
                variant="secondary"
                onClick={() => {
                  history.push({
                    pathname: '/reportCarotida',
                    state: {
                      codLaudo: carotida.carotida[0].CODSCAN,
                      printing: false,
                    },
                  });
                }}
              >
                Imprimir
              </Button>{' '}
              <Button
                variant="secondary"
                onClick={() => {
                  history.push({
                    pathname: '/reportCarotida',
                    state: {
                      codLaudo: carotida.carotida[0].CODSCAN,
                      printing: true,
                    },
                  });
                }}
              >
                Imprimir Timbrado
              </Button>{' '}
              <Button variant="warning" onClick={() => setShowUpload(true)}>
                Anexar
              </Button>{' '}
              <Button variant="danger" onClick={() => history.push('/agenda')}>
                Fechar
              </Button>
            </Card.Footer>
          </Form>
        </Card.Body>
      </Card>

      {showUpload && (
        <Modal
          show={showUpload}
          onHide={() => {
            setShowUpload(false);
            setFileToUpload();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Anexar arquivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomFileInput
              id="file-upload"
              label="Selecionar arquivo"
              multiple={false}
              onChange={e => setFileToUpload(e.target.files)}
            />
            <ul>
              {fileToUpload?.length > 0 && <li>{fileToUpload[0].name}</li>}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleUploadFile(fileToUpload[0])}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
