import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import md5 from 'md5';
import { FaTrashAlt, FaBars } from 'react-icons/fa';
import { Menu, Item, useContextMenu } from 'react-contexify';
import {
  Form,
  Col,
  Button,
  Card,
  Alert,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { ecgSuccess } from '../../../redux/features/laudo/ecgSlice';
import {
  loadingOpen,
  loadingClose,
} from '../../../redux/features/context/contextSlice';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import dbprontuario from '../../../util/prontuario';
import styles from '../../../styles/styles.module.css';
import history from '../../../services/history';
import RichTextEditor from '../../../components/RichTextEditor';
import { selectProntuarioById } from '../../../redux/features/prontuario/prontuarioSlice';
import CustomFileInput from '../../../components/CustomFileInput';

const updateEcg = async ecg => {
  try {
    const { data } = await api.put(`/ecg/${ecg.codEcg}`, ecg);
    return data;
  } catch (error) {
    return error.response.data.error;
  }
};

const fetchFiles = async id => {
  return new Promise((resolve, reject) => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `ecg/${id}` },
      (err, data) => {
        if (err) {
          toast.error('Erro ao buscar arquivos. Entre em contato.');
          reject(err);
        } else {
          const file = data.Contents.filter(
            ({ Key }) =>
              Key.includes('.jpg') ||
              Key.includes('.bmp') ||
              Key.includes('.BMP')
          );
          resolve(file);
        }
      }
    );
  });
};

export default function FormEcg(props) {
  const dispatch = useDispatch();
  const medico = useSelector(state => state.doctor.profile);
  const { funcionario } = useSelector(state => state.funcionario);
  const { ecg } = useSelector(state => state.ecg);
  const { loading } = useSelector(state => state.contexto);
  const { show } = useContextMenu();
  const [fileToUpload, setFileToUpload] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState({});
  const [isUpdatedInMenu, setIsUpdatedInMenu] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    codEcg: ecg[0]?.CODECG || '',
    descricao: ecg[0]?.OBSERVACAO || '',
  });
  const md5Data = `${ecg[0]?.CODPAC} - ${ecg[0]?.CODECG}`;
  const contentFooter = `<strong>Autenticação: ${md5(
    JSON.stringify(ecg[0] || props?.ecg || '')
  )}<strong/>
  <br />
  <strong className="mr-auto">
    Médico: ${prefixo()} ${ecg[0]?.Realizou}
  </strong>`;

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, []);

  // Effect to update local state when the global state changes
  useEffect(() => {
    setFieldValues({
      codEcg: ecg[0]?.CODECG || '',
      descricao: ecg[0]?.OBSERVACAO || '',
    });
  }, [ecg]);

  // Ensure that the local state is updated with the current global state
  useEffect(() => {
    setFieldValues({
      codEcg: ecg[0]?.CODECG || '',
      descricao: ecg[0]?.OBSERVACAO || '',
    });
  }, []);

  useEffect(() => {
    // atualiza ARQUIVO_URL no banco se ecg já tem arquivo no s3, mas não tem url no db
    const fetchFileURLs = async () => {
      try {
        if (ecg && ecg[0] && ecg[0].ARQUIVO_URL === null) {
          dispatch(loadingOpen());
          const file = await fetchFiles(ecg[0]?.CODECG);
          if (!file.length) return;
          const filename = file[0].Key.split('/').pop();
          const url = `https://prevencor.s3.amazonaws.com/ecg/${ecg[0]?.CODECG}/${filename}`;
          await api.put(`/ecg/${ecg[0].CODECG}/update-url`, {
            codEcg: ecg[0].CODECG,
            url,
          });
          dispatch(ecgSuccess({ ecg: [{ ...ecg[0], ARQUIVO_URL: url }] }));
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        dispatch(loadingClose());
      }
    };

    fetchFileURLs();
  }, []);

  async function handleUploadFile(file) {
    if (!file.length) return toast.warn('Selecione um arquivo!');

    const isInvalid = !['.jpg', '.bmp', '.BMP'].some(ext =>
      file[0].name.endsWith(ext)
    );
    if (isInvalid) return toast.warn('Selecione apenas arquivos .jpg ou .bmp.');

    const encodedFilename = encodeURIComponent(file[0].name);
    const codEcg = ecg[0]?.CODECG;
    const url = `https://prevencor.s3.amazonaws.com/ecg/${codEcg}/${file[0].name}`;

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/ecg/laudo/${codEcg}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file[0] });

      await api.put(`/ecg/${ecg[0].CODECG}/update-url`, { codEcg, url });

      toast.success('Arquivo enviado com sucesso!');
      dispatch(ecgSuccess({ ecg: [{ ...ecg[0], ARQUIVO_URL: url }] }));
      setShowUpload(false);
    } catch (error) {
      toast.error('Erro ao enviar arquivo!');
    }
  }

  const handleMenu = (event, id) => {
    event.preventDefault();
    show(event, { id });
  };

  const handleMenuClick = ({ event }) => {
    setIsUpdatedInMenu(true);
    setFieldValues({
      ...fieldValues,
      descricao: (fieldValues.descricao ?? '') + '  ' + event.currentTarget.id,
    });
    event.stopPropagation();
  };

  const handleGravar = async richTextData => {
    const data = { codEcg: fieldValues.codEcg, descricao: richTextData };
    fieldValues.codEcg = ecg[0]?.CODECG;
    fieldValues.descricao = richTextData;

    const response = await updateEcg(data);

    if (response && response.message) {
      dispatch(ecgSuccess({ ecg: [{ ...ecg[0], OBSERVACAO: richTextData }] }));
      toast.success(response.message);
    } else {
      toast.error(response);
    }
  };

  function prefixo() {
    const med = props?.ecg?.CODREALIZOU || ecg[0]?.CODREALIZOU;

    if (
      med?.CODREALIZOU === 1 ||
      med?.CODREALIZOU === 5 ||
      med?.CODREALIZOU === 2796 ||
      med?.CODREALIZOU === 958
    )
      return 'Prof. Dr. ';

    return 'Dr. ';
  }

  async function deleteS3Object(e, url) {
    e.preventDefault();
    const key = url
      .split('/')
      .slice(3)
      .join('/');

    try {
      await s3.deleteObject({ Bucket: 'prevencor', Key: key }).promise();
      await api.put(`/ecg/${ecg[0].CODECG}/update-url`, {
        codEcg: ecg[0].CODECG,
        url: null,
      });
      toast.success('Arquivo deletado com sucesso!');
      dispatch(ecgSuccess({ ecg: [{ ...ecg[0], ARQUIVO_URL: null }] }));
      setDeleteAlert(prev => ({ ...prev, [ecg[0]?.ARQUIVO_URL]: false }));
    } catch (error) {
      toast.error('Erro ao deletar arquivo. Entre em contato.');
    }
  }

  async function fetchProntuario(atividade) {
    const res = await api.get(`paciente/atividades?id=${atividade.CODPAC}`);
    const laudos = res.data.filter(
      l => l.CODPROCEDI === 1 || l.CODPROCEDI === 58 || l.CODPROCEDI === 800
    );

    if (laudos.length > 0) {
      await dispatch(selectProntuarioById(laudos[0]?.CODLAUDO));
      history.push('/formProntuario');
    } else {
      toast.warn('Paciente não tem consulta registrada!');
    }
  }

  return (
    <>
      <Card>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE ELETROCARDIOGRAMA</Card.Title>
        </Card.Header>
        <Card.Body>
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${ecg[0]?.CODPAC} - ${ecg[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${ecg[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${ecg[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${ecg[0]?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {ecg[0]?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Cod ECG: </strong>
          {ecg[0]?.CODECG}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {ecg[0]?.dataEcg}
          <br></br>
          <strong className="mr-auto">Médico que Realizou: Dr. </strong>
          {`${ecg[0]?.Realizou}`}
          <br></br>
          <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
          {`${ecg[0]?.Solicitou}`}
          <Alert
            show={deleteAlert[ecg[0]?.ARQUIVO_URL] === true}
            variant="danger"
            onClose={() =>
              setDeleteAlert(prevAlerts => ({
                ...prevAlerts,
                [ecg[0]?.ARQUIVO_URL]: false,
              }))
            }
            dismissible
          >
            <Alert.Heading>
              Tem certeza que quer deletar a imagem?
            </Alert.Heading>
            <Button
              onClick={e => deleteS3Object(e, ecg[0]?.ARQUIVO_URL)}
              variant="danger"
            >
              Confirmar
            </Button>
          </Alert>
          <div style={{ alignItems: 'center', textAlign: 'center' }}>
            {(medico || funcionario?.permissao === 'PARCIAL1') && (
              <button
                style={{ marginRight: 5, color: 'red' }}
                onClick={e => {
                  e.preventDefault();
                  setDeleteAlert(prevAlerts => ({
                    ...prevAlerts,
                    [ecg[0]?.ARQUIVO_URL]: true,
                  }));
                }}
              >
                Deletar imagem
              </button>
            )}
            <img
              alt="Imagem não encontrada"
              src={ecg[0]?.ARQUIVO_URL}
              loading="lazy"
              width="100%"
              height="80%"
            />
          </div>
          <Form.Row>
            <Col sm>
              <Form.Group size="sm">
                <Form.Label className="d-flex justify-content-between">
                  <strong>DESCRIÇÃO</strong>
                  <OverlayTrigger overlay={<Tooltip>Descrição</Tooltip>}>
                    <Button size="sm" onClick={e => handleMenu(e, 'idEcg')}>
                      <FaBars style={{ width: 18, height: 18 }} />
                    </Button>
                  </OverlayTrigger>
                </Form.Label>
                <Menu className={styles.styleMenu} id={'idEcg'}>
                  {dbprontuario.ecg.map(e => (
                    <Item id={e.value} onClick={e => handleMenuClick(e)}>
                      {e.value}
                    </Item>
                  ))}
                </Menu>
              </Form.Group>
              <RichTextEditor
                content={fieldValues.descricao}
                contentFooter={contentFooter}
                // contentHeader={contentHeader}
                saveContent={handleGravar}
                handleAttachment={() => setShowUpload(true)}
                imageUrl={ecg[0]?.ARQUIVO_URL}
                appendContent={isUpdatedInMenu}
                readOnly={false}
              />
            </Col>
          </Form.Row>
          <Card.Footer className="mt-3 text-center">
            <u>Autenticação</u> -{' '}
            <strong className="mr-auto">
              {prefixo()} {ecg[0]?.Realizou}
            </strong>{' '}
            <i className="mr-auto">- {md5(JSON.stringify(md5Data || ''))}</i>
          </Card.Footer>
          <Card.Footer className="mt-3 text-center">
            <Button
              onClick={
                props.isFromTab
                  ? () => {
                      props.setActiveKey('prontuario');
                      props.setShowEletroModal(false);
                    }
                  : () => fetchProntuario(ecg[0])
              }
            >
              Prontuário
            </Button>{' '}
            {props.showEletroModal && (
              <Button
                variant="danger"
                onClick={() => props.setShowEletroModal(false)}
              >
                Fechar
              </Button>
            )}
          </Card.Footer>
        </Card.Body>
      </Card>

      {loading && <Loading />}

      {showUpload && (
        <Modal
          show={showUpload}
          centered
          onHide={() => {
            setShowUpload(false);
            setFileToUpload([]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Anexar arquivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomFileInput
              id="file-upload"
              label="Selecionar arquivo"
              multiple={false}
              onChange={e => setFileToUpload(Array.from(e.target.files))}
            />
            <ul>
              {fileToUpload?.length > 0 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <li style={{ marginRight: 5 }}>{fileToUpload[0]?.name}</li>
                  <FaTrashAlt
                    onClick={() => setFileToUpload([])}
                    cursor="pointer"
                    style={{ color: 'red' }}
                  />
                </div>
              )}
            </ul>
            {ecg[0]?.ARQUIVO_URL && (
              <Alert variant="warning">
                <Alert.Heading>Este Eletro já tem 1 imagem!</Alert.Heading>
                <p>Delete a imagem atual para anexar outra</p>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => handleUploadFile(fileToUpload)}
              disabled={ecg[0]?.ARQUIVO_URL}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
