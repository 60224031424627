import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import styles from '../../../styles/styles.module.css';

export default function FormAgendaDiaria() {
  const { agendadiaria } = useSelector(state => state.faturamento);

  function removeDiv(divName) {
    let newdiv = document.createElement('div');
    let originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.print();
    document.body.innerHTML = originalContents;
  }

  function formatarData(data) {
    const dataFormatada = new Date(data);
    return dataFormatada.toLocaleDateString('pt-BR');
  }

  function formatarHorario(horario) {
    const hora = Math.floor(horario / 100);
    const minutos = horario % 100;
    return (
      hora.toString().padStart(2, '0') +
      ':' +
      minutos.toString().padStart(2, '0')
    );
  }

  // Função para montar o relatório em HTML separado por médico com largura de colunas padronizada
  function montarRelatorioPorMedicoHTML(agendas) {
    const relatorioPorMedico = {};
    agendas.forEach(agendamento => {
      const nomeMedico = agendamento.NOMEMED;
      if (!relatorioPorMedico[nomeMedico]) relatorioPorMedico[nomeMedico] = [];
      relatorioPorMedico[nomeMedico].push(agendamento);
    });

    let html = '';
    for (const medico in relatorioPorMedico) {
      html += `<br></br><h4>Médico: ${medico} - ${formatarData(
        agendadiaria[0].DATAAGENDA
      )}</h4>`;
      html +=
        '<table border="1" style="width: 100%; border-collapse: collapse;font-size: 0.8em;"><tr><th style="width: 5%;">Horário</th><th style="width: 30%;">Paciente</th><th style="width: 20%;">Procedimento</th><th style="width: 15%;">Convênio</th><th style="width: 12%;">Celular</th><th style="width: 18%;">Anotação</th></tr>';
      // eslint-disable-next-line
      relatorioPorMedico[medico].forEach(agendamento => {
        html += `<tr><td>${formatarHorario(agendamento.HORARIO)}</td><td>${
          agendamento.NOMEPAC
        }</td><td>${agendamento.DESCPROCEDI}</td><td>${
          agendamento.SIGLACONV
        }</td><td>${agendamento.CELULARPAC}</td><td></td></tr>`;
      });
      html += '</table>';
    }
    return html;
  }

  useEffect(() => {
    // Monta o relatório em HTML
    const relatorioHTML = montarRelatorioPorMedicoHTML(agendadiaria);
    document.getElementById('fechamento').innerHTML = relatorioHTML;
    // eslint-disable-next-line
  }, [agendadiaria]);

  return (
    <Card id="cardHeader">
      <div id="printMe">
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>AGENDA DIÁRIA</Card.Title>
        </Card.Header>
      </div>
      <hr></hr>
      <div id="fechamento"></div>
      <div id="removeMe" className="text-right">
        <Button variant="outline-primary" onClick={() => removeDiv('removeMe')}>
          Imprimir
        </Button>
      </div>
    </Card>
  );
}
