const dbprontuario = {
  queixas: [
    { sintoma: 'Avaliação de rotina' },
    { sintoma: 'Retorno' },
    { sintoma: 'Aumento da PA' },
    { sintoma: 'Oscilação da PA' },
    { sintoma: 'Assintomático cardiovascular' },
    {
      sintoma:
        'Nega dor precordial, dispneia, palpitações, edemas, tonturas ou síncope',
    },
    { sintoma: 'Nega dor precordial relacionada com esforços' },
    { sintoma: 'Dor precordial relacionada com esforço físico' },
    { sintoma: 'Dor precordial em repouso, sem relação com esforço físico' },
    { sintoma: 'Dor torácica atípica' },
    { sintoma: 'Cansaço aos esforços' },
    { sintoma: 'Fone exames sangue' },
    { sintoma: 'WhatsApp exames sangue' },
    { sintoma: 'Cansaço físico, indisposição, sem dispnéia' },
    { sintoma: 'Palpitações frequentes' },
    { sintoma: 'Tonturas posturais' },
    { sintoma: 'Tonturas rotatórias (labirintopatia)' },
    { sintoma: 'Edema em MMII com piora vespertina' },
    { sintoma: 'Cefaléia frequente' },
    { sintoma: 'Cefaléia na região da nuca' },
    { sintoma: 'Avaliação pré-operatória de' },
    { sintoma: 'Avaliação para realização de' },
  ],
  habitos: [
    { sintoma: 'Sedent\u00e1rio' },
    { sintoma: 'Atividade f\u00edsica irregular' },
    { sintoma: 'Ativo no trabalho e em atividades di\u00e1rias' },
    { sintoma: 'Academia _x/semana' },
    { sintoma: 'Caminhada regular, _x/semana' },
    { sintoma: 'Hidrogin\u00e1stica _x/semana' },
    { sintoma: 'Pilates 2 vezes por semana' },
    { sintoma: 'Nega Tabagismo' },
    { sintoma: 'Nega tabagismo e etilismo' },
    { sintoma: 'Nega tabagismo, etilismo e consumo de caf\u00e9' },
    { sintoma: 'Tabagista, fuma cigarros por dia, desde' },
    { sintoma: 'Ex-tabagista, fumou por  anos, parou em' },
    { sintoma: 'Etilista, cerveja por dia' },
    { sintoma: 'Consumo social de bebida alco\u00f3lica' },
    { sintoma: 'Ex-etilista, parou em' },
    { sintoma: 'Nega consumo de caf\u00e9' },
    { sintoma: 'Consumo de caf\u00e9: bastante' },
    { sintoma: 'Dieta bem controlada' },
    { sintoma: 'Dieta n\u00e3o controlada' },
    { sintoma: 'Dieta pouco controlada, sem grandes abusos' },
    { sintoma: 'Acompanha com nutricionista, dieta bem controlada' },
  ],
  familiares: [
    { sintoma: 'Nega HAS, DM, Dislipidemia' },
    { sintoma: 'Nega DM e Dislipidemia' },
    { sintoma: 'Nega DAC e AVC' },
    { sintoma: 'Nega morte súbita' },
  ],
  pessoais: [
    { sintoma: 'Nega HAS, DM, Dislipidemia' },
    { sintoma: 'Nega DM e Dislipidemia' },
    { sintoma: 'Nega IAM ou AVC' },
    { sintoma: 'HAS desde' },
    { sintoma: 'DM desde' },
    { sintoma: 'Dislipidemia desde' },
    { sintoma: 'Epidemiologia Chagas negativo' },
    { sintoma: 'Epidemiologia Chagas positivo' },
    { sintoma: 'Ansioso' },
    { sintoma: 'Muito Ansioso' },
    { sintoma: 'Síndrome do Pânico' },
    { sintoma: 'Depressão desde' },
    { sintoma: 'Nega cirurgias prévias' },
    { sintoma: 'Nega alergia' },
    { sintoma: 'ATC+Stent convencional' },
    { sintoma: 'ATC+Stent farmacológico' },
    { sintoma: 'Revasc.Mioc.' },
    { sintoma: 'Hiperferritinemia' },
    { sintoma: 'Hipotireoidismo' },
    { sintoma: 'Hiperuricemia' },
    { sintoma: 'Hipertireoidismo' },
    { sintoma: 'Intolerância Glicose' },
  ],
  fisico: [
    {
      sintoma:
        'PA=  mmHg   e  FC=  bpm \nRCR, 2T sem sopros\nMV +, sem ruídos adventícios\nAbdome flácido e indolor. Ausência de VMG, com RHA presentes\nAusência de edema em MMII\nPulsos presentes e simétricos\nCarótidas sem sopro',
    },
    { sintoma: 'PA=  mmhg   e  FC=  bpm ' },
    { sintoma: 'RCR, 2T sem sopros' },
    { sintoma: 'RCR, 2T com sopro sistólico +/6+ em foco' },
    { sintoma: 'RCR, 2T com sopro diastólico +/4+ em foco' },
    { sintoma: 'RCI, em FA e sem sopros' },
    { sintoma: 'RCI, em FA e com sopro sistólico em foco' },
    { sintoma: 'MV +, sem ruídos adventícios' },
    { sintoma: 'MV+, com estertores creptantes em bases' },
    { sintoma: 'MV+, com estertores creptantes até terço médio' },
    { sintoma: 'MV+, com diminuição global' },
    { sintoma: 'Abdome flácido e indolor. Ausência de VMG, com RHA presentes' },
    {
      sintoma:
        'Abdome flácido e indolor. Fígado palpável 3cm do rebordo costal, indolor. RHA presentes',
    },
    { sintoma: 'Ausência de edema em MMII' },
    { sintoma: 'Presença de edema em MMII, +/4+' },
    { sintoma: 'Pulsos presentes e simétricos' },
    { sintoma: 'Pulsos diminuídos em MMII' },
    { sintoma: 'Ausência de estase jugular' },
    { sintoma: 'Presença de estase jugular +/+4' },
    { sintoma: 'Carótidas sem sopro' },
    { sintoma: 'Carótida com sopro sistólico +/4+ à' },
  ],
  isda: [
    { sintoma: 'Nega alterações de hábitos urinário e intestinal' },
    { sintoma: 'Obstipação intestinal' },
    { sintoma: 'Nictúria' },
    { sintoma: 'Polaciúria' },
  ],
  hipoteses: [
    { sintoma: 'Avaliação de rotina' },
    { sintoma: 'Avaliação pré-operatória de' },
    { sintoma: 'Eutrófico Cardio' },
    { sintoma: 'Dor precordial' },
    { sintoma: 'Dor torácica' },
    { sintoma: 'Doença Arterial Coronariana Crônica Estável' },
    { sintoma: 'IAM em' },
    { sintoma: 'ATC+Stent convencional' },
    { sintoma: 'ATC+Stent farmacológico' },
    { sintoma: 'Revasc.Mioc.' },
    { sintoma: 'HAS' },
    { sintoma: 'PA oscilando (HAS?)' },
    { sintoma: 'Intolerância a Glicose' },
    { sintoma: 'Diabetes Mellitus tipo I' },
    { sintoma: 'Diabetes Mellitus tipo II' },
    { sintoma: 'Dislipidemia' },
    { sintoma: 'Síndrome Metabólica' },
    { sintoma: 'Obesidade' },
    { sintoma: 'Insuficiência Cardíaca Congestiva' },
    { sintoma: 'Insuficiência Cardíaca Sistólica' },
    { sintoma: 'Insuficiência Cardíaca Diastólica' },
    { sintoma: 'Fibrilação Atrial de início recente' },
    { sintoma: 'Fibrilação Atrial Permanente' },
    { sintoma: 'Fibrilação Atrial Persistente' },
    { sintoma: 'Fibrilação Atrial Paroxística' },
    { sintoma: 'Fibrilação Atrial revertida em' },
    { sintoma: 'Hiperferritinemia' },
    { sintoma: 'Hiperuricemia' },
    { sintoma: 'Hipertireoidismo' },
    { sintoma: 'Hipotireoidismo' },
    { sintoma: 'DPOC' },
    { sintoma: 'Ansiedade' },
    { sintoma: 'Depressão' },
    { sintoma: 'Síndrome do Pânico' },
  ],
  condutas: [
    { sintoma: 'Retornar para nova avaliação em' },
    {
      sintoma:
        'Mudanças de hábitos de vida (exercício, dieta e redução de peso)',
    },
    { sintoma: 'Melhorar dieta, aumentar exercício e reduzir peso' },
    { sintoma: 'Manter controle com dieta e exercício' },
    { sintoma: 'Controle da PA' },
    { sintoma: 'Exames laboratoriais' },
    { sintoma: 'Ecodopplercardio' },
    { sintoma: 'Eco Estresse Fisiológico' },
    { sintoma: 'Eco Estresse Farmacológico' },
    { sintoma: 'Eco Transesofágico' },
    { sintoma: 'Doppler de Carótidas' },
    { sintoma: 'Cintilografia do Miocárdio' },
    { sintoma: 'Teste Ergométrico' },
    { sintoma: 'Holter' },
    { sintoma: 'MAPA' },
    { sintoma: 'Looper' },
    { sintoma: 'Angiotomo de coronárias' },
    { sintoma: 'TC para escore de cálcio' },
    { sintoma: 'CATE' },
    { sintoma: 'Estudo Eletrofisiológico e Ablação' },
    { sintoma: 'Ultrasson de Abdome' },
    { sintoma: 'Cirurgia Cardíaca de Revascularização' },
    { sintoma: 'Cirurgia Cardíaca valvar' },
    { sintoma: 'Angioplastia com Stent em' },
  ],
  medicamentos: {
    id: 'root',
    name: 'Medicamentos',
    children: [
      {
        id: '100',
        name: 'IECA',
        children: [
          { id: '101', name: 'Maleato de Enalapril 10mg' },
          { id: '102', name: 'Vasopril 10mg' },
          { id: '103', name: 'Vasopril Plus 20/12,5mg' },
          { id: '104', name: 'Captopril 25mg' },
          { id: '105', name: 'Ramipril 5mg' },
          { id: '106', name: 'Naprix 5mg' },
          { id: '107', name: 'Naprix D 5/12,5mg' },
          { id: '108', name: 'Naprix D 5/25mg' },
          { id: '109', name: 'Naprix A 2,5/5mg' },
          { id: '110', name: 'Naprix A 5/5mg' },
        ],
      },
      {
        id: '200',
        name: 'BRA',
        children: [
          { id: '201', name: 'Losartana 50mg' },
          { id: '202', name: 'Aradois 50mg' },
          { id: '203', name: 'Aradois H 50/12,5mg' },
          { id: '204', name: 'Torlós 50mg' },
          { id: '205', name: 'Torlós H 50/12,5mg' },
          { id: '206', name: 'Valsartana 80mg' },
          { id: '207', name: 'Diovan 80mg' },
          { id: '208', name: 'Diovan HCT 80/12,5mg' },
          { id: '209', name: 'Diovan HCT 160/25mg' },
          { id: '210', name: 'Diovan Amlo FIX 160/5mg' },
          { id: '211', name: 'Diovan Amlo FIX 320/5mg' },
          { id: '212', name: 'Olmesartana 20mg' },
          { id: '213', name: 'Benicar 20mg' },
          { id: '214', name: 'Benicar HCT 20/12,5mg' },
          { id: '215', name: 'Benicar Anlo 20/5mg' },
          { id: '216', name: 'Olmy Anlo 20/5mg' },
          { id: '217', name: 'Olmetec Anlo 20/5mg' },
          { id: '218', name: 'Telmisartana 40mg' },
          { id: '219', name: 'Bramicar 40mg' },
          { id: '220', name: 'Micardis 40mg' },
        ],
      },
      {
        id: '300',
        name: 'ENTRESTO',
        children: [
          { id: '301', name: 'Sacubitril/Valsartana 50mg' },
          { id: '302', name: 'Entresto 50mg' },
        ],
      },
      {
        id: '400',
        name: 'DIURÉTICOS',
        children: [
          { id: '401', name: 'Hidroclorotiazida 25mg' },
          { id: '402', name: 'Lasix 40mg' },
          { id: '403', name: 'Furosemida 40mg' },
          { id: '404', name: 'Indapen SR 1,5mg' },
          { id: '405', name: 'Natrilix 1,5mg' },
          { id: '406', name: 'Moduretic 50/5mg' },
          { id: '407', name: 'Moduretic 25/2,5mg' },
          { id: '408', name: 'Diupress 25/5mg' },
          { id: '409', name: 'Aldactone 25mg' },
          { id: '410', name: 'Espironolactona 25mg' },
          { id: '411', name: 'Clortalidona 25mg' },
          { id: '412', name: 'Aldazida 25mg' },
          { id: '413', name: 'Amilorida/Hidroclorotiazida 5/50mg' },
          { id: '414', name: 'Hidrion 40/100mg' },
        ],
      },
      {
        id: '500',
        name: 'BETABLOQUEADORES',
        children: [
          { id: '501', name: 'Propranolol 40mg' },
          { id: '502', name: 'Atenolol 50mg' },
          { id: '504', name: 'Ablok 50mg' },
          { id: '505', name: 'Ablok Plus 50/12,5mg' },
          { id: '506', name: 'Carvedilol 3,125mg' },
          { id: '507', name: 'Carvedilol 6,25mg' },
          { id: '508', name: 'Bisoprolol 5mg' },
          { id: '509', name: 'Concor 5mg' },
          { id: '510', name: 'Metoprolol 50mg' },
          { id: '511', name: 'Selozok 50mg' },
          { id: '512', name: 'Nebivolol 5mg' },
        ],
      },
      {
        id: '600',
        name: 'BLOQUEADORES CALCIO',
        children: [
          { id: '601', name: 'Anlodipina 5mg' },
          { id: '602', name: 'Roxflan 5mg' },
          { id: '603', name: 'Pressat 5mg' },
          { id: '604', name: 'Novanlo 5mg' },
          { id: '605', name: 'Adalat Oros 20mg' },
          { id: '606', name: 'Adalat Oros 30mg' },
          { id: '607', name: 'Adalat R 20mg' },
          { id: '608', name: 'Zanidip 20mg' },
        ],
      },
      {
        id: '700',
        name: 'ANTIAGREGANTES E ANTICOAGULANTES',
        children: [
          { id: '701', name: 'AAS 100mg' },
          { id: '702', name: 'Aspirina Prevent 100mg' },
          { id: '703', name: 'Somalgim Cardio 100mg' },
          { id: '704', name: 'Clopidogrel 75mg' },
          { id: '705', name: 'Plagrel 75mg' },
          { id: '706', name: 'Ticagrelor 90mg' },
          { id: '707', name: 'Brilinta 90mg' },
          { id: '708', name: 'Marevan 5mg' },
          { id: '709', name: 'Coumadin 5mg' },
          { id: '710', name: 'Pradaxa 110mg' },
          { id: '711', name: 'Pradaxa 150mg' },
          { id: '712', name: 'Xarelto 15mg' },
          { id: '713', name: 'Xarelto 20mg' },
          { id: '714', name: 'Rivaroxabana 20mg' },
          { id: '715', name: 'Apixabana 5mg' },
          { id: '716', name: 'Eliquis 5mg' },
          { id: '717', name: 'Embo 5mg' },
          { id: '718', name: 'Lixiana 60mg' },
        ],
      },
      {
        id: '800',
        name: 'DIGITÁLICO',
        children: [{ id: '801', name: 'Digoxina 0,25mg' }],
      },
      {
        id: '900',
        name: 'ANTIARRITMICOS',
        children: [
          { id: '9001', name: 'Ancoron 200mg' },
          { id: '9002', name: 'Amiodarona 200mg' },
          { id: '9003', name: 'Ritmonorm 300mg' },
          { id: '9004', name: 'Vatis 150mg' },
        ],
      },
      {
        id: '1000',
        name: 'DIABETES',
        children: [
          { id: '1001', name: 'Metformina 500mg' },
          { id: '1002', name: 'Metformina 850mg' },
          { id: '1003', name: 'Glifage XR 500mg' },
          { id: '1004', name: 'Glifage XR 750mg' },
          { id: '1005', name: 'Glibenclamida 5mg' },
          { id: '1006', name: 'Galvus 50mg' },
          { id: '1007', name: 'Galvus MET 50/500mg' },
          { id: '1008', name: 'Galvus MET 50/850mg' },
          { id: '1009', name: 'Glimepirida 4mg' },
          { id: '1010', name: 'Jardiance 25mg' },
          { id: '1011', name: 'Janumet 50/850mg' },
          { id: '1012', name: 'Dapagliflozina 10mg' },
          { id: '1013', name: 'Forxiga 10mg' },
          { id: '1014', name: 'Diamicron MR 60mg' },
          { id: '1015', name: 'Glyxambi 25/5mg' },
        ],
      },

      {
        id: '1100',
        name: 'TIREÓIDE',
        children: [
          { id: '1101', name: 'Puran T4 50mcg' },
          { id: '1102', name: 'Euthyrox 50mcg' },
          { id: '1103', name: 'Levotiroxina 50mcg' },
          { id: '1104', name: 'Synthroid 50mcg' },
        ],
      },
      {
        id: '1200',
        name: 'DISLIPIDEMIA',
        children: [
          { id: '1201', name: 'Sinvastatina 20mg' },
          { id: '1202', name: 'Clinfar 20mg' },
          { id: '1203', name: 'Sinvastacor 20mg' },
          { id: '1204', name: 'Atorvastatina 20mg' },
          { id: '1205', name: 'Lipitor 20mg' },
          { id: '1206', name: 'Citalor 20mg' },
          { id: '1207', name: 'Rosuvastatina 20mg' },
          { id: '1208', name: 'Crestor 20mg' },
          { id: '1209', name: 'Rosucor 20mg' },
          { id: '1210', name: 'Ciprofibrato 100mg' },
          { id: '1211', name: 'Lipless 100mg' },
          { id: '1212', name: 'Lipanon 250mg' },
          { id: '1213', name: 'Lopid 600mg' },
          { id: '1214', name: 'Trezor 20mg' },
          { id: '1215', name: 'Pivast 2mg' },
          { id: '1216', name: 'Zetsim 10/40mg' },
          { id: '1217', name: 'Ezetimiba 10mg' },
          { id: '1218', name: 'Trezete 20/10mg' },
          { id: '1219', name: 'Zinpass Eze 20/10mg' },
          { id: '1220', name: 'Zinpass Eze 40/10mg' },
        ],
      },
      {
        id: '1300',
        name: 'ANSIOLÍTICOS',
        children: [
          { id: '1301', name: 'Bromazepan 3mg' },
          { id: '1302', name: 'Lexotan 3mg' },
          { id: '1303', name: 'Alprazolan 2mg' },
          { id: '1304', name: 'Rivotril 0,5mg' },
        ],
      },
      {
        id: '1400',
        name: 'ANTIDEPRESSIVOS',
        children: [
          { id: '1401', name: 'Fluoxetina 20mg' },
          { id: '1402', name: 'Citalopran 20mg' },
          { id: '1403', name: 'Citta 20mg' },
          { id: '1404', name: 'Procimax 20mg' },
          { id: '1405', name: 'Escitalopran 10mg' },
          { id: '1406', name: 'Lexapro 10mg' },
          { id: '1407', name: 'Sertralina 50mg' },
        ],
      },
      {
        id: '1500',
        name: 'DIVERSOS',
        children: [
          { id: '1501', name: 'Zyloric 100' },
          { id: '1502', name: 'Zyloric 300mg' },
          { id: '1503', name: 'Omeprazol 40mg' },
          { id: '1504', name: 'Pantoprazol 40mg' },
          { id: '1505', name: 'Benzetacil 1.200.000UI' },
        ],
      },
      {
        id: '1600',
        name: 'Obesidade',
        children: [
          { id: '1601', name: 'Ozempic 1,5 ml (doses 0,25/0,5 mg)' },
          { id: '1602', name: 'Ozempic 3 ml ( dose 1 mg)' },
          { id: '1603', name: 'Saxenda' },
        ],
      },
    ],
  },
  atestadosicm: [
    {
      Admissional:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC compareceu nesta clínica em consulta para avaliação e acompanhamento cardiológico, não apresentando sinais ou sintomas clínicos de cardiopatia manifesta.\nEncontra-se apto(a) clinicamente para exercer suas atividades profissionais. \n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      AtestadoDia:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC compareceu nesta clínica em consulta para avaliação e acompanhamento cardiológico, necessita de --- dia de afastamento das suas atividades profissionais.\n \n \n \n@MARILIA',
    },
    {
      AtestadoHora:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC compareceu nesta clínica em consulta para avaliação e acompanhamento cardiológico, necessita de afastamento das suas atividades profissionais no período de --:00 às --:00 horas.\n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      AtestadoFutebol:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC foi submetido(a) a avaliação cardiológica nesta clínica, exames de Eletrocardiograma, Ecodopplercardiograma e Teste Ergométrico computadorizado em esteira. \n \nO(a) paciente em questão não apresenta cardiopatia manifesta clinicamente ou em exames complementares de rotina e portanto encontra-se apto(a) a atividade física. \n \nExame em anexo.\n \n \n \n@MARILIA',
    },
    {
      AtividadeFisica:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC é portador(a) de --- e foi submetido(a) a avaliação cardiológica nesta clínica, exames de eletrocardiograma, Ecodopplercardiograma e Teste Ergométrico computadorizado em esteira. \n \nO(A) paciente em questão não apresenta cardiopatia manifesta clinicamente ou em exames complementares de rotina, e portanto encontra-se apto(a) a atividade física supervisionada respeitando-se limites individuais. \n \nSugestões de exercício: Realizar atividade física regular de 3 a 5 vezes por semana, duração de 40 a 60 minutos e intensidade moderada. Dar preferência a exercícios aeróbicos como caminhada, corrida, esteira, bicicleta ou natação. Exercícios resistidos como funcionais e musculação também são recomendados. \n \nExame anexo.\n \n \n \n@MARILIA',
    },
    {
      CarteiraHAS:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC compareceu nesta clínica em consulta para avaliação e acompanhamento cardiológico, constatado durante a consulta pressão arterial = ---  mmhg. Orientado(a) mudanças no estilo de vida quanto a hábitos alimentares, consumo de álcool, perda de peso e exercício físico regular. Fazer controle de PA.\n \nEm relação à HAS não há problemas ou impedimentos para dirigir veículo automotor. \n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      Cate:
        '                                 ENCAMINHAMENTO SERVIÇO DE HEMODINÂMICA\n \nSolicito para @PAC a realização de Cineangiocoronariografia com Ventriculografia esquerda. \n \nPortador(a) de __.\n \nRealizado Ecocardio de Estresse com evidência de isquemia na parede --.\n \n \nExames anexos. \n \n \nCID: \n \n \n@MARILIA: ',
    },
    {
      Cirurgia:
        '                                 ENCAMINHAMENTO SERVIÇO DE CIRURGIA\n \nSolicito para @PAC a realização de Cirurgia Cardíaca de __. \n \nPortador de __ . \n \n \nExames anexos.\n \n \nCID: ',
    },
    {
      ConcursoPublico1:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC -- nascido(a) em --/--/19--, portador(a) do RG: --UF:SSP, encontra-se apto(a) para participar do Concurso Público e assumir a função de --.\n \nO(a) paciente em questão não apresenta cardiopatia manifesta clinicamente ou em exames complementares.\n \n \n \n@MARILIA',
    },
    {
      ConcursoPublico2:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC -- nascido(a) em --/--/19--, portador(a) do RG: --, encontra-se apto(a) para realizar testes de esforços físicos, podendo participar dos testes de aptidão física do Edital do Concurso Público para o cargo de --.\n \nO(a) paciente em questão não apresenta cardiopatia manifesta clinicamente ou em exames complementares.\n \n \n \n@MARILIA',
    },
    {
      CriancaEsporte:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC foi submetido(a) a avaliação cardiológica nesta clínica, além de exames de eletrocardiograma, ecodopplercardiograma colorido e Teste Ergométrico computadorizado em esteira com resultados normais.\n \nO(a) paciente em questão não apresenta cardiopatia manifesta clinicamente ou em exames complementares, e, portanto, encontra-se apto(a) a atividade física supervisionada respeitando-se limites individuais.\n \n Exame anexo.  \n \n \n \n @MARILIA',
    },
    {
      Declaracao:
        '                                 ATESTADO MÉDICO\n \n \n \nDeclaro para os devidos fins que @PAC faz acompanhamento cardiológico  nesta clínica desde --/--/20-- com diagnóstico de --.\n \nRealizou sua última consulta em --/--/20-- quando apresentava-se compensado(a) e em uso regular e contínuo de suas medicações.\n \n \nExames anexos. \n \n \nCID:    \n \n \n \n @MARILIA',
    },
    {
      AltoCusto:
        '                                 DECLARAÇÃO MÉDICA\n \n \n \nDeclaro para os devidos fins que @PAC faz acompanhamento cardiológico nesta clínica com diagnósticos de --. \n \nNecessita de tratamento farmacológico com medicações que comprovadamente reduzem desfechos cardiovasculares maiores melhorando assim a sobrevida e prognóstico.\n \nDeclaro não ter conflito de interesses em relação a droga prescrita.\n \nExames anexos:\n \nCID:  \n \n \n \n@MARILIA',
    },
    {
      Fisioterapia:
        '                                 ENCAMINHAMENTO PARA ESPECIALISTA\n \n \n \nSolicito avaliação e acompanhamento com Fisioterapia para @PAC, portador(a) de --.\n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      Hidroginastica:
        '                                 ENCAMINHAMENTO HIDROGINÁSTICA\n \n \n \nSolicito para @PAC a realização de hidroginástica. Portador(a) de --.  \n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      Internacao:
        '                                 ENCAMINHAMENTO INTERNAÇÃO\n \n \n \nSolicito internação na Santa Casa de Misericórdia de Marília para @PAC, portador(a) de --.  \n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      Marcapasso:
        '                                 ENCAMINHAMENTO IMPLANTE DE MARCAPASSO\n \n \n \nA/C Serviço de Cirurgia Cardíaca. \n \nSolicito para @PAC a realização de implante de Marcapasso Cardíaco Dupla câmara.  \n \nPortador(a) de --. \n \nApresentando como sintomas ---. \n \n \nExames anexos.\n \n \n \n@MARILIA ',
    },
    {
      Nutricionista:
        '                                 ENCAMINHAMENTO PARA ESPECIALISTA\n \n \n \nSolicito avaliação e acompanhamento com Nutricionista para @PAC, portador(a) de --.  \n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      Psicologa:
        '                                 ENCAMINHAMENTO PARA ESPECIALISTA\n \n \n \nSolicito avaliação e acompanhamento com Psicólogo(a) para @PAC, portador(a) de --.  \n \n \nCID: \n \n \n \n@MARILIA',
    },
    {
      Vacina:
        '                                 ENCAMINHAMENTO PARA VACINAÇÃO\n \n \n \nSolicito para @PAC a realização de vacinação para ---, portador(a) de -- .  \n \n \nCID:  \n \n \n \n@MARILIA',
    },
    {
      PeriopAltoRisco:
        '                                 ATESTADO MÉDICO\n \n \n \ntAtesto para os devidos fins que @PAC foi submetido(a) a avaliação cardiológica perioperatória nesta clínica, não apresentando contraindicações para realizar cirurgia de ---.\n \nSegundo "European Society of Cardiology Guidelines" de 2022 e Diretriz da Sociedade Brasileira de Cardiologia de 2017, risco cirúrgico ---- compatível com o risco relacionado ao paciente e ao procedimento. \n \nPortador(a) de --- em acompanhamento regular e controlada.\n \n"Revised Cardiac Risk Index", atualização de 2019 (Escore de LEE): --- \n(escore 0= 3,9%; escore 1= 6%; escore 2= 10,1%; escore >=3= 15%) \n \nEscore de LEE: Presença de IAM prévio ou Angina, Insuficiência cardíaca, Diabetes em uso de insulina, Cirurgia de alto risco, Insuficiencia renal, Acidente vascular encefalico. Predizendo possibilidade de eventos perioperatório como Infarto do miocárdio, Bloqueio Atrioventricular, Insuficiência cardíaca e Morte cardiovascular.\n \nRecomendado realizar ECG e dosar troponina ultrassensível e BNP antes da cirurgia, assim como repetir dosagem de troponina no pós operatório em 24 e 48 horas após o procedimento.\n \nManter as medicações em uso pelo paciente. \n \n \n \n@MARILIA',
    },
    {
      PeriopBaixoRisco:
        '                                 ATESTADO MÉDICO\n \n \n \nAtesto para os devidos fins que @PAC foi submetido(a) a avaliação cardiológica perioperatória nesta clínica, não apresentando contraindicações para realizar cirurgia de ---.\n \nSegundo "European Society of Cardiology Guidelines" de 2022 e Diretriz da Sociedade Brasileira de Cardiologia de 2017, risco cirúrgico baixo compatível com o risco relacionado ao paciente e ao procedimento.\n \nPortador(a) de --- em acompanhamento regular e controlada.\n \nManter as medicações em uso pelo paciente.\n \n \n \n@MARILIA',
    },
  ],
  requisicoes: [
    {
      LaboratoriaisSimples:
        '                                 \n@MARILIA \n \n \n \nCONVÊNIO: @CONV \n \nNOME: @PAC \n \nCID: @CID10 \n \nINDICAÇÃO: @INDICA \n \n \n \nEXAMES:  \n \n        - HEMOGRAMA COMPLETO\n        - SODIO\n        - POTASSIO\n        - URÉIA\n        - CREATININA\n        - GLICEMIA DE JEJUM \n        - T4 LIVRE\n        - TSH\n        - COLESTEROL TOTAL E FRAÇÕES\n        - TRIGLICERIDES \n        - URINA TIPO 1 \n        - PSA TOTAL E PSA LIVRE',
    },
    {
      LaboratoriaisEspeciais:
        '                                 \n@MARILIA \n \n \n \nCONVÊNIO: @CONV \n \nNOME: @PAC \n \nCID: @CID10 \n \nINDICAÇÃO: @INDICA \n \n \n \nEXAMES:  \n \n        - GAMA GT \n        - FOSFATASE ALCALINA\n        - CPK \n        - TGO\n        - TGP\n        - BILIRRUBINAS TOTAIS\n        ',
    },
    {
      LaboratoriaisCompleto:
        '                                 \n@MARILIA \n \n \n \nCONVÊNIO: @CONV \n \nNOME: @PAC \n \nCID: @CID10 \n \nINDICAÇÃO: @INDICA \n \n \n \nEXAMES:  \n \n        - HEMOGRAMA COMPLETO \n        - SODIO\n        - POTASSIO\n        - URÉIA  \n        - CREATININA\n        - GLICEMIA DE JEJUM\n        - T4 LIVRE\n        - TSH\n        - COLESTEROL TOTAL E FRAÇÕES\n        - URINA TIPO 1\n        - PSA TOTAL E PSA LIVRE\n        - GAMA GT \n        - FOSFATASE ALCALINA\n        - CPK\n        - TGO\n        - TGP\n        - HEMOGLOBINA GLICADA\n        - GLICEMIA POS PRANDIAL\n        - BILIRRUBINAS TOTAIS\n        - MAGNÉSIO\n        - TRIGLICERÍDEOS\n        - ÁCIDO ÚRICO\n        ',
    },
    {
      OutrosExames:
        '                                 \n@MARILIA \n \n \n \nCONVÊNIO: @CONV \n \nNOME: @PAC \n \nCID: @CID10 \n \nINDICAÇÃO: @INDICA \n \n \n \nEXAMES:  \n \n        ',
    },
  ],
  complementares: {
    id: 'root',
    name: 'Exames',
    children: [
      {
        id: '100',
        name: 'ICM',
        children: [
          { id: '101', name: 'AVALIAÇÃO E CONTROLE DE MARCA PASSO' },
          { id: '102', name: 'CETE' },
          { id: '103', name: 'CONSULTA EM CONSULTÓRIO' },
          { id: '104', name: 'ECG - ELETROCARDIOGRAMA' },
          { id: '105', name: 'ECOCARDIOGRAMA TRANSTORACICO' },
          { id: '106', name: 'ECODOPPLER DE CAROTIDAS COLLOR' },
          { id: '107', name: 'ECODOPPLER ESTRESSE' },
          { id: '108', name: 'ECODOPPLER TRANSESOFAGICO' },
          { id: '109', name: 'LOOPER' },
          { id: '110', name: 'MAPA 24 HRS' },
          { id: '111', name: 'SIST HOLTER 24 HS  DIGITAL [20102020]' },
          { id: '112', name: 'TESTE ERGO. COMPUTADORIZADO [40101037]' },
          { id: '113', name: 'TILT TESTE' },
          { id: '114', name: 'MRPA - MEDIÇÃO RESIDENCIAL DA PA' },
        ],
      },
      {
        id: '200',
        name: 'OUTROS EXAMES',
        children: [
          { id: '201', name: 'RX TÓRAX PA + P' },
          { id: '202', name: 'ULTRASSOM DE ABDOMEN SUPERIOR' },
          { id: '203', name: 'ULTRASSOM DE ABDOMEN TOTAL' },
        ],
      },
      {
        id: '300',
        name: 'LABORATORIAIS',
        children: [
          { id: '301', name: 'BILIRRUBINAS TOTAIS' },
          { id: '302', name: 'COLESTEROL TOTAL E FRAÇÕES' },
          { id: '303', name: 'CPK' },
          { id: '304', name: 'CREATININA' },
          { id: '305', name: 'FERRATINHA' },
          { id: '306', name: 'FOSFATASE ALCALINA' },
          { id: '307', name: 'GAMA GT' },
          { id: '308', name: 'GLICEMIA DE JEJUM' },
          { id: '309', name: 'HDL' },
          { id: '310', name: 'HEMOGRAMA COMPLETO' },
          { id: '311', name: 'POTASSIO' },
          { id: '312', name: 'PSA TOTAL E FRAÇÕES' },
          { id: '313', name: 'SODIO' },
          { id: '314', name: 'T4 LIVRE' },
          { id: '315', name: 'TGO' },
          { id: '316', name: 'TGP' },
          { id: '317', name: 'TSH' },
          { id: '318', name: 'TRIGLICERIDES' },
          { id: '319', name: 'URÉIA' },
          { id: '320', name: 'URINA TIPO 1' },
          { id: '321', name: 'VITAMINA D' },
        ],
      },
      {
        id: '400',
        name: 'BIOQUÍMICA (SANGUE)',
        children: [
          { id: '401', name: 'ÁCIDO FÓLICO' },
          { id: '402', name: 'ÁCIDO LÁCTICO ARTERIAL' },
          { id: '403', name: 'ÁCIDO LÁCTICO VENOSO' },
          { id: '404', name: 'ÁCIDO ÚRICO' },
          { id: '405', name: 'ALDOLASE' },
          { id: '406', name: 'ALFA 1 GLICOPROTEÍNA ÁCIDA' },
          { id: '407', name: 'AMILASE' },
          { id: '408', name: 'AMÔNEA' },
          { id: '409', name: 'BILIRRUBINAS' },
          { id: '410', name: 'CÁLCIO' },
          { id: '411', name: 'COBRE' },
          { id: '412', name: 'COLESTEROL TOTAL' },
          { id: '413', name: 'COLESTEROL HDL' },
          { id: '414', name: 'COLESTEROL LDL' },
          { id: '415', name: 'COLESTEROL VLDL' },
          { id: '416', name: 'CPK' },
          { id: '417', name: 'CPK - FRAÇÃO MB' },
          { id: '418', name: 'CREATININA' },
          { id: '419', name: 'DHL' },
          { id: '420', name: 'ELETROFORESE PROTEÍNAS' },
          { id: '421', name: 'FERRITINA' },
          { id: '422', name: 'FERRO' },
          { id: '423', name: 'FOSFATASE ÁCIDA' },
          { id: '424', name: 'FOSFATASE ALCALINA' },
          { id: '425', name: 'FÓSFORO' },
          { id: '426', name: 'FRUTATOSAMINA' },
          { id: '427', name: 'GAMA GT' },
          { id: '428', name: 'GASOMETRIA ARTERIAL' },
          { id: '429', name: 'GASOMETRIA VENOSA' },
          { id: '430', name: 'GLICOSE' },
          { id: '431', name: 'GTT - TESTE TOLERÂNCIA A GLICOSE' },
          { id: '432', name: 'HEMOGLOBINA GLICADA' },
          { id: '433', name: 'MAGNÉSIO' },
          { id: '434', name: 'POTÁSSIO' },
          { id: '435', name: 'SÓDIO' },
          { id: '436', name: 'PROTEÍNAS TOTAIS + ALBUMINA' },
          { id: '437', name: 'TGO' },
          { id: '438', name: 'TGP' },
          { id: '439', name: 'TRIGLICÉRIDES' },
          { id: '440', name: 'URÉIA' },
          { id: '441', name: 'VITAMINA B12' },
        ],
      },
      {
        id: '500',
        name: 'BIOQUÍMICA (URINA)',
        children: [
          { id: '501', name: 'ÁCIDO ÚRICO - URINA 24H' },
          { id: '502', name: 'CÁLCIO - URINA 24H' },
          { id: '503', name: 'CITRATO - URINA 24H' },
          { id: '504', name: 'CREATININA - URINA 24H' },
          { id: '505', name: 'CREATININA - DEPURAÇÃO' },
          { id: '506', name: 'FÓSFORO - URINA 24H' },
          { id: '507', name: 'GLICOSÚRIA 24H - PERÍODO ÚNICO' },
          { id: '508', name: 'GLICOSÚRIA 24H - 4 PERÍODOS' },
          { id: '509', name: 'MICROALBUMINA - URINA 12H' },
          { id: '510', name: 'MICROALBUMINA - URINA 24H' },
          { id: '511', name: 'OXALATO - URINA 24H' },
          { id: '512', name: 'POTÁSSIO - URINA 24H' },
          { id: '513', name: 'SÓDIO - URINA 24H' },
          { id: '514', name: 'PROTEINÚRIA 24H' },
        ],
      },
      {
        id: '600',
        name: 'IMUNOLOGIA',
        children: [
          { id: '601', name: 'ANTICARDIOLIPINA (LGG + LGM + LGA)' },
          { id: '602', name: 'ANTICOAGULANTE LÚPICO' },
          { id: '603', name: 'ANTIMITOCONDRIA, AC' },
          { id: '604', name: 'ANTI MÚSCULO LISO, AC' },
          { id: '605', name: 'ANTI NÚCLEO, ACS' },
          { id: '606', name: 'FAN' },
          { id: '607', name: 'RNP' },
          { id: '608', name: 'SSA' },
          { id: '609', name: 'DNA' },
          { id: '610', name: 'SM' },
          { id: '611', name: 'SSB' },
          { id: '612', name: 'ENA' },
          { id: '613', name: 'ASLO' },
          { id: '614', name: 'C3' },
          { id: '615', name: 'C4' },
          { id: '616', name: 'CD4/CD8' },
          { id: '617', name: 'CHAGAS' },
          { id: '618', name: 'CITOMEGALOVÍRUS (LGG + LGM)' },
          { id: '619', name: 'CRIOAGLUTININAS' },
          { id: '620', name: 'CRIOGLOBULINAS' },
          { id: '621', name: 'FATOR REUMATÓIDE' },
          { id: '622', name: 'HEPATITE A - AC. LGG' },
          { id: '623', name: 'HEPATITE A - AC. LGM' },
          { id: '624', name: 'HEPATITE B' },
          { id: '625', name: 'HBSAG' },
          { id: '626', name: 'HBE' },
          { id: '627', name: 'ANTI HBC' },
          { id: '628', name: 'ANTI HBSAG' },
          { id: '629', name: 'ANTI HBE' },
          { id: '630', name: 'ANTI HBC - LGM' },
          { id: '631', name: 'HEPATITE C, AC' },
          { id: '632', name: 'HERPES SIMPLES (LGG + LGM)' },
          { id: '633', name: 'HIV (HIV1 + HIV2)' },
        ],
      },
      {
        id: '700',
        name: 'IMUNOGLOBULINAS',
        children: [
          { id: '701', name: 'IGG' },
          { id: '702', name: 'IGA' },
          { id: '703', name: 'IGM' },
          { id: '704', name: 'IGE' },
          { id: '705', name: 'MICOPLASMA' },
          { id: '706', name: 'MONONUCLEOSE PAUL BUNNEL' },
          { id: '707', name: 'MONONUCLEOSE EPSTEIN BAAR - IGM' },
        ],
      },
      {
        id: '800',
        name: 'PROTEÍNA C REATIVA (PCR)',
        children: [
          { id: '801', name: 'AVALIAR RISCO CARDIOVASCULAR' },
          { id: '802', name: 'AVALIAR PROCESSO INFLAMATÓRIO' },
        ],
      },
      {
        id: '900',
        name: 'HEMATOLOGIA',
        children: [
          { id: '901', name: 'HEMOGRAMA COMPLETO' },
          { id: '902', name: 'COAGULOGRAMA COMPLETO' },
          { id: '903', name: 'FIBRINOGÊNIO' },
          { id: '904', name: 'PLAQUETAS' },
          { id: '905', name: 'TS - TC' },
          { id: '906', name: 'TP - INT' },
          { id: '907', name: 'TTPA' },
          { id: '908', name: 'COOMBS DIRETO' },
          { id: '909', name: 'COOMBS INDIRETO' },
          { id: '910', name: 'ELETROFORESE DE HEMOGLOBINA' },
          { id: '911', name: 'ERITROGRAMA' },
          { id: '912', name: 'FALCIZAÇÃO' },
          { id: '913', name: 'G6PD' },
          { id: '914', name: 'GRUPO SNAGUÍNEO - RH' },
          { id: '915', name: 'RETICULÓCITOS' },
          { id: '916', name: 'VHS' },
        ],
      },

      {
        id: '1000',
        name: 'HORMÔNIOS',
        children: [
          { id: '1001', name: '17OH PROGESTERONA' },
          { id: '1002', name: 'ANDROSTENEDIONA' },
          { id: '1003', name: 'BETA - HCG' },
          { id: '1004', name: 'CORTISOL BASAL 8H' },
          { id: '1005', name: 'CORTISOL BASAL 16H' },
          { id: '1006', name: 'CORTISOL PÓS DEXAMETASONA' },
          { id: '1007', name: 'DHEA' },
          { id: '1008', name: 'DHEA - SULFATO' },
          { id: '1009', name: 'ESTRADIOL' },
          { id: '1010', name: 'FSH' },
          { id: '1011', name: 'HORMÔNIO DE CRESCIMENTO' },
          { id: '1012', name: 'INSULINA' },
          { id: '1013', name: 'LH' },
          { id: '1014', name: 'PARATORMONIO (PTH)' },
          { id: '1015', name: 'PROGESTERONA' },
          { id: '1016', name: 'PROLACTINA' },
          { id: '1017', name: 'TESTOSTERONA TOTAL' },
          { id: '1018', name: 'TESTOSTERONA LIVRE' },
          { id: '1019', name: 'TIREÓIDE' },
          { id: '1020', name: 'ANTITIREOGLOBULINA, AC' },
          { id: '1021', name: 'ANTITIREOPEROXIDASE, AC' },
          { id: '1022', name: 'T3' },
          { id: '1023', name: 'T4 TOTAL' },
          { id: '1024', name: 'T4 LIVRE' },
          { id: '1025', name: 'TSH' },
          { id: '1026', name: 'TIREOGLOBULINA' },
          { id: '1027', name: 'TRAB' },
          { id: '1028', name: 'VMA - URINA 24H' },
          { id: '1029', name: 'CATECOLAMINAS - URINA 24H' },
          { id: '1030', name: 'METANEFRINAS - URINA 24H' },
        ],
      },
      {
        id: '1100',
        name: 'FEZES',
        children: [
          { id: '1101', name: 'FEZES-CULTURA' },
          { id: '1102', name: 'FEZES-ANTIBIOGRAMA' },
          { id: '1103', name: 'FEZES-CRIPTOSPORIDIUM, PESQUISA' },
          { id: '1104', name: 'FEZES-EXAME DIRETO' },
          { id: '1105', name: 'FEZES-GORDURAS,PESQUISA' },
          { id: '1106', name: 'FEZES-ISOSPORA, PESQUISA' },
          { id: '1107', name: 'FEZES-PARASITOLÓGICO AMOSTRA ÚNICA' },
          { id: '1108', name: 'FEZES-PARASITOLÓGICO TRÊS AMOSTRAS' },
          { id: '1109', name: 'FEZES-PROVA FUNCIONAL DIGESTIVA' },
          { id: '1110', name: 'FEZES-ROTAVÍRUS, PESQUISA' },
          { id: '1111', name: 'FEZES-SANGUE OCULTO, PESQUISA' },
          { id: '1112', name: 'FEZES-SUBSTÂNCIA REDUTORAS, PESQUISA' },
        ],
      },
      {
        id: '1200',
        name: 'URINA',
        children: [
          { id: '1201', name: 'URINA 1 - 1º JATO' },
          { id: '1202', name: 'URINA 1 -  JATO MÉDIO' },
          { id: '1203', name: 'UROCULTURA' },
          { id: '1204', name: 'URINA-ANTIBIOGRAMA' },
          { id: '1205', name: 'URINA-TESTE GRAVIDEZ' },
        ],
      },
      {
        id: '1300',
        name: 'SECREÇÕES E LÍQUIDOS CAVITÁRIOS',
        children: [
          { id: '1301', name: 'SECREÇÕES E LÍQUIDOS CAVITÁRIOS-MATERIAL' },
          { id: '1302', name: 'SECREÇÕES E LÍQUIDOS CAVITÁRIOS-CULTURA' },
          { id: '1303', name: 'SECREÇÕES E LÍQUIDOS CAVITÁRIOS-ANTIBIOGRAMA' },
          { id: '1304', name: 'CULTURA PARA FUNGOS' },
          { id: '1305', name: 'CHLAMYDIA' },
          { id: '1306', name: 'MICOPLASMA/UREAPLASMA' },
          { id: '1307', name: 'GRAM' },
          { id: '1308', name: 'HELICOBACTER PYLORI, PESQUISA' },
          { id: '1309', name: 'MICOLÓGICO DIREITO' },
          { id: '1310', name: 'ZIEHL' },
        ],
      },
      {
        id: '1400',
        name: 'MARCADORES',
        children: [
          { id: '1401', name: 'ALFAFETOPROTEÍNA' },
          { id: '1402', name: 'CEA' },
          { id: '1403', name: 'CA 19-9' },
          { id: '1404', name: 'CA 125' },
          { id: '1405', name: 'CA 15-3' },
          { id: '1406', name: 'PSA TOTAL' },
        ],
      },
      {
        id: '1500',
        name: 'OUTROS',
        children: [
          { id: '1501', name: 'CITOGRAMA NASAL' },
          { id: '1502', name: 'ESPERMOGRAMA' },
          { id: '1503', name: 'PPD' },
        ],
      },
    ],
  },
  cid: [
    { value: 'Ansiedade', label: 'F41.2' },
    { value: 'Arritmia Ventricular', label: 'I47.0' },
    { value: 'Avaliação geral de rotina', label: 'Z00.0' },
    { value: 'Comunicação Interventricular', label: 'Q21.0' },
    { value: 'Comunicação Interatrial', label: 'Q21.1' },
    { value: 'Depressão', label: 'F32' },
    { value: 'Diabetes Mellitus tipo II', label: 'E11' },
    { value: 'Dispneia', label: 'R06.0' },
    { value: 'Dislipidemia', label: 'E78.2' },
    { value: 'Doença Arterial Coronariana', label: 'I25' },
    { value: 'Doença Pulmonar Obstrutiva Crônica', label: 'J44' },
    { value: 'Doença Reumatica da Valva Aórtica', label: 'I06' },
    { value: 'Doença Reumatica da Valva Mitral', label: 'I05' },
    { value: 'Doença de Chagas', label: 'B57.2' },
    { value: 'Dor Precordial', label: 'R07.2' },
    { value: 'Estenose Valvar Pulmonar', label: 'Q22.1' },
    { value: 'Fibrilação Atrial', label: 'I48' },
    { value: 'Hipertensão Arterial Sistêmica', label: 'I10' },
    { value: 'Insuficiência Cardiaca Congestiva', label: 'I50.0' },
    { value: 'Marcapasso cardíaco', label: 'Z95.0' },
    { value: 'Obesidade', label: 'E66.8' },
    { value: 'Palpitações', label: 'R00.2' },
    { value: 'Prolapso da Valva Mitral', label: 'I34.1' },
    { value: 'Prótese Valvar', label: 'Z95.2' },
    { value: 'Sincope', label: 'R55' },
    { value: 'Taquicardia Paroxistica Supraventricular', label: 'I47.1' },
    { value: 'Valva Aórtica Bicúspide', label: 'Q23.1' },
  ],
  ecg: [
    { value: 'FC=    bpm ' },
    { value: 'Ritmo Sinusal ' },
    { value: 'Ritmo Sinusal, dentro dos limites da normalidade ' },
    { value: 'Alteração da repolarização ventricular ' },
    { value: 'Alteração da repolarização ventricular anterior ' },
    { value: 'Alteração da repolarização ventricular anterolateral ' },
    { value: 'Alteração da repolarização ventricular difusa ' },
    { value: 'Alteração da repolarização ventricular inferior ' },
    { value: 'Alteração da repolarização ventricular lateral ' },
    { value: 'Área Inativa Anterior ' },
    { value: 'Área Inativa Anterior Extensa ' },
    { value: 'Área Inativa Anterolateral ' },
    { value: 'Área Inativa Anterosseptal ' },
    { value: 'Área Inativa Inferior ' },
    { value: 'Área Inativa Lateral ' },
    { value: 'Arritmia Sinusal ' },
    { value: 'Ativação atrial retrógrada ' },
    { value: 'Atraso Final de condução intraventricular  à direita ' },
    { value: 'Atraso Final de condução intraventricular  à esquerda ' },
    { value: 'Bloqueio Atrioventricular 2:1 ' },
    { value: 'Bloqueio Atrioventricular de 1º grau ' },
    { value: 'Bloqueio Atrioventricular de 2º grau Tipo I ' },
    { value: 'Bloqueio Atrioventricular de 2º grau Tipo II ' },
    { value: 'Bloqueio Atrioventricular de Alto grau ' },
    { value: 'Bloqueio Atrioventricular Total ' },
    { value: 'Bloqueio Divisional Anteromedial Esquerdo ' },
    { value: 'Bloqueio Divisional Anterossuperior Esquerdo ' },
    { value: 'Bloqueio Divisional Inferior Direito ' },
    { value: 'Bloqueio Divisional Posteroinferior Esquerdo ' },
    { value: 'Bloqueio Divisional Superior Direito ' },
    { value: 'Bloqueio do Ramo Direito ' },
    { value: 'Bloqueio do Ramo Esquerdo ' },
    { value: 'Bloqueio Sinoatrial do Tipo I ' },
    { value: 'Bloqueio Sinoatrial Tipo II ' },
    { value: 'Bradicardia Sinusal ' },
    { value: 'Desvio de Eixo para direita ' },
    { value: 'Desvio de Eixo para esquerda ' },
    { value: 'Dissociação atrioventricular ' },
    { value: 'Eixo indeterminado ' },
    { value: 'Extrassístole Atrial ' },
    { value: 'Extrassístole Atrial Bloqueada ' },
    { value: 'Extrassístole Atrial com Aberrância de condução ' },
    { value: 'Extrassístole Juncional ' },
    { value: 'Extrassístole Supraventricular ' },
    { value: 'Extrassístole Supraventricular Isoladas ' },
    { value: 'Extrassístole Supraventricular Pareadas ' },
    { value: 'Extrassístole Ventricular ' },
    { value: 'Extrassístole Ventricular Bigeminadas ' },
    { value: 'Extrassístole Ventricular Isoladas ' },
    { value: 'Extrassístole Ventricular Monomórficas ' },
    { value: 'Extrassístole Ventricular Parassístole Ventricular ' },
    { value: 'Extrassístole Ventricular Pareadas ' },
    { value: 'Extrassístole Ventricular Polimórficas ' },
    { value: 'Fibrilação Atrial ' },
    { value: 'Flutter Atrial ' },
    { value: 'Intervalo QT curto ' },
    { value: 'Intervalo QT longo ' },
    { value: 'Marcapasso com falha de comando ' },
    { value: 'Marcapasso com falha de sensibilidade ' },
    { value: 'Padrão de repolarização precoce ' },
    { value: 'Padrão de repolarização precoce anterolateral ' },
    { value: 'Padrão de repolarização precoce inferior ' },
    { value: 'Parada Sinusal ' },
    { value: 'Pré-excitação ventricular ' },
    { value: 'Presença de isquemia anterior ' },
    { value: 'Presença de isquemia anterior extensa ' },
    { value: 'Presença de isquemia anterolateral ' },
    { value: 'Presença de Isquemia anterosseptal ' },
    { value: 'Presença de isquemia inferior ' },
    { value: 'Presença de isquemia lateral ' },
    { value: 'Ritmo atrial ectópico ' },
    { value: 'Ritmo atrial multifocal ' },
    { value: 'Ritmo de Marcapasso Cardíaco Artificial Atrial ' },
    { value: 'Ritmo de Marcapasso Cardíaco Artificial Atrioventricular ' },
    { value: 'Ritmo de Marcapasso Cardíaco Artificial Ventricular ' },
    { value: 'Ritmo idioventricular acelerado ' },
    { value: 'Ritmo idioventricular de escape ' },
    { value: 'Ritmo juncional ' },
    { value: 'Ritmo juncional de escape ' },
    { value: 'Sobrecarga Atrial Direita ' },
    { value: 'Sobrecarga Atrial Esquerda ' },
    { value: 'Sobrecarga Biatrial ' },
    { value: 'Sobrecarga Biventricular ' },
    { value: 'Sobrecarga Ventricular Direita ' },
    { value: 'Sobrecarga Ventricular Esquerda ' },
    { value: 'Taquicardia Atrial ' },
    { value: 'Taquicardia Atrial Multifocal ' },
    { value: 'Taquicardia por Reentrada Atrioventricular Antidrômica ' },
    { value: 'Taquicardia por Reentrada Atrioventricular Ortodrômica ' },
    { value: 'Taquicardia por Reentrada Nodal Atípica  Incomum ' },
    { value: 'Taquicardia por Reentrada Nodal comum ' },
    { value: 'Taquicardia Sinusal ' },
    { value: 'Taquicardia Supraventricular com Aberrância de condução ' },
    { value: 'Taquicardia Ventricular Monomórfica ' },
    { value: 'Taquicardia Ventricular Polimórfica ' },
  ],
};

export const getRandomColor = lab => {
  if (lab.EXAME === 'CT') return 'green';
  if (lab.EXAME === 'HDL') return 'blue';
  if (lab.EXAME === 'Triglicerides') return 'yellow';
  if (lab.EXAME === 'LDL Calculado') return 'magenta';
  if (lab.EXAME === 'Hb glicada') return 'red';
  if (lab.EXAME === 'Ácido úrico') return 'lightblue';
  if (lab.EXAME === 'Uréia') return '#00FFFF';
  if (lab.EXAME === 'Glicemia') return '#FAEBD7';
  if (lab.EXAME === 'K') return '#F0F8FF';
  if (lab.EXAME === 'Na') return '#A52A2A';
  if (lab.EXAME === 'Creatinina') return '#7FFF00';
  if (lab.EXAME === 'TGP') return '#8A2BE2';
  if (lab.EXAME === 'TGO') return '#DEB887';
  if (lab.EXAME === 'TSH') return '#7FFFD4';
  if (lab.EXAME === 'T4') return '#D2691E';
  if (lab.EXAME === 'PSA') return '#ADD8E6';
  if (lab.EXAME === 'Urina I') return '#9400D3';
  if (lab.EXAME === 'Vitamina D') return '#FF1493';
  if (lab.EXAME === 'HT') return '#00BFFF';
  if (lab.EXAME === 'Hb') return '#DCDCDC';
  if (lab.EXAME === 'Hemograma') return '#FFD700';
  if (lab.EXAME === 'Magnésio') return '#ADFF2F';
  if (lab.EXAME === 'Ferritina') return '#F0E68C';
};

export default dbprontuario;
