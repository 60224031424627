/* eslint-disable no-console */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { subHours, addDays, parseISO, format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import pt from 'date-fns/locale/pt';
import {
  FaCalendar,
  FaMoneyBill,
  FaCalendarCheck,
  FaBroadcastTower,
  FaProductHunt,
  FaUserMd,
} from 'react-icons/fa';
import {
  DropdownButton,
  Dropdown,
  Button,
  Col,
  Row,
  Alert as AlertReact,
  Card,
  Table,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from 'react-bootstrap';
import Creatable from 'react-select/creatable';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import {
  loadingOpen,
  loadingClose,
  printOpen,
  printClose,
} from '../../../redux/features/context/contextSlice';
import {
  selectProntuarioById,
  liberarLaudoById,
  insertFatura,
  atualizarGuiaConvenio,
} from '../../../redux/features/prontuario/prontuarioSlice';
import {
  pacienteReset,
  pacienteSuccess,
  selectPacienteById,
} from '../../../redux/features/prontuario/pacienteSlice';
import {
  selectStressById,
  selectStressByIdOLD,
} from '../../../redux/features/laudo/stressSlice';
import { selectEcgById } from '../../../redux/features/laudo/ecgSlice';
import { selectTransById } from '../../../redux/features/laudo/transSlice';
import { selectErgoById } from '../../../redux/features/laudo/ergoSlice';
import { selectEcoById } from '../../../redux/features/laudo/ecoSlice';
import { selectFetalById } from '../../../redux/features/laudo/fetalSlice';
import Loading from '../../../pages/Index/Loading';
import history from '../../../services/history';
import api from '../../../services/api';
import medicoAgenda from '../../../util/medicoAgenda';
import dbatividade from '../../../util/atividade';
import formatarHora from '../../../util/formatarHora';
import FormPaciente from '../../../pages/patient/Paciente/FormPaciente';
import { selectHolterByID } from '../../../redux/features/laudo/holterSlice';
import { selectMapaByID } from '../../../redux/features/laudo/mapaSlice';
import {
  getAgendaDiaria,
  getFechamentoDiario,
} from '../../../redux/features/context/faturamentoSlice';
import {
  selectAvaliacaoById,
  selectAvaliacaoByPac,
  selectMarcapassoById,
} from '../../../redux/features/prontuario/avaliacaoSlice';
import getFirstName from '../../../util/getFirstName';
import fetchAgendas from '../../../services/agenda';
import FormMedico from '../Medico/FormMedico';
import { medicoSuccess } from '../../../redux/features/prontuario/medicoSlice';
import CustomFileInput from '../../../components/CustomFileInput';

registerLocale('ptBR', ptBR);

export default function Agenda() {
  const dispatch = useDispatch();
  const location = useLocation();
  const findPac = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const modalOpen = searchParams.get('modalOpen');
  const med = searchParams.get('med');
  const medico = useSelector(state => state.doctor.profile);
  const { patient } = useSelector(state => state.paciente);
  const { doctors, selectMedicos } = useSelector(state => state.medico);
  const { funcionario } = useSelector(state => state.funcionario);
  const { loading } = useSelector(state => state.contexto);
  const { convenios } = useSelector(state => state.convenio);
  const { procedimentos } = useSelector(state => state.procedimento);
  const { detalhes } = useSelector(state => state.faturamento);
  const { horarios } = useSelector(state => state.agenda);
  const initialState = useMemo(
    () => ({
      nome: '',
      cpfusuario: '',
      especialidade: '',
      crm: '',
      rqe: '',
      codFunc: medico ? medico?.idFunc : funcionario?.codFunc,
      dtAdmissao: new Date(),
      tipoMedico: 'Externo',
    }),
    []
  );
  const [novoMedico, setNovoMedico] = useState(initialState);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPatientDetailsModal, setShowPatientDetailsModal] = useState(false);
  const [pacDetails, setPacDetails] = useState();
  const [agendamentos, setAgendamentos] = useState([]);
  const [dataAgenda, setDataAgenda] = useState();
  const [dataFormatada, setDataFormatada] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [horaAgenda, setHoraAgenda] = useState();
  const [dataHoraAgenda, setDataHoraAgenda] = useState();
  const [dateAgenda, setDateAgenda] = useState(new Date());
  const dateAgendaFormatted = useMemo(() => {
    const adjustedDate = dateAgenda.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return subHours(adjustedDate, 3).toISOString();
  }, [dateAgenda]);
  const selectProcedi = procedimentos.map(p => ({
    value: p.CODPROCEDI,
    label: p.DESCPROCEDI,
  }));
  const selectConvenio = convenios.map(c => ({
    value: c.codConv,
    label: c.sigla,
  }));
  const memoAtividade = useMemo(
    () => dbatividade.atividade.map(i => i.codProcedi),
    []
  );
  const [count, setCount] = useState(0);
  const [loadingPac, setLoadingPac] = useState(false);
  const [pacientes, setPacientes] = useState([]);
  const [codPaciente, setCodPaciente] = useState();
  const [nomePaciente, setNomePaciente] = useState();
  const [codMedico, setCodMedico] = useState();
  const [nomeMedico, setNomeMedico] = useState();
  const [codConvenio, setCodConvenio] = useState();
  const [convenio, setConvenio] = useState();
  const [codProcedimento, setCodProcedimento] = useState();
  const [procedimento, setProcedimento] = useState();
  const [agendou, setAgendou] = useState();
  const [marcou, setMarcou] = useState();
  const [cancelou, setCancelou] = useState();
  const [agendamento, setAgendamento] = useState();
  const [atendido, setAtendido] = useState();
  const [codAgenda, setCodAgenda] = useState();
  const [situacao, setSituacao] = useState();
  const [atividade, setAtividade] = useState();
  const [observacoes, setObservacoes] = useState();
  const [idMedico, setIdMedico] = useState(modalOpen ? med : medico?.codMed);
  const [agendaMed, setAgendaMed] = useState('');
  const [atividades, setAtividades] = useState([]);
  const [nomePac, setNomePac] = useState('');
  const [dtnNasc, setDtnNasc] = useState('');
  const [searchPac, setSearchPac] = useState(false);
  const [codigoLaudo, setCodigoLaudo] = useState();
  const [files, setFiles] = useState();
  const [faturado, setFaturado] = useState();
  const [fatura, setFatura] = useState({
    codRealizou: '',
    codProcedi: '',
    codConv: '',
    codPac: '',
    dataFatura: '',
    horaFatura: '',
    codFunc: medico ? medico.idFunc : funcionario.codFunc,
    codFuncLaudo: medico ? medico.idFunc : funcionario.codFunc,
    convenio,
    procedimento,
    guia: '',
    guiaecg: '',
    ecg: '',
    eco: '',
  });
  const [activeKey, setActiveKey] = useState('agenda');
  const [showModalFaturamento, setShowModalFaturamento] = useState(false);
  const [showModalUpdateFat, setShowModalUpdateFat] = useState(false);
  const [showBtnFaturamento, setShowBtnFaturamento] = useState(true);
  const [showAlertFaturamento, setShowAlertFaturamento] = useState(false);
  const [showAlertEspelho, setShowAlertEspelho] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showAtividadesModal, setShowAtividadesModal] = useState(false);
  const [showSearchPatientsModal, setShowSearchPatientsModal] = useState(false);
  const [showSearchMedicosModal, setShowSearchMedicosModal] = useState(false);
  const [showAlertGeraHorarios, setShowAlertGeraHorarios] = useState(false);
  const [showAlertGravaAgenda, setShowAlertGravaAgenda] = useState(false);
  const [showAlertGravaObs, setShowAlertGravaObs] = useState(false);
  const dropDownStyle = { fontSize: 12, color: 'green', fontWeight: 'bold' };

  useEffect(() => {
    fetchAgendas({
      idMedico,
      dateAgendaFormatted,
      setAgendamentos,
      setCount,
      setIdMedico,
      setAgendaMed,
      funcionario,
      dispatch,
    });
  }, [dateAgendaFormatted, idMedico, funcionario]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const isModalOpenURLSearchParam = searchParams.get('modalOpen');
    const dateAppt = searchParams.get('date');
    const med = searchParams.get('med');

    setShowAtividadesModal(isModalOpenURLSearchParam === 'true');

    if (isModalOpenURLSearchParam === 'true') fetchAtividadesOnGoBack();
    if (dateAppt && med) fetchAgendaOnGoBack();
  }, [history.location.search]);

  useEffect(() => {
    if (showSearchPatientsModal) findPac.current.focus();
    if (showAlertGravaObs) {
      const obsAgenda = document.getElementById('obsAgenda');
      if (obsAgenda) obsAgenda.focus();
    }
  }, [showSearchPatientsModal, showAlertGravaObs]);

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  const loadAgendas = async () => {
    await fetchAgendas({
      idMedico,
      dateAgendaFormatted,
      setAgendamentos,
      setCount,
      setIdMedico,
      setAgendaMed,
      funcionario,
      dispatch,
    });
  };

  async function gerarHorarios(idMed) {
    if (agendamentos.length > 0) return toast.error('Agenda já gerada!');
    if (!idMed) return toast.error('Selecione um médico para gerar horários!');

    const diaSemana = new Date(dateAgendaFormatted).getDay() + 2;
    const horariosMed = horarios.filter(
      h => h.CODMEDICO === idMed && h.PROCEDIMENTO === diaSemana.toString()
    );

    if (!horariosMed.length) return toast.info('Médico não atende neste dia!');

    const horariosMap = horariosMed?.map(h => ({
      ...h,
      DATAAGENDA: dateAgendaFormatted,
      CODFUNC: medico ? medico.idFunc : funcionario.codFunc,
      CODFUNCAGENDA: '',
      OBS: h.OBS || '',
    }));

    await api.post(`/agenda/horarios`, horariosMap);
    toast.success(`${horariosMap.length} horários gerados com sucesso!`);
    setShowAlertGeraHorarios(false);
    loadAgendas();
  }

  function verifyMessageObs(a) {
    setCodAgenda(a.CODAGENDA);
    let obs = a.OBSERVACAO;

    if (a.CODMED !== 0) {
      switch (a.CODMED) {
        case 1:
          if (
            a.codProcedi === 1 &&
            [20, 4, 5, 8, 62, 2, 7, 11, 12, 23, 3, 43, 13].includes(a.codConv)
          ) {
            notifyAndModifyObs();
          }
          break;
        case 3283:
          if (a.codProcedi === 1 && a.codConv === 92) notifyAndModifyObs();
          break;
        case 5:
          if (a.codProcedi === 1 && a.codConv === 36) notifyAndModifyObs();
          break;
        case 2030:
          if (a.codProcedi === 1 && a.codConv === 732) notifyAndModifyObs();
          break;
        case 1981:
          if (a.codProcedi === 1 && a.codConv === 25) {
            toast.info('DINHEIRO OU PIX (21964622840) DIRETO P/ MÉDICA');
            obs += '-PAGTO DINHEIRO OU PIX (21964622840)';
            setObservacoes(obs);
            handleMensagemObservacao(obs);
          }
          break;
        case 957:
          if ([2, 8].includes(a.codProcedi) && a.codConv === 36) {
            notifyAndModifyObs();
          }
          break;
        case 2796:
          if (
            [2, 4, 9, 36, 43, 732, 108, 69, 92].includes(a.codConv) &&
            a.codProcedi === 1
          ) {
            notifyAndModifyObs();
          }
          if ([43].includes(a.codConv) && a.codProcedi === 715) {
            notifyAndModifyObs();
          }
          break;
        default:
          break;
      }
    }

    if (a.codProcedi !== 0) {
      switch (a.codProcedi) {
        case 7:
          if ([5, 108, 1].includes(a.codConv)) {
            notifyAndModifyObs('-Autorizar primeiro, e depois Agendar');
          }
          break;
        case 5:
          if ([675, 801, 108, 1].includes(a.codConv)) {
            notifyAndModifyObs('-Autorizar primeiro, e depois Agendar');
          }
          break;
        case 675:
          if ([14, 65, 68, 70].includes(a.codConv)) notifyAndModifyObs();
          break;
        case 715:
          if ([108].includes(a.codConv)) {
            notifyAndModifyObs('-Se tiver stress agendar um outro dia');
          }
          break;
        case 801:
          if ([675, 720, 750].includes(a.codConv)) {
            notifyAndModifyObs('-Convênio não Autoriza!');
          }
          break;
        default:
          break;
      }
    }

    function notifyAndModifyObs(message = '-Médico não atende o Convênio') {
      toast.info(`ALERTA: Existe uma Observação para esse Agendamento!`);
      obs += message;
      setObservacoes(obs);
      handleMensagemObservacao(obs);
    }
  }

  function fetchAgendaOnGoBack() {
    const dateAppt = searchParams.get('date');
    const med = searchParams.get('med');
    let c = 0;

    api
      .get(`medico/agendas?id=${med}&dt=${dateAppt}`)
      .then(result => {
        const data = result?.data?.map(agenda => ({
          ...agenda,
          dataFormatada: format(
            addDays(parseISO(agenda.DATAAGENDA), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
          counter: (c += 1),
        }));
        setAgendamentos(data);
        setCount(c);
      })
      .catch(error => toast.error(error.response.data.message))
      .finally(() => dispatch(loadingClose()));
  }

  function fetchAtividadesOnGoBack() {
    const userId = searchParams.get('userId');
    const patientName = searchParams.get('pac');
    let c = 0;

    setNomePac(patientName);
    api
      .get(`paciente/atividades?id=${userId}`)
      .then(result => {
        const data = result.data.map(atividade => ({
          ...atividade,
          dataFormatada: format(
            addDays(parseISO(atividade.DATALAUDO), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
          counter: (c += 1),
        }));
        setAtividades(data);
      })
      .catch(error => toast.error(error.response.data.error))
      .finally(() => dispatch(loadingClose()));
  }

  const handleOnEnterPress = (e, next) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      const nextElement = document.getElementById(next);
      if (nextElement) {
        nextElement.select();
      } else {
        console.warn(`Elemento com ID ${next} não encontrado.`);
      }
    }
  };

  async function handleInserirMedico(e) {
    e.preventDefault();

    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
      return;
    }

    try {
      const { data } = await api.post('/medicos', novoMedico);
      const newMedico = { value: data.medico.codMed, label: data.medico.nome };
      dispatch(medicoSuccess({ newMedico }));
      toast.success(data.message);
    } catch (error) {
      toast.error('Falha ao cadastrar médico: ' + error.message);
    }
  }

  const handleGetNomeMedico = (e, nextInput, fieldName) => {
    if (e.key === 'Enter') {
      const inputValue = Number(e.target.value);
      const med = doctors.find(d => d.CODMEDICO === inputValue);
      if (med) {
        setFatura(prevFatura => ({ ...prevFatura, [fieldName]: med.NOMEMED }));
        handleNextInput(e, nextInput);
      } else {
        toast.error('Médico não localizado!');
        handleNextInput(e, e.target.name);
      }
    }
  };

  const handleNextInput = (event, nextInputId) => {
    if (event.key === 'Enter') {
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    }
  };

  const handleChangeFaturamento = e => {
    const { name, value } = e.target;
    setFatura(prevFatura => ({ ...prevFatura, [name]: value }));
  };

  const handleValorFaturamento = e => {
    const filteredProcedi = procedimentos.filter(
      p => p.CODPROCEDI === Number(e.target.value)
    );

    if (filteredProcedi.length > 0) {
      const detalhe = detalhes?.filter(
        d =>
          d.CODPROCEDI === Number(e.target.value) &&
          d.CODCONV === Number(fatura.codConv)
      );
      setFatura({
        ...fatura,
        procedimento: filteredProcedi[0]?.DESCPROCEDI,
        valor: detalhe[0]?.VLRREAL || 0,
      });
      fatura.codProcedi === 3
        ? handleNextInput(e, 'guiaecg')
        : handleNextInput(e, 'guia');
    } else {
      toast.warn('Procedimento não localizado!');
      handleNextInput(e, 'codProcedi');
    }
  };

  const handleAtualizarGuiaConvenio = pAtividade => {
    setShowAtividadesModal(false);
    setFatura({
      codFatura: pAtividade.CODFATURA,
      codLaudo: pAtividade.CODLAUDO,
      codPac: pAtividade.CODPAC,
      paciente: pAtividade.NOMEPAC,
      codConveniado: pAtividade.CODMEDICO,
      codSolicitou: pAtividade.CODENCAMINHOU,
      codFaturou: pAtividade.CODFATURAMENTO,
      codRealizou: pAtividade.CODREALIZOU,
      conveniado: pAtividade.NOMEMED,
      solicitou: pAtividade.ENCAMINHOU,
      faturou: pAtividade.FATUROU,
      realizou: pAtividade.REALIZOU,
      codProcedi: pAtividade.CODPROCEDI,
      procedimento: pAtividade.DESCPROCEDI,
      codConv: pAtividade.CODCONV,
      convenio: pAtividade.SIGLACONV,
      codFunc: medico ? medico.idFunc : funcionario.codFunc,
      usuario: medico ? medico.apelido : funcionario.nome,
      codFuncLaudo: medico ? medico.idFunc : funcionario.codFunc,
      dataFormatada: new Date(pAtividade.DATALAUDO).toISOString().substr(0, 10),
      dataFatura: pAtividade.DATALAUDO,
      valor: pAtividade.VLRLAUDO,
      guia: pAtividade.CODPROCEDI !== 3 ? pAtividade.GUIA : '',
      guiaecg: pAtividade.CODPROCEDI === 3 ? pAtividade.GUIA : '',
    });
    setShowModalUpdateFat(true);
  };

  function handleUpdateFaturamento(event) {
    event.preventDefault();
    document.getElementById('btnUpdateFat').disabled = true;
    dispatch(atualizarGuiaConvenio(fatura)).then(result => {
      result
        ? toast.success(result)
        : toast.warn('Ocorreu um erro na gravação do Faturamento');
      setShowAlertFaturamento(false);
      setShowBtnFaturamento(true);
    });
  }

  function handleSelectFaturamento(event, pAgenda) {
    event.preventDefault();
    api
      .get(`paciente/atividades?id=${pAgenda.CODPAC}`)
      .then(result => {
        const faturado = result.data.find(
          atv =>
            atv.CODPAC === pAgenda.CODPAC &&
            atv.CODCONV === pAgenda.CODCONV &&
            atv.CODPROCEDI === pAgenda.CODPROCEDI &&
            atv.CODMEDICO === pAgenda.CODMED &&
            atv.DATALAUDO === pAgenda.DATAAGENDA
        );
        if (faturado) {
          toast.warn('Procedimento Já Faturado nesta Data para este Paciente!');
          setShowModalFaturamento(false);
        } else {
          let obs = '';
          obs = pAgenda.OBSERVACAO;
          obs = 'Fat-' + obs;
          const updateOBS = {
            CODAGENDA: pAgenda.CODAGENDA,
            OBSERVACAO: obs,
            CODFUNCMARCOU: medico ? medico.idFunc : funcionario.codFunc,
          };

          api
            .put('/agenda/observacao', updateOBS)
            .then(() => loadAgendas())
            .catch(err => toast.error(err.response.data.error));

          setFatura({
            codPac: pAgenda.CODPAC,
            paciente: pAgenda.NOMEPAC,
            codConveniado: pAgenda.CODMED,
            codSolicitou: pAgenda.CODMED,
            codFaturou: pAgenda.CODMED,
            codRealizou: pAgenda.CODMED,
            conveniado: pAgenda.NOMEMED,
            solicitou: pAgenda.NOMEMED,
            faturou: pAgenda.NOMEMED,
            realizou: pAgenda.NOMEMED,
            codProcedi: pAgenda.CODPROCEDI === 1 ? 3 : pAgenda.CODPROCEDI,
            procedimento: pAgenda.PROCEDIMENTO,
            codConv: pAgenda.CODCONV,
            convenio: pAgenda.CONVENIO,
            codFunc: medico ? medico.idFunc : funcionario.codFunc,
            usuario: medico ? medico.apelido : funcionario.nome,
            codFuncLaudo: medico ? medico.idFunc : funcionario.codFunc,
            dataFormatada: new Date(pAgenda.DATAAGENDA)
              .toISOString()
              .substr(0, 10),
            dataFatura: pAgenda.DATAAGENDA,
            valor: 0,
            guia: '',
            guiaecg: '',
            ecg: pAgenda.CODPROCEDI === 1 ? '3' : '',
          });

          pAgenda.SITUACAO = 'Em Atendimento';
          pAgenda.CODFUNC = medico ? medico.idFunc : funcionario.codFunc;
          if (pAgenda) {
            api
              .put('/agenda/situacao', pAgenda)
              .catch(err => toast.error(err.response.data.error));
          } else {
            toast.warn('Agendamento não identificado!');
          }

          setShowModalFaturamento(true);
        }
      })
      .catch(err => toast.error(err.response.data.error));
  }

  function handleInsertFaturamento(event) {
    event.preventDefault();
    setShowAlertEspelho(false);
    document.getElementById('btnGravarFat').disabled = true;
    dispatch(insertFatura(fatura)).then(result => {
      result
        ? toast.success(result)
        : toast.warn('Ocorreu um erro na gravação do Faturamento');
      setShowAlertFaturamento(false);
      setShowBtnFaturamento(true);
      if ([1, 3, 58].includes(fatura.codProcedi)) {
        setShowAlertEspelho(false);
      } else {
        setShowAlertEspelho(true);
        handleTipoEspelho();
      }
    });
  }

  async function handleGravarAgenda() {
    const objAgenda = {
      CODAGENDA: codAgenda,
      DATAAGENDA: dataAgenda,
      OBSERVACAO: observacoes,
      HORARIO: horaAgenda,
      CODMED: codMedico,
      codPac: codPaciente,
      codConv: codConvenio,
      codProcedi: codProcedimento,
      situacao: situacao === 'Aberta' ? 'Marcada' : situacao,
      codFuncAgendou: medico ? medico.idFunc : funcionario.codFunc,
      atividade: atividade,
    };

    api
      .put('agendamento', objAgenda)
      .then(() => {
        setShowAlertGravaAgenda(false);
        loadAgendas();
      })
      .then(verifyMessageObs(objAgenda))
      .catch(err => toast.error(err.response.data.error));
  }

  // TODO: deixar só esse
  function handleGravarObservacao() {
    const objAgenda = {
      CODAGENDA: codAgenda,
      CODFUNCMARCOU: medico ? medico.idFunc : funcionario.codFunc,
      OBSERVACAO: observacoes,
    };

    api
      .put('/agenda/observacao', objAgenda)
      .then(result => {
        setShowAlertGravaObs(false);
        loadAgendas();
        toast.success(result.data.message);
      })
      .catch(err => toast.error(err.response.data.error));
  }

  async function handleMensagemObservacao(obs) {
    api
      .put('/agenda/observacao', {
        CODAGENDA: codAgenda,
        CODFUNCMARCOU: medico ? medico.idFunc : funcionario.codFunc,
        OBSERVACAO: obs,
      })
      .then(() => {
        setShowAlertGravaObs(false);
        loadAgendas();
      })
      .catch(err => toast.error(err.response.data.error));
  }

  async function handleLaudar(atividade) {
    dispatch(loadingOpen());
    if (medico?.tipo === 'Agenda' || funcionario) {
      switch (atividade.CODPROCEDI) {
        case 1:
        case 58:
        case 800: // PRONTUARIO
          await dispatch(selectProntuarioById(atividade.CODLAUDO));
          history.push('/formProntuario');
          break;
        case 3: // ECG
          await dispatch(selectEcgById(atividade.CODLAUDO));
          history.push('/formEcg');
          break;
        case 13: // STRESS
          await dispatch(selectStressById(atividade.CODLAUDO));
          history.push('/formStress');
          break;
        case 12: // TRANS
          await dispatch(selectTransById(atividade.CODLAUDO));
          history.push('/formTrans');
          break;
        // case 4: //ERGO
        //   break;
        case 11:
        case 712:
        case 713:
        case 714: // ECO ecocardiograma
          const eco = await dispatch(selectEcoById(atividade.CODLAUDO));
          if (eco.TIPOECO === 'EcoC') history.push('/formCongenito');
          else history.push('/formDoppler');
          break;
        case 14:
        case 15:
          history.push({
            pathname: '/formCarotida',
            state: { codLaudo: atividade.CODLAUDO },
          });
          break;
        case 17: // FETAL
          await dispatch(selectFetalById(atividade.CODLAUDO));
          history.push('/formFetal');
          break;
        case 2: // AVALIACAO
          await dispatch(selectAvaliacaoByPac(atividade.CODPAC));
          await dispatch(selectAvaliacaoById(atividade.CODLAUDO)).then(resp => {
            dispatch(selectMarcapassoById(resp[0].CODMARCA));
            history.push('/formAvaliacao');
          });
          break;
        default:
          dispatch(loadingClose());
          return toast.warn('Laudo não disponível para registro!');
      }
    }

    dispatch(loadingClose());
  }

  async function handleLiberarLaudo(atividade) {
    dispatch(loadingOpen());
    setShowAtividadesModal(false);
    const fatura = { codFatura: atividade.CODFATURA, liberado: 'SIM' };
    await dispatch(liberarLaudoById({ fatura }));
    dispatch(loadingClose());
  }

  function handleImprimir(atividade) {
    switch (atividade.CODPROCEDI) {
      case 1:
      case 58:
      case 800:
        handleProntuario(atividade);
        break;
      case 3:
        handleEcg(atividade);
        break;
      case 13:
        handleStress(atividade);
        break;
      case 12:
        handleTrans(atividade);
        break;
      case 4:
        handleErgo(atividade);
        break;
      case 11:
      case 712:
      case 713:
      case 714:
        handleEco(atividade);
        break;
      case 14:
      case 15:
        handleCarotida(atividade);
        break;
      case 17:
        handleFetal(atividade);
        break;
      default:
        return toast.warn('Laudo não disponível para Visualização!');
    }
  }

  function handleImprimirTXT(atividade) {
    switch (atividade.CODPROCEDI) {
      case 11:
      case 712:
      case 713:
      case 714:
        handleEcoTXT(atividade);
        break;
      default:
        return toast.warn('Laudo não disponível para Visualização!');
    }
  }

  function handleImprimirEspelho(atividade) {
    switch (atividade.CODPROCEDI) {
      case 12:
        handleEspelhoTrans(atividade);
        break;
      case 4:
        handleEspelhoErgo(atividade);
        break;
      case 6:
        handleEspelhoHolter(atividade);
        break;
      case 7:
        handleEspelhoMapa(atividade);
        break;
      case 14:
      case 15:
        handleEspelhoCarotida(atividade);
        break;
      case 17:
        handleEspelhoFetal(atividade);
        break;
      default:
        return toast.warn('Espelho para Laudo ainda não disponível!');
    }
  }

  function handleTipoEspelho() {
    api
      .get(`paciente/atividades?id=${fatura.codPac}`)
      .then(result => {
        const faturado = result.data.filter(
          a => a.CODPAC === fatura.codPac && a.DATALAUDO === fatura.dataFatura
        );
        if (faturado) setFaturado([...faturado]);
      })
      .catch(error => console.log(error));
  }

  function changeColor(agenda) {
    switch (agenda.SITUACAO) {
      case 'Solicitado':
        return { fontWeight: 'bold', backgroundColor: '#00FF00' };
      case 'Marcada':
        return { color: 'black', fontWeight: 'bold', backgroundColor: 'white' };
      case 'Aberta':
        return { color: 'black', backgroundColor: 'white' };
      case 'Atendido':
        return { color: 'blue', fontWeight: 'bold', backgroundColor: 'white' };
      case 'Faltou':
        return {
          textDecoration: 'line-through',
          color: 'green',
          fontWeight: 'bold',
          backgroundColor: 'white',
        };
      case 'Cancelado':
        return {
          textDecoration: 'line-through',
          color: 'red',
          fontWeight: 'bold',
          backgroundColor: 'white',
        };
      case 'Em Atendimento':
        return {
          color: 'purple',
          fontWeight: 'bold',
          backgroundColor: 'white',
        };
      case 'Feriado':
        return { color: 'red', fontWeight: 'bold', backgroundColor: 'white' };
      case 'Reservada':
        return { color: 'brown', fontWeight: 'bold', backgroundColor: 'white' };
      case 'Fechada':
        return {
          color: 'yellow',
          fontWeight: 'bold',
          backgroundColor: 'white',
        };
      case 'Agendada':
        return {
          color: 'black',
          fontWeight: 'bold',
          backgroundColor: '#00ffff',
        };
      default:
        return {
          color: 'black',
          fontWeight: 'bold',
          backgroundColor: 'white',
        };
    }
  }

  function encaixeAgenda(agenda) {
    const encaixe = {
      CODPAC: 1,
      CODCONV: 1000,
      CODPROCEDI: 1000,
      CODMEDICO: agenda.CODMED,
      HORARIO: agenda.HORARIO + 1,
      DATAAGENDA: dateAgendaFormatted,
      ESTADO: 'Aberta',
      CODFUNC: medico ? medico.idFunc : funcionario.codFunc,
      CODFUNCAGENDA: medico ? medico.idFunc : funcionario.codFunc,
      ATIVIDADE: agenda.ATIVIDADE,
      OBS: agenda.OBSERVACAO,
    };

    api
      .post('/agenda', encaixe)
      .then(() => loadAgendas())
      .catch(err => toast.error(err.response.data.error));
  }

  function alteraStatusAgenda(pAgenda, situacao) {
    if (
      situacao === 'Aberta' ||
      situacao === 'Faltou' ||
      situacao === 'Em Atendimento' ||
      situacao === 'Reservada' ||
      situacao === 'Feriado' ||
      situacao === 'Fechada'
    ) {
      pAgenda.SITUACAO = situacao;
      pAgenda.CODFUNC = medico ? medico.idFunc : funcionario.codFunc;
      if (pAgenda) {
        api
          .put('/agenda/situacao', pAgenda)
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Agendamento não identificado!');
      }
    }

    if (situacao === 'Faltou') {
      const obs =
        '\nFaltou dia ' +
        pAgenda.dataFormatada +
        ' - ' +
        pAgenda.PROCEDIMENTO +
        ' - Dr. ' +
        pAgenda.NOMEMED;
      const pac = {
        codPac: pAgenda.CODPAC,
        codFunc: medico ? medico.idFunc : funcionario.codFunc,
        obs,
      };
      api
        .put('/informacao', pac)
        .catch(err => console.error(err.response.data.error));
    }

    if (situacao === 'Atendido') {
      pAgenda.SITUACAO = situacao;
      pAgenda.CODFUNCATENDIDO = medico ? medico.idFunc : funcionario.codFunc;
      if (pAgenda) {
        api
          .put('/agenda/situacao', pAgenda)
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Agendamento não identificado!');
      }
    }

    if (situacao === 'Solicitado') {
      pAgenda.SITUACAO = situacao;
      pAgenda.CODFUNCATENDIDO = medico ? medico.idFunc : funcionario.codFunc;
      api
        .put('/agenda/situacao', pAgenda)
        .catch(err => toast.error(err.response.data.error));
    }

    if (situacao === 'Cancelado') {
      pAgenda.SITUACAO = situacao;
      pAgenda.CODFUNCCANCELOU = medico ? medico.idFunc : funcionario.codFunc;
      if (pAgenda) {
        api
          .put('/agenda/situacao', pAgenda)
          .then(() => encaixeAgenda(pAgenda))
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Agendamento não identificado!');
      }
    }

    if (situacao === 'Marcada') {
      pAgenda.SITUACAO = situacao;
      pAgenda.CODFUNCAGENDA = medico ? medico.idFunc : funcionario.codFunc;
      if (pAgenda) {
        api
          .put('/agenda/situacao', pAgenda)
          .catch(err => toast.error(err.response.data.error));
      } else {
        toast.warn('Agendamento não identificado!');
      }
    }
  }

  function checkEnter(e) {
    if (e.key === 'Enter') handlePaciente(nomePac);
  }

  function checkEnterDtNasc(e) {
    if (e.key === 'Enter') handlePacienteDtNasc();
  }

  function handlePaciente(nome) {
    dispatch(loadingOpen());
    if (nome.length > 2) {
      let c = 0;
      if (nome) {
        api
          .get(`/pacientebynome`, { params: { nome } })
          .then(result => {
            const p = result.data.map(paciente => ({
              ...paciente,
              dataFormatada:
                paciente.DTNPAC &&
                format(addDays(parseISO(paciente.DTNPAC), 1), 'dd/MM/yyyy', {
                  locale: pt,
                }),
              counter: (c += 1),
            }));
            setPacientes(p);
            setCount(c);
            setLoadingPac(true);
          })
          .catch(error => toast.error(error.response.data.error))
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Médico não identificado!');
      }
    } else {
      toast.warn('Digite ao menos 3 caracteres!');
    }
    dispatch(loadingClose());
  }

  async function handlePacienteDtNasc() {
    dispatch(loadingOpen());
    setLoadingPac(false);
    if (dtnNasc.length > 2) {
      let c = 0;
      if (dtnNasc) {
        api
          .get(`/pacientebydtnasc`, { params: { dt: dtnNasc } })
          .then(result => {
            const p = result.data.map(paciente => ({
              ...paciente,
              dataFormatada:
                paciente.DTNPAC &&
                format(addDays(parseISO(paciente.DTNPAC), 1), 'dd/MM/yyyy', {
                  locale: pt,
                }),
              counter: (c += 1),
            }));
            setPacientes(p);
            setCount(c);
            setLoadingPac(true);
          })
          .catch(error => toast.error(error.response.data.error))
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Médico não identificado!');
      }
    } else {
      toast.warn('Digite ao menos 3 caracteres!');
    }
    dispatch(loadingClose());
  }

  function handleGetAgenda(agendaPac) {
    setSelectedRow(agendaPac.CODAGENDA);
    dispatch(loadingOpen());
    dispatch(selectPacienteById({ CODPAC: agendaPac.CODPAC }));
    handleClearState();
    setDataFormatada(agendaPac.dataFormatada);
    setDataAgenda(agendaPac.DATAAGENDA);
    setCodAgenda(agendaPac.CODAGENDA);
    setCodPaciente(agendaPac.CODPAC);
    setNomePaciente(agendaPac.NOMEPAC);
    setNomePac(agendaPac.NOMEPAC);
    setCodMedico(agendaPac.CODMED);
    setNomeMedico(agendaPac.NOMEMED);
    setCodConvenio(agendaPac.CODCONV);
    setConvenio(agendaPac.CONVENIO);
    setCodProcedimento(agendaPac.CODPROCEDI);
    setProcedimento(agendaPac.PROCEDIMENTO);
    setHoraAgenda(agendaPac.HORARIO);
    setDataHoraAgenda(agendaPac.HORAAGENDA);
    setSituacao(agendaPac.SITUACAO);
    setAtividade(agendaPac.ATIVIDADE);
    setObservacoes(agendaPac.OBSERVACAO);
    setAgendou(agendaPac.AGENDOU);
    setMarcou(agendaPac.MARCOU);
    setCancelou(agendaPac.CANCELOU);
    setAgendamento(agendaPac.AGENDAMENTO);
    setAtendido(agendaPac.ATENDIDO);
    dispatch(loadingClose());
  }

  function handleEditAgenda(event, agendaPac) {
    event.preventDefault();
    dispatch(loadingOpen());
    dispatch(selectPacienteById({ CODPAC: agendaPac.CODPAC }));
    handleClearState();
    setDataFormatada(agendaPac.dataFormatada);
    setDataAgenda(agendaPac.DATAAGENDA);
    setCodAgenda(agendaPac.CODAGENDA);
    setCodPaciente(agendaPac.CODPAC);
    setNomePaciente(agendaPac.NOMEPAC);
    setCodMedico(agendaPac.CODMED);
    setNomeMedico(agendaPac.NOMEMED);
    setCodConvenio(agendaPac.CODCONV);
    setConvenio(agendaPac.CONVENIO);
    setCodProcedimento(agendaPac.CODPROCEDI);
    setProcedimento(agendaPac.PROCEDIMENTO);
    setHoraAgenda(agendaPac.HORARIO);
    setDataHoraAgenda(agendaPac.HORAAGENDA);
    setSituacao(agendaPac.SITUACAO);
    setAtividade(agendaPac.ATIVIDADE);
    setObservacoes(agendaPac.OBSERVACAO);
    setAgendou(agendaPac.AGENDOU);
    setMarcou(agendaPac.MARCOU);
    setCancelou(agendaPac.CANCELOU);
    setAgendamento(agendaPac.AGENDAMENTO);
    setAtendido(agendaPac.ATENDIDO);
    document.getElementById('codPaciente').focus();
    event.stopPropagation();
    dispatch(loadingClose());
  }

  function handleSelectPaciente(pac) {
    console.log(pac);
    setCodPaciente(pac.CODPAC);
    setNomePaciente(pac.NOMEPAC);
    setCodConvenio(pac.CODINTERNADO);
    setNomePac('');
    setDtnNasc('');
    setLoadingPac(false);
    setShowSearchPatientsModal(false);
    document.getElementById('codPaciente').focus();
  }

  function handleClearState() {
    setCodAgenda();
    setCodPaciente();
    setNomePaciente();
    setCodMedico();
    setNomeMedico();
    setCodConvenio();
    setConvenio();
    setCodProcedimento();
    setProcedimento();
    setHoraAgenda();
    setDataHoraAgenda();
    setSituacao();
    setAtividade();
    setObservacoes();
  }

  function handleCodigoMedico(e, vMedico) {
    if (searchParams.get('modalOpen') === 'true') {
      searchParams.delete('modalOpen');
      searchParams.delete('userId');
      searchParams.delete('pac');
    }

    setAgendaMed(e.target.textContent);
    setIdMedico(vMedico);
    searchParams.set('med', vMedico);
    searchParams.set('date', dateAgendaFormatted);
    history.push(`/agenda?${searchParams.toString()}`);
  }

  async function handleEcg(atividade) {
    // LAUDO LIBERADO (SIM ou NAO)
    if (atividade.GUIA1 === 'SIM') {
      dispatch(loadingOpen());
      await dispatch(selectEcgById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/reportEcg');
    } else {
      toast.warn('Laudo não Liberado para Internet!');
    }
  }

  async function handleStress(atividade) {
    dispatch(loadingOpen());
    if (
      funcionario ||
      medico?.tipo === 'Agenda' ||
      medico?.tipo === 'Externo'
    ) {
      // SANTA CASA
      if (atividade.CODLAUDO > 81610) {
        await dispatch(selectStressById(atividade.CODLAUDO));
        dispatch(loadingClose());
        history.push('/reportStress');
      } else {
        await dispatch(selectStressByIdOLD(atividade.CODLAUDO));
        dispatch(loadingClose());
        history.push('/reportStress');
      }
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleTrans(atividade) {
    dispatch(loadingOpen());
    // EXTERNO OU SANTA CASA
    if (
      funcionario ||
      medico?.tipo === 'Agenda' ||
      medico?.tipo === 'Externo'
    ) {
      await dispatch(selectTransById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/reportTrans');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleEspelhoTrans(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      await dispatch(selectTransById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoTrans');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleErgo(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      dispatch(selectErgoById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/reportErgo');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleEspelhoErgo(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      dispatch(selectErgoById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoErgo');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleEspelhoHolter(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      dispatch(selectHolterByID(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoHolter');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleEspelhoMapa(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      dispatch(selectMapaByID(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoMapa');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleEco(atividade) {
    if (medico?.tipo === 'Externo') {
      // SANTA CASA
      if (dbatividade.convenios.some(c => c.codConv === atividade.CODCONV)) {
        let c = 0;
        // LAUDO LIBERADO (SIM ou NAO)
        if (atividade.GUIA1 === 'SIM') {
          dispatch(loadingOpen());
          dispatch(selectEcoById(atividade.CODLAUDO))
            .then(eco => {
              const listaEco = { ...eco, counter: (c += 1) };
              setCount(c);
              if (listaEco.TIPOECO === 'EcoC') {
                history.push('/reportEcoCongenito');
              } else {
                history.push('/reportDoppler');
              }
            })
            .finally(() => dispatch(loadingClose()));
        } else {
          toast.warn('Laudo não Liberado para Internet!');
        }
      } else {
        toast.warn('Convênio não Autorizado!');
      }
    } else if (funcionario || medico?.tipo === 'Agenda') {
      // if (medico.codMed NAO FOR DA SANTA CASA (SE FOR DA EQUIPE))
      let c = 0;
      if (atividade.GUIA1 === 'SIM') {
        dispatch(loadingOpen());
        dispatch(selectEcoById(atividade.CODLAUDO))
          .then(eco => {
            const listaEco = { ...eco, counter: (c += 1) };
            setCount(c);
            if (listaEco.TIPOECO === 'EcoC') {
              history.push('/reportEcoCongenito');
            } else {
              history.push('/reportDoppler');
            }
          })
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Laudo não Liberado para Internet!');
      }
    } else {
      toast.warn('Visualização não permitida. Consulte a administração!');
    }
  }

  function handleEcoTXT(atividade) {
    if (medico?.tipo === 'Externo') {
      // SANTA CASA
      if (dbatividade.convenios.some(c => c.codConv === atividade.CODCONV)) {
        let c = 0;
        // LAUDO LIBERADO (SIM ou NAO)
        if (atividade.GUIA1 === 'SIM') {
          dispatch(loadingOpen());
          dispatch(selectEcoById(atividade.CODLAUDO))
            .then(eco => {
              const listaEco = { ...eco, counter: (c += 1) };
              setCount(c);
              if (listaEco.TIPOECO === 'EcoC') {
                history.push('/reportEcoCongenito');
              } else {
                history.push('/reportDoppler');
              }
            })
            .finally(() => dispatch(loadingClose()));
        } else {
          toast.warn('Laudo não Liberado para Internet!');
        }
      } else {
        toast.warn('Convênio não Autorizado!');
      }
    } else if (funcionario || medico?.tipo === 'Agenda') {
      // if (medico.codMed NAO FOR DA SANTA CASA (SE FOR DA EQUIPE))
      let c = 0;
      if (atividade.GUIA1 === 'SIM') {
        dispatch(loadingOpen());
        dispatch(selectEcoById(atividade.CODLAUDO))
          .then(eco => {
            const listaEco = { ...eco, counter: (c += 1) };
            setCount(c);
            if (listaEco.TIPOECO === 'EcoC') {
              history.push('/reportEcoCongenitoTXT');
            } else {
              toast.warn('Disponível para Laudo de Eco Congênito!');
            }
          })
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Laudo não Liberado para Internet!');
      }
    } else {
      toast.warn('Visualização não permitida. Consulte a administração!');
    }
  }

  async function handleEspelhoDoppler(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      dispatch(selectEcoById(atividade.CODLAUDO))
        .then(eco => {
          if (eco.TIPOECO === 'EcoD') {
            history.push('/espelhoDoppler');
          } else if (eco.TIPOECO === 'EcoC') {
            history.push('/espelhoCong');
          } else {
            toast.warn('Espelho Disponível somente para Laudo de Eco Doppler!');
          }
        })
        .finally(() => dispatch(loadingClose()));
    } else {
      toast.warn(
        'Visualização de Espelho não permitida. Consulte a administração do ICM!'
      );
    }
  }

  async function handleEspelhoFisio(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      await dispatch(selectStressById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoFisio');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleEspelhoFarmaco(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      await dispatch(selectStressById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoFarmaco');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleCarotida(atividade) {
    if (medico?.tipo !== 'Externo' && atividade.GUIA1 === 'SIM') {
      // SANTA CASA
      history.push({
        pathname: '/reportCarotida',
        state: { codLaudo: atividade.CODLAUDO },
      });
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleEspelhoCarotida(atividade) {
    if (funcionario || medico?.tipo === 'Agenda') {
      history.push({
        pathname: '/espelhoCarotida',
        state: { codLaudo: atividade.CODLAUDO },
      });
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  async function handleProntuario(atividade) {
    dispatch(loadingOpen());
    if (medico?.tipo !== 'Externo') {
      // SANTA CASA
      if (atividade.CODLAUDO !== 0) {
        await dispatch(selectProntuarioById(atividade.CODLAUDO));
        dispatch(loadingClose());
        history.push('/reportProntuario');
      } else {
        toast.warn('Prontuario não identificado!');
      }
    } else {
      toast.warn('Prontuário não Autorizado!');
    }
  }

  function handleFetal(atividade) {
    dispatch(loadingOpen());
    if (medico?.tipo !== 'Externo') {
      // SANTA CASA
      dispatch(selectFetalById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/reportFetal');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleEspelhoFetal(atividade) {
    dispatch(loadingOpen());
    if (funcionario || medico?.tipo === 'Agenda') {
      dispatch(selectFetalById(atividade.CODLAUDO));
      dispatch(loadingClose());
      history.push('/espelhoFetal');
    } else {
      toast.warn('Laudo não Autorizado!');
    }
  }

  function handleAtividades(atividade) {
    dispatch(loadingOpen());
    searchParams.set('modalOpen', 'true');
    searchParams.set('userId', atividade.CODPAC);
    searchParams.set('med', idMedico);
    searchParams.set('pac', atividade.NOMEPAC);
    setNomePac(atividade.NOMEPAC);

    if (medico?.tipo !== 'Externo') {
      let c = 0;
      if (atividade.CODPAC !== 0) {
        api
          .get(`paciente/atividades?id=${atividade.CODPAC}`)
          .then(result => {
            const data = result?.data?.map(atividade => ({
              ...atividade,
              dataFormatada: format(
                addDays(parseISO(atividade.DATALAUDO), 1),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              counter: (c += 1),
            }));
            setAtividades(data);
            setShowAtividadesModal(true);
            history.push(`/agenda?${searchParams.toString()}`);
          })
          .catch(err => toast.error(err.response.data.error))
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Paciente não identificado!');
      }
    } else {
      let c = 0;
      if (atividade.CODPAC !== 0) {
        api
          .get(`paciente/atividades?id=${atividade.CODPAC}`)
          .then(result => {
            const data = result?.data?.map(atividade => ({
              ...atividade,
              dataFormatada: format(
                addDays(parseISO(atividade.DATALAUDO), 1),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              counter: (c += 1),
            }));
            setAtividades(data);
            setShowAtividadesModal(true);
          })
          .catch(err => toast.error(err.response.data.error))
          .finally(() => dispatch(loadingClose()));
      } else {
        toast.warn('Paciente não identificado!');
      }
    }
  }

  function getFolderName(a) {
    switch (a.CODPROCEDI) {
      case 1:
      case 58:
      case 800:
        return 'prontuario';
      case 3:
        return 'ecg';
      case 13:
        return 'stress';
      case 12:
        return 'trans';
      case 4:
        return 'ergo';
      case 11:
      case 712:
      case 713:
      case 714:
        return 'eco';
      case 14:
      case 15:
        return 'carotida';
      case 17:
        return 'fetal';
      default:
        return toast.warn('Laudo não disponível para Visualização!');
    }
  }

  async function handleUploadFile(file) {
    const folder = getFolderName(atividade);
    const name = file.name
      .replaceAll(' ', '')
      .replaceAll('%20', '')
      .replaceAll('%2520', '');

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/${folder}/laudo/${codigoLaudo}/key/${name}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
    } catch (error) {
      toast.error('Erro ao enviar arquivo!');
    }
  }

  function handleAttachFile(atividade) {
    setShowUpload(true);
    setCodigoLaudo(atividade.CODLAUDO);
    setAtividade(atividade);
  }

  function closeFileModal() {
    setShowUpload(false);
    setFiles([]);
  }

  async function confirmUpload() {
    if (!files) return toast.warn('Selecione um arquivo!');

    for (const file of files) {
      await handleUploadFile(file);
    }

    toast.success('Arquivos enviados com sucesso!');
  }

  const handleFechamentoDiario = () => {
    dispatch(getFechamentoDiario(dateAgendaFormatted)).then(() => {
      history.push('/fechamento');
    });
  };

  const handleAgendaDiaria = () => {
    dispatch(getAgendaDiaria(dateAgendaFormatted)).then(() => {
      history.push('/agendadiaria');
    });
  };

  const verPaciente = async nome => {
    try {
      const result = await api.get(`/pacientebynome`, { params: { nome } });
      const p = result.data.map(paciente => ({
        ...paciente,
        dataFormatada:
          paciente.DTNPAC &&
          format(addDays(parseISO(paciente.DTNPAC), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
      }));
      setPacDetails(p[0]);
      setShowPatientDetailsModal(true);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="agenda"
        id="tabs-agenda"
        activeKey={activeKey}
        onSelect={key => {
          if (key === 'pac') setSearchPac(false);
          setActiveKey(key);
        }}
        className="m-1"
        style={{
          background: 'green',
          borderRadius: '2em',
          padding: '0.3em 1.5em',
          letterSpacing: '0.3em',
          fontWeight: 'bolder',
        }}
        fill
      >
        <Tab eventKey="agenda" title="AGENDA">
          <AlertReact variant="success">
            <Row>
              <Col sm={4}>
                <strong>
                  Agendamento
                  {codAgenda ? ` - ${codAgenda}` : ''}
                </strong>
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip>Cadastrar Médico</Tooltip>}>
                  <span className="d-inline-block">
                    <Button
                      onClick={() => setShowSearchMedicosModal(true)}
                      size="sm"
                      variant="dark"
                    >
                      <FaUserMd style={{ width: 18, height: 18 }} />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip>Cadastrar Paciente</Tooltip>}>
                  <span className="d-inline-block">
                    <Button
                      onClick={() => {
                        setSearchPac(false);
                        setLoadingPac(false);
                        setShowSearchPatientsModal(true);
                      }}
                      size="sm"
                      variant="success"
                    >
                      <FaProductHunt style={{ width: 18, height: 18 }} />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={1}>
                <OverlayTrigger
                  overlay={<Tooltip>Atividades do Paciente</Tooltip>}
                >
                  <span className="d-inline-block">
                    <Button
                      onClick={() => history.push(`/frmatv`)}
                      size="sm"
                      variant="warning"
                    >
                      <FaBroadcastTower style={{ width: 18, height: 18 }} />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip>Gerar Horários</Tooltip>}>
                  <span className="d-inline-block">
                    <Button
                      onClick={() => setShowAlertGeraHorarios(true)}
                      size="sm"
                    >
                      <FaCalendarCheck style={{ width: 18, height: 18 }} />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip>Fechamento Diário</Tooltip>}>
                  <span className="d-inline-block">
                    <Button
                      onClick={handleFechamentoDiario}
                      size="sm"
                      variant="info"
                    >
                      <FaMoneyBill style={{ width: 18, height: 18 }} />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={1}>
                <OverlayTrigger overlay={<Tooltip>Agenda Diária</Tooltip>}>
                  <span className="d-inline-block">
                    <Button
                      onClick={handleAgendaDiaria}
                      size="sm"
                      variant="secondary"
                    >
                      <FaCalendar style={{ width: 18, height: 18 }} />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col sm={2}>
                <Button
                  size="sm"
                  block
                  onClick={() => {
                    if (!codAgenda)
                      toast.info('Nenhuma Agenda foi Selecionada!');
                    else setShowAlertGravaAgenda(true);
                  }}
                >
                  Gravar
                </Button>
              </Col>
            </Row>
          </AlertReact>

          <AlertReact show={showAlertGeraHorarios} variant="warning">
            <AlertReact.Heading>
              Confirma a Geração de Horários da AGENDA?
            </AlertReact.Heading>
            MÉDICO:{' '}
            <strong>
              {idMedico} - {agendaMed}
            </strong>{' '}
            - Data: {dateAgendaFormatted}
            <hr />
            <Button onClick={() => gerarHorarios(idMedico)} variant="warning">
              Confirma Geração de HORÁRIOS?
            </Button>
          </AlertReact>

          <AlertReact show={showAlertGravaAgenda} variant="warning">
            <AlertReact.Heading>
              Confirma a Gravação da AGENDA?
            </AlertReact.Heading>
            Agendamento {codAgenda ? ` - ${codAgenda}` : ''} - Paciente:{' '}
            {nomePaciente}
            <hr />
            <Button onClick={handleGravarAgenda} variant="warning">
              Confirma Gravação da AGENDA?
            </Button>
          </AlertReact>

          <AlertReact show={showAlertGravaObs} variant="warning">
            <AlertReact.Heading>
              Confirma a Gravação da Observação na AGENDA?
            </AlertReact.Heading>
            Agendamento {codAgenda ? ` - ${codAgenda}` : ''} - Paciente:{' '}
            {nomePaciente}
            <hr />
            <Form.Row className="m-0">
              <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                <Form.Label className="m-0" size="sm">
                  Obs:
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="obsAgenda" className="m-0" as={Col} sm>
                <Form.Control
                  className="m-0"
                  size="sm"
                  as="textarea"
                  onChange={e => setObservacoes(e.target.value)}
                  value={observacoes || ''}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      const elemHora = document.getElementById('horaAgenda');
                      elemHora.focus();
                      if (elemHora) elemHora.select();
                    }
                  }}
                />
              </Form.Group>
            </Form.Row>
            <hr />
            <Button onClick={handleGravarObservacao} variant="warning">
              Confirma Gravação da AGENDA?
            </Button>
          </AlertReact>

          <Form noValidate validated={false} className="card m-1">
            <Card className="m-0">
              <Form.Row className="m-0">
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Paciente:
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  className="m-0"
                  size="sm"
                  as={Col}
                  sm={1}
                  controlId="codPaciente"
                >
                  <Form.Control
                    className="m-0"
                    style={{ textTransform: 'uppercase' }}
                    size="sm"
                    type="text"
                    onKeyUp={e => {
                      if (e.key === 'Enter' && codPaciente === 1) {
                        e.stopPropagation();
                        setShowSearchPatientsModal(true);
                      } else if (e.key === 'Enter' && codPaciente > 1) {
                        dispatch(
                          selectPacienteById({ CODPAC: Number(e.target.value) })
                        ).then(p => setNomePaciente(p.NOMEPAC));
                        const elemConv = document.getElementById('codConvenio');
                        elemConv.focus();
                        if (elemConv) elemConv.select();
                      }
                    }}
                    onChange={e => setCodPaciente(Number(e.target.value))}
                    value={codPaciente || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={4}>
                  <InputGroup size="sm" className="m-0">
                    <Form.Control
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      className="m-0"
                      style={{ textTransform: 'uppercase' }}
                      size="sm"
                      type="text"
                      onChange={e => setNomePaciente(e.target.value)}
                      value={nomePaciente || ''}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Médico:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={1}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    onChange={e => setCodMedico(Number(e.target.value))}
                    value={codMedico || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={4}>
                  <InputGroup size="sm" className="m-0">
                    <Form.Control
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      className="m-0"
                      style={{ textTransform: 'uppercase' }}
                      size="sm"
                      onChange={e => setNomeMedico(e.target.value)}
                      value={nomeMedico || ''}
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Row className="m-0">
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Conv:
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  controlId="codConvenio"
                  className="m-0"
                  as={Col}
                  sm={1}
                >
                  <Form.Control
                    className="m-0"
                    size="sm"
                    onChange={e => setCodConvenio(Number(e.target.value))}
                    value={codConvenio || ''}
                    onKeyUp={e => {
                      if (e.key === 'Enter' && codConvenio !== 1000) {
                        const filteredConv = convenios.filter(
                          item => item.codConv === Number(e.target.value)
                        );
                        setConvenio(filteredConv[0]?.sigla);
                        const pElem = document.getElementById(
                          'codProcedimento'
                        );
                        pElem.focus();
                        if (pElem) pElem.select();
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group
                  style={{ color: 'blue' }}
                  className="m-0"
                  as={Col}
                  sm={4}
                >
                  <Creatable
                    name="conve"
                    menuPlacement="top"
                    placeholder={fatura.convenio || 'Selecione o Convênio'}
                    options={selectConvenio}
                    value={selectConvenio.filter(o => o.value === codConvenio)}
                    onChange={option => {
                      setConvenio(option.label);
                      setCodConvenio(option.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Procedi:
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  controlId="codProcedimento"
                  className="m-0"
                  as={Col}
                  sm={1}
                >
                  <Form.Control
                    className="m-0"
                    size="sm"
                    onChange={e => setCodProcedimento(Number(e.target.value))}
                    value={codProcedimento || ''}
                    onKeyUp={e => {
                      if (e.key === 'Enter' && codProcedimento !== 1000) {
                        const p = procedimentos.filter(
                          item => item.CODPROCEDI === Number(e.target.value)
                        );
                        setProcedimento(p[0]?.DESCPROCEDI);
                        const elemObs = document.getElementById('obs');
                        elemObs.focus();
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="m-0"
                  as={Col}
                  sm={4}
                  style={{ color: 'blue' }}
                >
                  <Creatable
                    name="procedi"
                    menuPlacement="top"
                    placeholder={
                      fatura.procedimento || 'Selecione o Procedimento'
                    }
                    options={selectProcedi}
                    value={selectProcedi.filter(
                      opt => opt.value === codProcedimento
                    )}
                    onChange={option => {
                      setProcedimento(option.label);
                      setCodProcedimento(option.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row className="m-0">
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Obs:
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="obs" className="m-0" as={Col} sm>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    as="textarea"
                    rows="2"
                    onChange={e => setObservacoes(e.target.value)}
                    value={observacoes || ''}
                    onKeyDown={e => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        const elemHora = document.getElementById('horaAgenda');
                        elemHora.focus();
                        if (elemHora) elemHora.select();
                      }
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row className="m-0">
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Data:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={3}>
                  <div className="m-0">
                    <Form.Control
                      readOnly={true}
                      className="m-0"
                      size="sm"
                      value={dataFormatada}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Hora:
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  controlId="horaAgenda"
                  className="m-0"
                  as={Col}
                  sm={1}
                >
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={false}
                    onChange={e => setHoraAgenda(Number(e.target.value))}
                    value={horaAgenda || ''}
                    onKeyUp={e => {
                      if (e.key === 'Enter') setShowAlertGravaAgenda(true);
                    }}
                  />
                </Form.Group>

                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Situação:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={2}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setSituacao(e.target.value)}
                    value={situacao || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Atv:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={2}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setAtividade(e.target.value)}
                    value={atividade || ''}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row className="m-0">
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Agenda:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={1}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setAgendamento(e.target.value)}
                    value={agendamento || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Marcou:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={1}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setAgendou(e.target.value)}
                    value={agendou || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Atendido:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={1}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setAtendido(e.target.value)}
                    value={atendido || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Cancelou:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={1}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setCancelou(e.target.value)}
                    value={cancelou || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                  <Form.Label className="m-0" size="sm">
                    Altera:
                  </Form.Label>
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={1}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setMarcou(e.target.value)}
                    value={marcou || ''}
                  />
                </Form.Group>
                <Form.Group className="m-0" as={Col} sm={2}>
                  <Form.Control
                    className="m-0"
                    size="sm"
                    readOnly={true}
                    onChange={e => setDataHoraAgenda(e.target.value)}
                    value={dataHoraAgenda || ''}
                  />
                </Form.Group>
              </Form.Row>
            </Card>
          </Form>

          <Card className="text-center m-0 p-0">
            <Row className="m-0 p-0">
              <Col sm={2}>
                <Button disabled size="sm" variant="success" block>
                  {`Agenda - ${agendaMed}`}
                </Button>
              </Col>
              <Col sm={10}>
                {medicoAgenda.map(agenda => (
                  <Button
                    key={agenda.codigo}
                    size="sm"
                    variant="outline-success"
                    onClick={e => handleCodigoMedico(e, agenda.codigo)}
                  >
                    {agenda.medico}
                  </Button>
                ))}
              </Col>
            </Row>
          </Card>

          <Card style={{ marginTop: 10 }}>
            <Row className="m-0 p-0">
              <Col sm={2} className="m-0 p-0">
                <DatePicker
                  selected={dateAgenda}
                  onChange={date => setDateAgenda(date)}
                  inline
                  locale="ptBR"
                  todayButton="Hoje"
                  dateFormat="dd/MM/yyyy"
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                />
              </Col>
              <Col sm={10} className="m-0 p-0">
                <Table
                  className="m-0 p-0"
                  striped
                  bordered="true"
                  hover="true"
                  size="sm"
                  variant="primary"
                  style={{ fontSize: 10 }}
                >
                  <thead className="m-0 p-0">
                    <tr className="m-0 p-0" style={{ fontSize: 10 }}>
                      <th>#</th>
                      <th>Horário</th>
                      <th>Paciente</th>
                      <th>Proced</th>
                      <th>Conv</th>
                      <th>Atv</th>
                      <th>Status</th>
                      <th>Celular</th>
                      <th>Obs</th>
                      <th>Confirma</th>
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody className="m-0 p-0">
                    {agendamentos.map(a => (
                      <tr
                        key={a.counter}
                        style={changeColor(a)}
                        onClick={() => handleGetAgenda(a)} // Seleciona a linha ao clicar
                        className={
                          selectedRow === a.CODAGENDA ? 'table-success' : ''
                        }
                      >
                        <td>
                          <OverlayTrigger
                            trigger="hover"
                            overlay={<Tooltip id="a-radio">Atividades</Tooltip>}
                          >
                            <Form.Check
                              type="radio"
                              defaultChecked
                              label=""
                              onClick={async () => {
                                await handleGetAgenda(a);
                                await handleAtividades(a);
                              }}
                            />
                          </OverlayTrigger>
                        </td>
                        <td className="m-0 p-0">{formatarHora(a.HORARIO)}</td>
                        <td className="m-0 p-0">{a.NOMEPAC}</td>
                        <td className="m-0 p-0">{a.PROCEDIMENTO}</td>
                        <td className="m-0 p-0">{a.CONVENIO}</td>
                        <td className="m-0 p-0">{a.ATIVIDADE}</td>
                        <td className="m-0 p-0">{a.SITUACAO}</td>
                        <td className="m-0 p-0">{a.CELULARPAC}</td>
                        <td className="m-0 p-0">{a.OBSERVACAO}</td>
                        <td className="m-0 p-0">{a.STATUSCONFIRMA}</td>
                        <td className="m-0 p-0">
                          <DropdownButton
                            drop="left"
                            size="sm"
                            title="Menu"
                            onClick={() => handleGetAgenda(a)}
                          >
                            <div style={dropDownStyle}>
                              <Dropdown.Item
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                as="button"
                                onClick={() => handleAtividades(a)}
                              >
                                ATIVIDADES
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                as="button"
                                onClick={e => handleEditAgenda(e, a)}
                              >
                                Ir para Agenda
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                as="button"
                                onClick={() => verPaciente(a.NOMEPAC)}
                              >
                                Ver Paciente
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                as="button"
                                onClick={() =>
                                  alteraStatusAgenda(a, 'Reservada')
                                }
                              >
                                Agenda Reservada
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                as="button"
                                onClick={() =>
                                  alteraStatusAgenda(a, 'Cancelado')
                                }
                              >
                                Agenda Cancelada
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => alteraStatusAgenda(a, 'Fechada')}
                              >
                                Agenda Fechada
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => alteraStatusAgenda(a, 'Feriado')}
                              >
                                Agenda Feriado
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => alteraStatusAgenda(a, 'Marcada')}
                              >
                                Agenda Marcada
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => alteraStatusAgenda(a, 'Aberta')}
                              >
                                Agenda Aberta
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() =>
                                  alteraStatusAgenda(a, 'Em Atendimento')
                                }
                              >
                                Paciente Em Atendimento
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() =>
                                  alteraStatusAgenda(a, 'Atendido')
                                }
                              >
                                Paciente Atendido
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => alteraStatusAgenda(a, 'Faltou')}
                              >
                                Paciente Faltou
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() =>
                                  alteraStatusAgenda(a, 'Solicitado')
                                }
                              >
                                Paciente no ECG
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => encaixeAgenda(a)}
                              >
                                Encaixe na Agenda
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={() => setShowAlertGravaObs(true)}
                              >
                                Atualiza Observação
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                as="button"
                                style={dropDownStyle}
                                className="ml-3 p-0"
                                onClick={e => handleSelectFaturamento(e, a)}
                              >
                                FATURAMENTO
                              </Dropdown.Item>
                            </div>
                          </DropdownButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{ textAlign: 'right' }} colSpan="10">
                        TOTAL DE AGENDAMENTOS
                      </td>
                      <td style={{ textAlign: 'left' }} colSpan="1">
                        {count}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
          </Card>

          {showPatientDetailsModal && (
            <Modal
              show={showPatientDetailsModal}
              onHide={() => setShowPatientDetailsModal(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Detalhes do paciente</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormPaciente patient={pacDetails} isCreation={false} />
              </Modal.Body>
            </Modal>
          )}

          {showAtividadesModal && (
            <Modal
              show={showAtividadesModal}
              onHide={() => setShowAtividadesModal(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Exames do Paciente: {nomePac}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table
                  className="m-0 p-0"
                  responsive="sm"
                  striped
                  bordered
                  hover
                  size="sm"
                  variant="success"
                >
                  <thead className="m-0 p-0">
                    {medico?.tipo !== 'Externo' ? (
                      <tr>
                        <th>#</th>
                        <th>Proced</th>
                        <th>Realizou</th>
                        <th>Convênio</th>
                        <th>Data</th>
                        <th>Laudo</th>
                        <th>Guia</th>
                        <th>Valor</th>
                        <th>Laudado</th>
                        <th>Conv</th>
                        <th>Med</th>
                        <th>Enc</th>
                        <th>Fatura</th>
                        <th>#</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>#</th>
                        <th>Proced</th>
                        <th>Convênio</th>
                        <th>Data</th>
                        <th>Laudo</th>
                      </tr>
                    )}
                  </thead>
                  <tbody className="m-0 p-0">
                    {medico?.tipo !== 'Externo'
                      ? atividades.map(a => (
                          <tr key={a.counter} className="m-0 p-0">
                            <td className="m-0 p-0">{a.counter}</td>
                            <td className="m-0 p-0" style={{ color: 'blue' }}>
                              <Button
                                onClick={() => handleLaudar(a)}
                                variant="link"
                              >
                                {getFirstName(a.DESCPROCEDI)}
                              </Button>
                            </td>
                            <td className="m-0 p-0">{a.CODREALIZOU}</td>
                            <td className="m-0 p-0">{a.SIGLACONV}</td>
                            <td className="m-0 p-0">{a.dataFormatada}</td>
                            <td className="m-0 p-0">{a.CODLAUDO}</td>
                            <td className="m-0 p-0">{a.GUIA}</td>
                            <td className="m-0 p-0">{a.VLRLAUDO}</td>
                            <td className="m-0 p-0">{a.GUIA1}</td>
                            <td className="m-0 p-0">{a.CODCONV}</td>
                            <td className="m-0 p-0">{a.CODMEDICO}</td>
                            <td className="m-0 p-0">{a.CODENCAMINHOU}</td>
                            <td className="m-0 p-0">{a.CODFATURA}</td>
                            <td className="m-0 p-0">
                              <Dropdown>
                                <Dropdown.Toggle
                                  size="sm"
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  Menu
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => handleLaudar(a)}
                                  >
                                    Laudar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleAttachFile(a)}
                                  >
                                    Anexar Arquivo
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleLiberarLaudo(a)}
                                  >
                                    Liberar Laudo
                                  </Dropdown.Item>
                                  {a.CODPROCEDI === 11 ||
                                  a.CODPROCEDI === 712 ||
                                  a.CODPROCEDI === 713 ||
                                  a.CODPROCEDI === 714 ? (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(printClose());
                                          handleImprimir(a);
                                        }}
                                      >
                                        Imprimir
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(printOpen());
                                          handleImprimir(a);
                                        }}
                                      >
                                        Imprimir Timbrado
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleImprimirTXT(a)}
                                      >
                                        ImprimirTXT
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleEspelhoDoppler(a)}
                                      >
                                        Espelho Doppler
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleEspelhoDoppler(a)}
                                      >
                                        Espelho Congênito
                                      </Dropdown.Item>
                                    </>
                                  ) : a.CODPROCEDI === 13 ? (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(printClose());
                                          handleImprimir(a);
                                        }}
                                      >
                                        Imprimir
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleEspelhoFisio(a)}
                                      >
                                        Espelho Fisio
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleEspelhoFarmaco(a)}
                                      >
                                        Espelho Farmaco
                                      </Dropdown.Item>
                                    </>
                                  ) : (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(printClose());
                                          handleImprimir(a);
                                        }}
                                      >
                                        Imprimir
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          dispatch(printOpen());
                                          handleImprimir(a);
                                        }}
                                      >
                                        Imprimir Timbrado
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleImprimirEspelho(a)}
                                      >
                                        Imprimir Espelho
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleAtualizarGuiaConvenio(a)
                                    }
                                  >
                                    Atualizar Guia Convênio
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                      : atividades.map(a =>
                          dbatividade.convenios.some(
                            conv => conv.codConv === a.CODCONV
                          ) ? (
                            dbatividade.procedimentos.some(
                              procedi => procedi.codProcedi === a.CODPROCEDI
                            ) ? (
                              <tr key={a.counter}>
                                <td>{a.counter}</td>
                                {a.CODPROCEDI === 11 ||
                                a.CODPROCEDI === 712 ||
                                a.CODPROCEDI === 714 ? (
                                  <td>
                                    <Button
                                      variant="link"
                                      onClick={() => handleEco(a)}
                                    >
                                      {a.DESCPROCEDI}
                                    </Button>
                                  </td>
                                ) : (
                                  <td>{a.DESCPROCEDI}</td>
                                )}
                                <td>{a.SIGLACONV}</td>
                                <td>{a.dataFormatada}</td>
                                <td>{a.CODLAUDO}</td>
                              </tr>
                            ) : null
                          ) : null
                        )}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          )}

          {showSearchPatientsModal && (
            <Modal
              show={showSearchPatientsModal}
              onHide={() => setShowSearchPatientsModal(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Localizar Paciente</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row className="m-1">
                  <Col sm={6}>
                    <Form.Control
                      style={{ textTransform: 'uppercase' }}
                      value={nomePac}
                      id="nomePac"
                      required
                      ref={findPac}
                      type="text"
                      placeholder="Nome do Paciente"
                      onChange={e => setNomePac(e.target.value)}
                      onKeyUp={e => checkEnter(e)}
                    />
                  </Col>
                  <strong>DtNasc</strong>
                  <Col sm={3}>
                    <Form.Control
                      style={{ textTransform: 'uppercase' }}
                      value={dtnNasc}
                      type="date"
                      onChange={e => setDtnNasc(e.target.value)}
                      onKeyUp={e => checkEnterDtNasc(e)}
                    />
                  </Col>
                  <Col sm={1}>
                    <Button
                      onClick={() => {
                        setSearchPac(false);
                        setLoadingPac(false);
                        if (nomePac !== '') {
                          handlePaciente(nomePac);
                        } else {
                          if (dtnNasc !== '') handlePacienteDtNasc();
                        }
                      }}
                    >
                      Localizar
                    </Button>
                  </Col>
                  <Col sm={1}>
                    <Button
                      variant="success"
                      onClick={() => {
                        setSearchPac(false);
                        setLoadingPac(false);
                        dispatch(pacienteReset());
                        setSearchPac(true);
                      }}
                    >
                      Novo
                    </Button>
                  </Col>
                </Form.Row>

                {searchPac && (
                  <FormPaciente patient={patient} isCreation={true} />
                )}

                {loadingPac && (
                  <Form.Row>
                    <Col>
                      <Table
                        responsive="sm"
                        striped
                        bordered
                        hover
                        size="sm"
                        variant="success"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Dt Nasc</th>
                            <th>Celular</th>
                            <th>CPF</th>
                            <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pacientes.map(pac => (
                            <tr key={pac.counter}>
                              <td>{pac.counter}</td>
                              <td>{pac.CODPAC}</td>
                              <td>{pac.NOMEPAC}</td>
                              <td>{pac.dataFormatada}</td>
                              <td>{pac.CELULARPAC}</td>
                              <td>{pac.ENDMARPAC}</td>
                              <td style={{ textAlign: 'center' }}>
                                <Button
                                  size="sm"
                                  variant="info"
                                  onClick={() => handleSelectPaciente(pac)}
                                >
                                  Agendar
                                </Button>{' '}
                                <Button
                                  size="sm"
                                  onClick={() => {
                                    setLoadingPac(false);
                                    dispatch(
                                      pacienteSuccess({ paciente: pac })
                                    );
                                    setSearchPac(true);
                                  }}
                                >
                                  Editar
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Form.Row>
                )}
              </Modal.Body>
            </Modal>
          )}

          {showSearchMedicosModal && (
            <Modal
              show={showSearchMedicosModal}
              onHide={() => setShowSearchMedicosModal(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>CADASTRO DE MÉDICOS</Modal.Title>
              </Modal.Header>
              <Modal.Body>{<FormMedico />}</Modal.Body>
            </Modal>
          )}

          {showUpload && (
            <Modal show={showUpload} onHide={closeFileModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Anexar arquivo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomFileInput
                  id="file-upload"
                  label="Selecionar arquivo"
                  multiple={true}
                  onChange={e => setFiles(Array.from(e.target.files))}
                />
                <ul>{files && files.map(file => <li>{file.name}</li>)}</ul>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={confirmUpload}>Confirmar</Button>
              </Modal.Footer>
            </Modal>
          )}

          {showModalFaturamento && (
            <Modal
              show={showModalFaturamento}
              onHide={() => setShowModalFaturamento(false)}
              size="xl"
              centered
            >
              <Modal.Header style={{ background: 'blue' }} closeButton>
                <Modal.Title>FATURAMENTO DE ATIVIDADE</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col sm={8}>
                    <Card className="m-0">
                      <Form.Row className="m-1">
                        {[1, 3].includes(fatura.codProcedi) ? (
                          <>
                            <Form.Group
                              className="m-0"
                              size="sm"
                              as={Col}
                              sm={3}
                            >
                              <Form.Label className="m-2" size="sm">
                                <strong>Faturar ECG e Consulta?</strong>
                              </Form.Label>
                            </Form.Group>

                            <Form.Group className="m-0" as={Col} sm={3}>
                              <Form.Check
                                className="m-2"
                                label="Somente ECG"
                                name="groupEcg"
                                type="radio"
                                id="3"
                                checked={fatura.ecg === '3' ? true : false}
                                onChange={e =>
                                  setFatura({
                                    ...fatura,
                                    codProcedi: 3,
                                    ecg: e.target.id,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group className="m-0" as={Col} sm={3}>
                              <Form.Check
                                className="m-2"
                                label="Somente Consulta"
                                name="groupEcg"
                                type="radio"
                                id="1"
                                checked={fatura.ecg === '1' ? true : false}
                                onChange={e =>
                                  setFatura({
                                    ...fatura,
                                    codProcedi: 1,
                                    ecg: e.target.id,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group className="m-0" as={Col} sm={3}>
                              <Form.Check
                                className="m-2"
                                label="ECG e Consulta"
                                name="groupEcg"
                                type="radio"
                                id="31"
                                checked={fatura.ecg === '31' ? true : false}
                                onChange={e =>
                                  setFatura({
                                    ...fatura,
                                    codProcedi: 3,
                                    ecg: e.target.id,
                                  })
                                }
                              />
                            </Form.Group>

                            <Form.Group className="m-0" as={Col} sm={12}>
                              <hr></hr>
                            </Form.Group>
                          </>
                        ) : null}
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Data:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={6}>
                          <Form.Control
                            readOnly={true}
                            className="m-0"
                            size="sm"
                            onChange={e => handleChangeFaturamento(e)}
                            name="dataFatura"
                            value={fatura?.dataFormatada || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={1}>
                          <Form.Label className="m-0" size="sm">
                            Func:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={3}>
                          <Form.Control
                            readOnly={true}
                            size="sm"
                            onChange={e => handleChangeFaturamento(e)}
                            name="usuario"
                            value={fatura.usuario || ''}
                          />
                        </Form.Group>
                        <Form.Group className="mt-1" size="sm" as={Col} sm={2}>
                          <Form.Label className="mt-1" size="sm">
                            Paciente:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-1" size="sm" as={Col} sm={2}>
                          <Form.Control
                            readOnly={true}
                            size="sm"
                            type="text"
                            onChange={e => handleChangeFaturamento(e)}
                            name="codPac"
                            value={fatura.codPac || ''}
                          />
                        </Form.Group>
                        <Form.Group className="mt-1" size="sm" as={Col} sm={8}>
                          <Form.Control
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            readOnly={true}
                            size="sm"
                            type="text"
                            onChange={e => handleChangeFaturamento(e)}
                            name="paciente"
                            value={fatura.paciente || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={12}>
                          <hr></hr>
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Conveniado:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codConveniado"
                            onKeyUp={e =>
                              handleGetNomeMedico(
                                e,
                                'codSolicitou',
                                'conveniado'
                              )
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codConveniado"
                            value={fatura.codConveniado || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            size="sm"
                            name="conveniado"
                            menuPlacement="top"
                            placeholder={
                              fatura.conveniado || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos?.filter(
                              opt => opt.value === fatura.codConveniado
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                conveniado: option.label,
                                codConveniado: option.value,
                              });
                              document.getElementById('codConveniado').focus();
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Solicitou:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codSolicitou"
                            onKeyUp={e =>
                              handleGetNomeMedico(e, 'codRealizou', 'solicitou')
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codSolicitou"
                            value={fatura.codSolicitou || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            size="sm"
                            name="solicitou"
                            menuPlacement="top"
                            placeholder={
                              fatura.solicitou || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codSolicitou
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                solicitou: option.label,
                                codSolicitou: option.value,
                              });
                              document.getElementById('codSolicitou').focus();
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Realizou:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codRealizou"
                            onKeyUp={e =>
                              handleGetNomeMedico(e, 'codFaturou', 'realizou')
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codRealizou"
                            value={fatura.codRealizou || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            size="sm"
                            name="realizou"
                            menuPlacement="top"
                            placeholder={
                              fatura.realizou || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codRealizou
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                realizou: option.label,
                                codRealizou: option.value,
                              });
                              document.getElementById('codRealizou').focus();
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Faturou:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codFaturou"
                            onKeyUp={e =>
                              handleGetNomeMedico(e, 'codConv', 'faturou')
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codFaturou"
                            value={fatura.codFaturou || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            className="m-0"
                            size="sm"
                            name="faturou"
                            menuPlacement="top"
                            placeholder={
                              fatura.faturou || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codFaturou
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                faturou: option.label,
                                codFaturou: option.value,
                              });
                              document.getElementById('codFaturou').focus();
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Convênio:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codConv"
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                const filteredConv = convenios.filter(
                                  i => i.codConv === Number(e.target.value)
                                );
                                if (filteredConv.length > 0) {
                                  setFatura({
                                    ...fatura,
                                    convenio: filteredConv[0]?.sigla,
                                  });
                                  handleNextInput(e, 'codProcedi');
                                } else {
                                  toast.warn('Convênio não localizado!');
                                  handleNextInput(e, 'codConv');
                                }
                              }
                            }}
                            onChange={e => handleChangeFaturamento(e)}
                            name="codConv"
                            value={fatura.codConv || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            name="convenio"
                            menuPlacement="top"
                            placeholder={
                              fatura.convenio || 'Selecione o Convênio'
                            }
                            options={selectConvenio}
                            value={selectConvenio.filter(
                              opt => opt.value === fatura.codConv
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                convenio: option.label,
                                codConv: option.value,
                              });
                              document.getElementById('codConv').focus();
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Proced:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            id="codProcedi"
                            type="number"
                            style={{ backgroundColor: 'yellow' }}
                            onKeyUp={e =>
                              e.key === 'Enter' && handleValorFaturamento(e)
                            }
                            onBlur={e => handleValorFaturamento(e)}
                            onChange={e => handleChangeFaturamento(e)}
                            name="codProcedi"
                            value={fatura.codProcedi || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            name="procedimento"
                            menuPlacement="top"
                            placeholder={
                              fatura.procedimento || 'Selecione o Procedimento'
                            }
                            options={selectProcedi}
                            value={selectProcedi.filter(
                              opt => opt.value === fatura.codProcedi
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                procedimento: option.label,
                                codProcedi: option.value,
                              });
                              document.getElementById('codProcedi').focus();
                            }}
                          />
                        </Form.Group>
                        {fatura.codProcedi === 11 ||
                          (fatura.codProcedi === '11' && (
                            <>
                              <Form.Group className="m-0" as={Col} sm={2}>
                                <Form.Label className="mt-1" size="sm">
                                  Tipo de Eco:
                                </Form.Label>
                              </Form.Group>
                              <Form.Group className="m-0" as={Col} sm={2}>
                                <Form.Check
                                  className="m-2"
                                  label="Doppler"
                                  name="groupEco"
                                  type="radio"
                                  id="EcoD"
                                  checked={fatura.eco === 'EcoD' ? true : false}
                                  onChange={e =>
                                    setFatura({ ...fatura, eco: e.target.id })
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="m-0" as={Col} sm={2}>
                                <Form.Check
                                  className="m-2"
                                  label="Congênito"
                                  name="groupEco"
                                  type="radio"
                                  id="EcoC"
                                  checked={fatura.eco === 'EcoC' ? true : false}
                                  onChange={e =>
                                    setFatura({ ...fatura, eco: e.target.id })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                className="m-0"
                                as={Col}
                                sm={6}
                              ></Form.Group>
                            </>
                          ))}

                        <Form.Group className="mt-1" size="sm" as={Col} sm={2}>
                          <Form.Label className="mt-1" size="sm">
                            Usuário:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} sm={2}>
                          <Form.Control
                            readOnly={true}
                            size="sm"
                            onChange={e => handleChangeFaturamento(e)}
                            name="usuario"
                            value={fatura.usuario || ''}
                          />
                        </Form.Group>

                        {fatura.ecg === '31' ? (
                          <>
                            <Form.Group
                              className="mt-1"
                              size="sm"
                              as={Col}
                              sm={1}
                            >
                              <Form.Label className="mt-1" size="sm">
                                Guia Cons:
                              </Form.Label>
                            </Form.Group>
                            <Form.Group className="mt-1" as={Col} sm={2}>
                              <Form.Control
                                size="sm"
                                type="number"
                                id="guia"
                                onKeyUp={e => handleNextInput(e, 'valor')}
                                onChange={e => handleChangeFaturamento(e)}
                                name="guia"
                                value={fatura.guia}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mt-1"
                              size="sm"
                              as={Col}
                              sm={1}
                            >
                              <Form.Label className="mt-1" size="sm">
                                Guia Ecg:
                              </Form.Label>
                            </Form.Group>
                            <Form.Group className="mt-1" as={Col} sm={2}>
                              <Form.Control
                                size="sm"
                                type="number"
                                id="guiaecg"
                                onKeyUp={e => handleNextInput(e, 'valor')}
                                onChange={e => handleChangeFaturamento(e)}
                                name="guiaecg"
                                value={fatura.guiaecg}
                              />
                            </Form.Group>
                          </>
                        ) : fatura.ecg === '3' ? (
                          <>
                            <Form.Group
                              className="mt-1"
                              size="sm"
                              as={Col}
                              sm={1}
                            >
                              <Form.Label className="mt-1" size="sm">
                                Guia Ecg:
                              </Form.Label>
                            </Form.Group>
                            <Form.Group className="mt-1" as={Col} sm={2}>
                              <Form.Control
                                size="sm"
                                type="number"
                                id="guiaecg"
                                onKeyUp={e => handleNextInput(e, 'valor')}
                                onChange={e => handleChangeFaturamento(e)}
                                name="guiaecg"
                                value={fatura.guiaecg}
                              />
                            </Form.Group>
                          </>
                        ) : (
                          <>
                            <Form.Group
                              className="mt-1"
                              size="sm"
                              as={Col}
                              sm={1}
                            >
                              <Form.Label className="mt-1" size="sm">
                                Guia:
                              </Form.Label>
                            </Form.Group>
                            <Form.Group className="mt-1" as={Col} sm={2}>
                              <Form.Control
                                size="sm"
                                type="number"
                                id="guia"
                                onKeyUp={e => handleNextInput(e, 'valor')}
                                onChange={e => handleChangeFaturamento(e)}
                                name="guia"
                                value={fatura.guia}
                              />
                            </Form.Group>
                          </>
                        )}
                        <Form.Group className="mt-1" size="sm" as={Col} sm={1}>
                          <Form.Label className="mt-1" size="sm">
                            Valor:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} sm={1}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="valor"
                            onKeyUp={e => {
                              if (e.keyCode === 13) {
                                setShowBtnFaturamento(false);
                                document.getElementById('btnInserir').focus();
                              }
                            }}
                            onChange={e => handleChangeFaturamento(e)}
                            name="valor"
                            value={fatura.valor || 0}
                          />
                        </Form.Group>
                      </Form.Row>

                      <Card.Footer className="text-center">
                        <Button
                          id="btnInserir"
                          size="md"
                          disabled={showBtnFaturamento}
                          onClick={() => setShowAlertFaturamento(true)}
                        >
                          Gravar
                        </Button>{' '}
                        <Button
                          variant="secondary"
                          size="md"
                          onClick={() => setShowModalFaturamento(false)}
                        >
                          Fechar
                        </Button>
                      </Card.Footer>
                      <AlertReact
                        show={showAlertFaturamento}
                        variant="warning"
                        style={{ textAlign: 'right' }}
                      >
                        <AlertReact.Heading>
                          Confirma a Gravação do Faturamento?
                        </AlertReact.Heading>
                        <p></p>
                        <hr />
                        <div className="text-right">
                          <Button
                            id="btnGravarFat"
                            disabled={showBtnFaturamento}
                            onClick={e => handleInsertFaturamento(e)}
                            variant="warning"
                          >
                            Gravar Faturamento
                          </Button>
                        </div>
                      </AlertReact>
                      <AlertReact
                        show={showAlertEspelho}
                        variant="success"
                        style={{ textAlign: 'right' }}
                      >
                        <AlertReact.Heading>
                          Confirma a Impressão do Espelho?
                        </AlertReact.Heading>
                        <p></p>
                        <hr />
                        <div>
                          {faturado?.map(a =>
                            a.CODPROCEDI === 13 ? (
                              <>
                                <Form.Check
                                  id={a.CODLAUDO}
                                  key={new Date().getTime()}
                                  inline
                                  type="radio"
                                  name="radioEspelho"
                                  label="Espelho Farmaco"
                                  size="sm"
                                  onClick={() => handleEspelhoFarmaco(a)}
                                />
                                <Form.Check
                                  id={a.CODLAUDO}
                                  key={new Date().getTime()}
                                  inline
                                  type="radio"
                                  name="radioEspelho"
                                  label="Espelho Fisio"
                                  size="sm"
                                  onClick={() => handleEspelhoFisio(a)}
                                />
                              </>
                            ) : a.CODPROCEDI === 11 ||
                              a.CODPROCEDI === 712 ||
                              a.CODPROCEDI === 713 ||
                              a.CODPROCEDI === 714 ? (
                              <Form.Check
                                id={a.CODLAUDO}
                                key={new Date().getTime()}
                                inline
                                type="radio"
                                name="radioEspelho"
                                label={a.DESCPROCEDI}
                                size="sm"
                                onClick={() => handleEspelhoDoppler(a)}
                              />
                            ) : memoAtividade.includes(a.CODPROCEDI) ? (
                              <Form.Check
                                id={a.CODLAUDO}
                                key={new Date().getTime()}
                                inline
                                type="radio"
                                name="radioEspelho"
                                label={a.DESCPROCEDI}
                                size="sm"
                                onClick={() => handleImprimirEspelho(a)}
                              />
                            ) : null
                          )}
                        </div>
                        <hr />
                      </AlertReact>
                    </Card>{' '}
                  </Col>
                  <Col sm={4}>
                    <Card>
                      <Card.Header className="text-center h4">
                        Novo Médico
                      </Card.Header>
                      <Row className="m-0">
                        <Col sm={12}>
                          <Form.Group controlId="formNome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Digite o nome do médico"
                              value={novoMedico?.nome || ''}
                              onChange={e =>
                                setNovoMedico({
                                  ...novoMedico,
                                  nome: e.target.value,
                                })
                              }
                              onKeyUp={e => handleOnEnterPress(e, 'formCPF')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="m-0">
                        <Col sm={12}>
                          <Form.Group controlId="formCPF">
                            <Form.Label>CPF</Form.Label>
                            <Form.Control
                              type="text"
                              value={novoMedico?.cpfusuario || ''}
                              onChange={e =>
                                setNovoMedico({
                                  ...novoMedico,
                                  cpfusuario: e.target.value,
                                })
                              }
                              onKeyUp={e => handleOnEnterPress(e, 'formEspec')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="m-0">
                        <Col sm={12}>
                          <Form.Group controlId="formEspec">
                            <Form.Label>Especialidade</Form.Label>
                            <Form.Control
                              type="text"
                              value={novoMedico?.especialidade || ''}
                              onChange={e =>
                                setNovoMedico({
                                  ...novoMedico,
                                  especialidade: e.target.value,
                                })
                              }
                              onKeyUp={e => handleOnEnterPress(e, 'formCRM')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="m-0">
                        <Col>
                          <Form.Group controlId="formCRM">
                            <Form.Label>CRM</Form.Label>
                            <Form.Control
                              type="text"
                              value={novoMedico?.crm || ''}
                              onChange={e =>
                                setNovoMedico({
                                  ...novoMedico,
                                  crm: e.target.value,
                                })
                              }
                              onKeyUp={e => handleOnEnterPress(e, 'formRQE')}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formRQE">
                            <Form.Label>RQE</Form.Label>
                            <Form.Control
                              type="text"
                              value={novoMedico?.rqe || ''}
                              onChange={e =>
                                setNovoMedico({
                                  ...novoMedico,
                                  rqe: e.target.value,
                                })
                              }
                              onKeyUp={e => handleOnEnterPress(e, 'btnInserir')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Card.Footer className="text-center mt-3">
                        <Button
                          id="btnInserir"
                          variant="info"
                          size="md"
                          onClick={e => handleInserirMedico(e)}
                        >
                          Gravar
                        </Button>{' '}
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          )}

          {showModalUpdateFat && (
            <Modal
              show={showModalUpdateFat}
              onHide={() => setShowModalUpdateFat(false)}
              size="xl"
              centered
            >
              <Modal.Header style={{ background: 'red' }} closeButton>
                <Modal.Title>
                  ATUALIZA FATURAMENTO - {fatura.codFatura} - LAUDO:{' '}
                  {fatura.codLaudo}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Card className="m-0">
                      <Form.Row className="m-1">
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Data:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={10}>
                          <Form.Control
                            readOnly={true}
                            className="m-0"
                            size="sm"
                            onChange={e => handleChangeFaturamento(e)}
                            name="dataFatura"
                            value={fatura.dataFormatada || ''}
                          />
                        </Form.Group>
                        <hr></hr>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Paciente:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Control
                            className="m-0"
                            readOnly={true}
                            size="sm"
                            type="text"
                            onChange={e => handleChangeFaturamento(e)}
                            name="codPac"
                            value={fatura.codPac || ''}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" size="sm" as={Col} sm={8}>
                          <InputGroup size="sm" className="m-0">
                            <Form.Control
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              className="m-0"
                              readOnly={true}
                              size="sm"
                              type="text"
                              onChange={e => handleChangeFaturamento(e)}
                              name="paciente"
                              value={fatura.paciente || ''}
                            />
                          </InputGroup>
                        </Form.Group>

                        <hr></hr>

                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Conveniado:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codConveniado"
                            onKeyUp={e =>
                              handleGetNomeMedico(
                                e,
                                'codSolicitou',
                                'conveniado'
                              )
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codConveniado"
                            value={fatura.codConveniado || ''}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            size="sm"
                            name="conveniado"
                            menuPlacement="top"
                            placeholder={
                              fatura.conveniado || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codConveniado
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                conveniado: option.label,
                                codConveniado: option.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Solicitou:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codSolicitou"
                            onKeyUp={e =>
                              handleGetNomeMedico(e, 'codRealizou', 'solicitou')
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codSolicitou"
                            value={fatura.codSolicitou || ''}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            size="sm"
                            name="solicitou"
                            menuPlacement="top"
                            placeholder={
                              fatura.solicitou || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codSolicitou
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                solicitou: option.label,
                                codSolicitou: option.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Realizou:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codRealizou"
                            onKeyUp={e =>
                              handleGetNomeMedico(e, 'codFaturou', 'realizou')
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codRealizou"
                            value={fatura.codRealizou || ''}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            size="sm"
                            name="realizou"
                            menuPlacement="top"
                            placeholder={
                              fatura.realizou || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codRealizou
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                realizou: option.label,
                                codRealizou: option.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Faturou:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codFaturou"
                            onKeyUp={e =>
                              handleGetNomeMedico(e, 'codConv', 'faturou')
                            }
                            onChange={e => handleChangeFaturamento(e)}
                            name="codFaturou"
                            value={fatura.codFaturou || ''}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            className="m-0"
                            size="sm"
                            name="faturou"
                            menuPlacement="top"
                            placeholder={
                              fatura.faturou || 'Selecione um Médico'
                            }
                            options={selectMedicos}
                            value={selectMedicos.filter(
                              opt => opt.value === fatura.codFaturou
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                faturou: option.label,
                                codFaturou: option.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Convênio:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="codConv"
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                const filteredConv = convenios.filter(
                                  c => c.codConv === Number(e.target.value)
                                );
                                if (filteredConv.length > 0) {
                                  setFatura({
                                    ...fatura,
                                    convenio: filteredConv[0]?.sigla,
                                  });
                                  handleNextInput(e, 'codProcedi');
                                } else {
                                  toast.warn('Convênio não localizado!');
                                  handleNextInput(e, 'codConv');
                                }
                              }
                            }}
                            onChange={e => handleChangeFaturamento(e)}
                            name="codConv"
                            value={fatura.codConv || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            name="convenio"
                            menuPlacement="top"
                            placeholder={
                              fatura.convenio || 'Selecione o Convênio'
                            }
                            options={selectConvenio}
                            value={selectConvenio.filter(
                              opt => opt.value === fatura.codConv
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                convenio: option.label,
                                codConv: option.value,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="m-0" size="sm" as={Col} sm={2}>
                          <Form.Label className="m-0" size="sm">
                            Proced:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={2}>
                          <Form.Control
                            size="sm"
                            id="codProcedi"
                            type="number"
                            style={{ backgroundColor: 'yellow' }}
                            onKeyUp={e =>
                              e.key === 'Enter' && handleValorFaturamento(e)
                            }
                            onBlur={e => handleValorFaturamento(e)}
                            onChange={e => handleChangeFaturamento(e)}
                            name="codProcedi"
                            value={fatura.codProcedi || ''}
                          />
                        </Form.Group>
                        <Form.Group className="m-0" as={Col} sm={8}>
                          <Creatable
                            name="procedimento"
                            menuPlacement="top"
                            placeholder={
                              fatura.procedimento || 'Selecione o Procedimento'
                            }
                            options={selectProcedi}
                            value={selectProcedi.filter(
                              opt => opt.value === fatura.codProcedi
                            )}
                            onChange={option => {
                              setFatura({
                                ...fatura,
                                procedimento: option.label,
                                codProcedi: option.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="mt-1" size="sm" as={Col} sm={2}>
                          <Form.Label className="mt-1" size="sm">
                            Usuário:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} sm={2}>
                          <Form.Control
                            readOnly={true}
                            size="sm"
                            onChange={e => handleChangeFaturamento(e)}
                            name="usuario"
                            value={fatura.usuario || ''}
                          />
                        </Form.Group>
                        <Form.Group className="mt-1" size="sm" as={Col} sm={1}>
                          <Form.Label className="mt-1" size="sm">
                            Guia:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} sm={3}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id={fatura.codProcedi === 3 ? 'guiaecg' : 'guia'}
                            onKeyUp={e => handleNextInput(e, 'valor')}
                            onChange={e => handleChangeFaturamento(e)}
                            name={fatura.codProcedi === 3 ? 'guiaecg' : 'guia'}
                            value={
                              fatura.codProcedi === 3
                                ? fatura.guiaecg
                                : fatura.guia
                            }
                          />
                        </Form.Group>
                        <Form.Group className="mt-1" size="sm" as={Col} sm={1}>
                          <Form.Label className="mt-1" size="sm">
                            Valor:
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} sm={3}>
                          <Form.Control
                            size="sm"
                            type="number"
                            id="valor"
                            onKeyUp={e => {
                              if (e.keyCode === 13) {
                                setShowBtnFaturamento(false);
                                document.getElementById('btnAtualizar').focus();
                              }
                            }}
                            onChange={e => handleChangeFaturamento(e)}
                            name="valor"
                            value={fatura.valor || 0}
                          />
                        </Form.Group>
                      </Form.Row>

                      <Card.Footer className="text-center">
                        <Button
                          size="md"
                          disabled={showBtnFaturamento}
                          onClick={() => setShowAlertFaturamento(true)}
                        >
                          Gravar
                        </Button>{' '}
                        <Button
                          variant="secondary"
                          size="md"
                          onClick={() => setShowModalUpdateFat(false)}
                        >
                          Fechar
                        </Button>
                      </Card.Footer>
                      <AlertReact
                        show={showAlertFaturamento}
                        variant="warning"
                        style={{ textAlign: 'right' }}
                      >
                        <AlertReact.Heading>
                          Confirma a Atualização do Faturamento?
                        </AlertReact.Heading>
                        <p></p>
                        <hr />
                        <div className="text-right">
                          <Button
                            id="btnUpdateFat"
                            disabled={showBtnFaturamento}
                            onClick={e => handleUpdateFaturamento(e)}
                            variant="warning"
                          >
                            Atualizar Faturamento
                          </Button>
                        </div>
                      </AlertReact>
                    </Card>{' '}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          )}
        </Tab>
        <Tab eventKey="pac" title="PACIENTE">
          <Card>
            <Card.Header>
              <h4 className="mb-0">
                <span className="text-success">LOCALIZAR PACIENTE</span>
              </h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={6}>
                  <Form.Control
                    style={{ textTransform: 'uppercase' }}
                    value={nomePac}
                    id="nomePac"
                    required
                    autoFocus
                    type="text"
                    placeholder="Nome do Paciente"
                    onChange={e => setNomePac(e.target.value)}
                    onKeyUp={e => checkEnter(e)}
                  />
                </Col>
                <Col sm={1}>
                  <strong className="m-0">DtNasc</strong>
                </Col>
                <Col sm={3}>
                  <Form.Control
                    style={{ textTransform: 'uppercase' }}
                    value={dtnNasc}
                    type="date"
                    onChange={e => setDtnNasc(e.target.value)}
                    onKeyUp={e => checkEnterDtNasc(e)}
                  />
                </Col>
                <Col sm={2}>
                  <Button
                    onClick={() => {
                      if (nomePac !== '') {
                        handlePaciente(nomePac);
                      } else {
                        if (dtnNasc !== '') handlePacienteDtNasc();
                      }
                    }}
                  >
                    Localizar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {loadingPac && (
            <Table
              responsive="sm"
              striped
              bordered
              hover
              size="sm"
              variant="primary"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Dt Nasc</th>
                  <th>Celular</th>
                  <th>Código</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {pacientes.map(pac => (
                  <tr key={pac.counter}>
                    <td>{pac.counter}</td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => handleSelectPaciente(pac)}
                      >
                        {pac.NOMEPAC}
                      </Button>
                    </td>
                    <td>{pac.dataFormatada}</td>
                    <td>{pac.CELULARPAC}</td>
                    <td>{pac.CODPAC}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Button
                        size="sm"
                        onClick={async () => {
                          setLoadingPac(false);
                          await dispatch(
                            selectPacienteById({ CODPAC: pac.CODPAC })
                          );
                          setSearchPac(true);
                        }}
                      >
                        Editar
                      </Button>{' '}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => {
                          setLoadingPac(false);
                          dispatch(pacienteReset());
                          setSearchPac(true);
                        }}
                      >
                        Novo
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan="5">
                    TOTAL DE PACIENTES
                  </td>
                  <td style={{ textAlign: 'left' }} colSpan="1">
                    {count}
                  </td>
                </tr>
              </tfoot>
            </Table>
          )}
        </Tab>
      </Tabs>

      {loading && <Loading />}
    </>
  );
}
