import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Table,
  Row,
  Col,
  Button,
  Card,
  Alert,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from 'react-bootstrap';
import sha256 from 'js-sha256';
import jsPDF from 'jspdf';
import { FaBars, FaArrowRight, FaAngleDown, FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import md5 from 'md5';
import QRCode from 'qrcode';
import { Box } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { Chart } from 'react-google-charts';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { parseISO, format, addDays, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt';
import Creatable from 'react-select/creatable';
import {
  updateProntuarioById,
  insertProntuarioById,
  prontuarioSuccess,
  insertReceita,
  insertAtestado,
  insertRequisicao,
} from '../../../redux/features/prontuario/prontuarioSlice';
import {
  getExameLabByPac,
  getViewExameLabByPac,
  getViewLabResultsByPac,
  getTabelaExameLabByPac,
} from '../../../redux/features/prontuario/laboratorioSlice';
import { updateAgendaAtendido } from '../../../redux/features/prontuario/agendaSlice';
import {
  loadingOpen,
  loadingClose,
  signingOpen,
  signingClose,
} from '../../../redux/features/context/contextSlice';
import {
  selectStressById,
  selectStressByIdOLD,
} from '../../../redux/features/laudo/stressSlice';
import { selectTransById } from '../../../redux/features/laudo/transSlice';
import { selectHolterByID } from '../../../redux/features/laudo/holterSlice';
import { selectErgoById } from '../../../redux/features/laudo/ergoSlice';
import { selectEcoById } from '../../../redux/features/laudo/ecoSlice';
import { selectMapaByID } from '../../../redux/features/laudo/mapaSlice';
import { selectFetalById } from '../../../redux/features/laudo/fetalSlice';
import history from '../../../services/history';
import api from '../../../services/api';
import dataExtenso from '../../../util/dataExtenso';
import Loading from '../../../pages/Index/Loading';
import dbprontuario, { getRandomColor } from '../../../util/prontuario';
import styles from '../../../styles/styles.module.css';
import header from '../../../assets/icm-header.jpg';
import icp from '../../../assets/icp-brasil.png';
import prefixo from '../../../util/prefixo';
import getPrescricao from '../../../util/getPrescricao';
import { digitalSignature } from '../../../redux/features/certificate/certificateSlice';
import { selectEcgById } from '../../../redux/features/laudo/ecgSlice';
import PacienteInfo from '../../../pages/patient/Paciente/PacienteInfo';
import ReportStress from '../stress/ReportStress';
import ReportHolter from '../holter/ReportHolter';
import ReportCongTXT from '../eco/ReportCongTXT';
import ReportDoppler from '../eco/ReportDoppler';
import ReportErgo from '../ergo/ReportErgo';
import ReportMapa from '../mapa/ReportMapa';
import ReportFetal from '../fetal/ReportFetal';
import ReportTrans from '../trans/ReportTrans';
import removeBeforeBase64 from '../../../util/removeBeforeBase64';
import FormEcg from '../ecg/FormEcg';
import {
  updateRequisicao,
  updateAtestado,
  updateReceita,
} from '../../../services/prontuario';

const QRCODE_RECEITA = 'https://icm-marilia.com/receita/validacao';
const QRCODE_ATESTADO = 'https://icm-marilia.com/atestado/validacao';
const QRCODE_REQUISICAO = 'https://icm-marilia.com/requisicao/validacao';

export default function FormProntuario() {
  const dispatch = useDispatch();
  const medico = useSelector(state => state.doctor.profile);
  const { prontuario, pedido } = useSelector(state => state.prontuario);
  const { ecg } = useSelector(state => state.ecg);
  const {
    tipoexame,
    exames,
    viewlabresults,
    viewexames,
    tabelaexames,
  } = useSelector(state => state.laboratorio);
  const { patient } = useSelector(state => state.paciente);
  const { funcionario } = useSelector(state => state.funcionario);
  const { loading, signing } = useSelector(state => state.contexto);
  const { show } = useContextMenu();
  const atual = prontuario?.atual;
  const anterior = prontuario?.anterior;
  const anteriores = prontuario?.anteriores;
  const [receitaCounter, setReceitaCounter] = useState(1);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [atividades, setAtividades] = useState([]);
  const [showConsulta, setShowConsulta] = useState(false);
  const [showMedicamento, setShowMedicamento] = useState(false);
  const [showExamesCompl, setShowExamesCompl] = useState(false);
  const [showMedicamentoReceita, setShowMedicamentoReceita] = useState(false);
  const [showTransModal, setShowTransModal] = useState(false);
  const [showLaudoModal, setShowLaudoModal] = useState(false);
  const [showEletroModal, setShowEletroModal] = useState(false);
  const [showConsultaTelefone, setShowConsultaTelefone] = useState(false);
  const [showHolterModal, setShowHolterModal] = useState(false);
  const [showMapaModal, setShowMapaModal] = useState(false);
  const [showGravaParcial, setShowGravaParcial] = useState(false);
  const [showErgoModal, setShowErgoModal] = useState(false);
  const [showGravaTotal, setShowGravaTotal] = useState(false);
  const [showReceita, setShowReceita] = useState(false);
  const [showRadioAtestado, setShowRadioAtestado] = useState(false);
  const [showRadioRequisicao, setShowRadioRequisicao] = useState(false);
  const [showFetalModal, setShowFetalModal] = useState(false);
  const [showAtestado, setShowAtestado] = useState(false);
  const [showRequisicao, setShowRequisicao] = useState(false);
  const [showExamesLab, setShowExamesLab] = useState(false);
  const [toggleValor, setToggleValor] = useState();
  const [toggleResultado, setToggleResultado] = useState();
  const [showGraficoExamesLab, setShowGraficoExamesLab] = useState(false);
  const [showTabelaExamesLab, setShowTabelaExamesLab] = useState(false);
  const [searchReceitas, setSearchReceitas] = useState(false);
  const [searchAtestados, setSearchAtestados] = useState(false);
  const [searchRequisicoes, setSearchRequisicoes] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [total, setTotal] = useState(0);
  const [fonteSize, setFonteSize] = useState(12);
  const [activeKey, setActiveKey] = useState('prontuario');
  const [usoReceita, setUsoReceita] = useState('oral-continuo');
  const [showStressModal, setShowStressModal] = useState(false);
  const [paciente, setPaciente] = useState({
    celular: patient.CELULARPAC,
    cidade: patient.CIDADEPAC,
    codPac: patient.CODPAC,
    cpf: patient.CPF,
    dtNasc: patient.dataFormatada,
    numero: patient.ENDERECO_NR,
    rua: patient.ENDRESPAC,
    estado: patient.ESTADOPAC,
    logradouro: patient.LOGRA_PAC,
    bairro: patient.BAIRROPAC,
    nome: patient.NOMEPAC,
    enderecoPac: `${patient.LOGRA_PAC} ${patient.ENDRESPAC}, ${patient.ENDERECO_NR} - ${patient.BAIRROPAC} - ${patient.CIDADEPAC}/${patient.ESTADOPAC}`,
  });
  const [showAlertAtestadoGravado, setShowAlertAtestadoGravado] = useState(
    false
  );
  const [showAlertRequisicaoGravada, setShowAlertRequisicaoGravada] = useState(
    false
  );
  const [showAlertAtestados, setShowAlertAtestados] = useState(false);
  const [showAlertRequisicoes, setShowAlertRequisicoes] = useState(false);
  const [showAlertGravada, setShowAlertGravada] = useState(false);
  const [showEcocongModal, setShowEcocongModal] = useState(false);
  const [showEcodopplerModal, setShowEcodopplerModal] = useState(false);
  const [showAlertReceitas, setShowAlertReceitas] = useState(false);
  const [alert, setAlert] = useState({ variant: '', mensagem: '' });
  const [laudo, setLaudo] = useState();
  const [fieldValues, setFieldValues] = useState({
    codconsulta: anteriores[0]?.CODCONSULTA || '',
    queixas: anteriores[0]?.QUEIXAS || '',
    familiares: anteriores[0]?.ANTECEDENTES_FLIA || '',
    pessoais: anteriores[0]?.ANTECEDENTES_PESSOAIS || '',
    conduta: anteriores[0]?.CONDUTA || '',
    complementares: anteriores[0]?.EXAMES_COMPLEMENTARES || '',
    fisico: anteriores[0]?.EXAME_FISICO || '',
    habitos: anteriores[0]?.HABITOS || '',
    hipotese: anteriores[0]?.HIPOTESE_DIAGNOSTICA || '',
    isda: anteriores[0]?.ISDA || '',
    medicamentos: anteriores[0]?.MEDICAMENTOS || '',
    obs: anteriores[0]?.OBS || '',
    observacao: anteriores[0]?.OBSERVACOES || '',
    peso: anteriores[0]?.PESOPAC || '',
    cid: anteriores[0]?.CID || '',
    cid2: anteriores[0]?.CID2 || '',
    cid3: anteriores[0]?.CID3 || '',
    gravado: anteriores[0]?.GRAVADO || '',
    tipo: anteriores[0]?.TIPO || '',
    dataFormatada: anteriores[0]?.dataFormatada || '',
    idadePAC: anteriores[0]?.idadePAC || '',
    convenio: anteriores[0]?.SIGLACONV || '',
    procedimento: anteriores[0]?.DESCPROCEDI || '',
    realizou: anteriores[0]?.REALIZOU || '',
  });
  const [assinaturaDigital, setAssinaturaDigital] = useState(false);
  const [receitas, setReceitas] = useState();
  const [receita, setReceita] = useState({
    codPaciente: atual?.CODPAC,
    codMedico: atual?.CODREALIZOU,
    nomeMedico: atual?.REALIZOU,
    nomePaciente: atual?.NOMEPAC,
    codConsulta: atual?.CODCONSULTA,
    dataRec: format(new Date(), 'dd/MM/yyyy'),
    tipo: '',
    descricao: '',
    status: 'EMITIDA',
  });
  const [atestados, setAtestados] = useState();
  const [atestadoMedico, setAtestadoMedico] = useState({
    codPaciente: atual?.CODPAC,
    codMedico: atual?.CODREALIZOU,
    nomeMedico: atual?.REALIZOU,
    nomePaciente: atual?.NOMEPAC,
    codConsulta: atual?.CODCONSULTA,
    dataAtestado: format(new Date(), 'dd/MM/yyyy'),
    tipo: '',
    descricao: '',
  });
  const [requisicoes, setRequisicoes] = useState({
    codPaciente: atual?.CODPAC,
    codMedico: medico?.codMed,
    nomeMedico: medico?.nome,
    nomePaciente: atual?.NOMEPAC,
    codConsulta: atual?.CODCONSULTA,
    dataAtestado: format(new Date(), 'dd/MM/yyyy'),
    tipo: '',
    descricao: '',
    cid: '',
    cid2: null,
    indicacao: '',
  });
  const [requisicao, setRequisicao] = useState({
    codPaciente: atual?.CODPAC,
    codMedico: atual?.CODREALIZOU,
    nomeMedico: atual?.REALIZOU,
    nomePaciente: atual?.NOMEPAC,
    codConsulta: atual?.CODCONSULTA,
    dataRec: format(new Date(), 'dd/MM/yyyy'),
    tipo: '',
    descricao: '',
    cid: '',
    indicacao: '',
  });
  const [laboratoriais, setLaboratoriais] = useState(exames);
  const [chartExames, setChartExames] = useState([]);
  const [tableExames, setTableExames] = useState([]);
  const [uniqueExames, setUniqueExames] = useState([]);
  // eslint-disable-next-line
  const [valor, setValor] = useState(0);
  // eslint-disable-next-line
  const [resultado, setResultado] = useState('');
  const btnStyle = {
    background: 'none',
    color: 'blue',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
  };
  const cidValueLabelSorted = useMemo(
    () =>
      dbprontuario.cid
        .map(i => ({ value: i.value, label: `${i.label} - ${i.value}` }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    []
  );
  const queixasSorted = useMemo(
    () =>
      dbprontuario.queixas.sort((a, b) => a.sintoma.localeCompare(b.sintoma)),
    []
  );
  const habitosSorted = useMemo(
    () =>
      dbprontuario.habitos.sort((a, b) => a.sintoma.localeCompare(b.sintoma)),
    []
  );
  const isdaSorted = useMemo(
    () => dbprontuario.isda.sort((a, b) => a.sintoma.localeCompare(b.sintoma)),
    []
  );
  const pessoaisSorted = useMemo(
    () =>
      dbprontuario.pessoais.sort((a, b) => a.sintoma.localeCompare(b.sintoma)),
    []
  );
  const hipotesesSorted = useMemo(
    () =>
      dbprontuario.hipoteses.sort((a, b) => a.sintoma.localeCompare(b.sintoma)),
    []
  );
  const condutasSorted = useMemo(
    () =>
      dbprontuario.condutas.sort((a, b) => a.sintoma.localeCompare(b.sintoma)),
    []
  );

  useEffect(() => {
    if (medico?.tipo === 'Externo' || funcionario?.permissao === 'PARCIAL1')
      history.push('/notAllowed');
  }, [medico, funcionario]);

  useEffect(() => {
    handleEvolucao(atual);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleVidaasAssinarReq = async content => {
    const payload = {
      id: requisicao.codRequisicao.toString(),
      alias: requisicao.codRequisicao + '-requisicao.pdf',
      hash: sha256(JSON.stringify(content)),
      hash_algorithm: '2.16.840.1.101.3.4.2.1',
      signature_format: 'CAdES_AD_RT',
      base64_content: removeBeforeBase64(content),
    };

    dispatch(digitalSignature(payload)).then(resp => {
      dispatch(signingClose());
      if (JSON.stringify(resp) !== '{}' && resp?.status !== 403) {
        const decodedData = atob(resp?.signatures[0].file_base64_signed);
        const byteArray = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const file = URL.createObjectURL(blob);
        document.open(file, '_blank', '');
      } else {
        toast.warn('Usuário ainda não autenticado!');
      }
    });
  };

  const handleVidaasAssinarAtestado = async content => {
    const payload = {
      id: atestadoMedico.codAtestado.toString(),
      alias: atestadoMedico.codAtestado + '-atestado.pdf',
      hash: sha256(JSON.stringify(content)),
      hash_algorithm: '2.16.840.1.101.3.4.2.1',
      signature_format: 'CAdES_AD_RT',
      base64_content: removeBeforeBase64(content),
    };

    dispatch(digitalSignature(payload)).then(resp => {
      dispatch(signingClose());
      if (JSON.stringify(resp) !== '{}' && resp?.status !== 403) {
        const decodedData = atob(resp?.signatures[0].file_base64_signed);
        const byteArray = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const file = URL.createObjectURL(blob);
        document.open(file, '_blank', '');
      } else {
        toast.warn('Usuário ainda não foi AUTENTICADO!');
      }
    });
  };

  const handleVidaasAssinar = async content => {
    const payload = {
      id: receita.codReceita.toString(),
      alias: receita.codReceita + '-receita.pdf',
      hash: sha256(JSON.stringify(content)),
      hash_algorithm: '2.16.840.1.101.3.4.2.1',
      signature_format: 'CAdES_AD_RT',
      base64_content: removeBeforeBase64(content),
    };

    dispatch(digitalSignature(payload)).then(resp => {
      dispatch(signingClose());
      if (JSON.stringify(resp) !== '{}' && resp?.status !== 403) {
        const decodedData = atob(resp?.signatures[0].file_base64_signed);
        const byteArray = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const fileReceita = URL.createObjectURL(blob);
        document.open(fileReceita, '_blank', '');
      } else {
        toast.warn('Usuário ainda não foi AUTENTICADO!');
      }
    });
  };

  const getDropDownMenu = (consulta, variant) => {
    return (
      <DropdownButton
        as={ButtonGroup}
        id="ddbtn1"
        variant={variant}
        drop="right"
        title={consulta.dataFormatada}
        onClick={() => handleEvolucao(consulta)}
        size="sm"
      >
        <Dropdown.Item onClick={event => handlePrincipal(consulta, event)}>
          Consulta Principal
        </Dropdown.Item>
        <Dropdown.Item onClick={event => handleComplementar(consulta, event)}>
          Consulta Complementar
        </Dropdown.Item>
        <Dropdown.Item onClick={event => handleTelefone(event)}>
          Consulta Telefone
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => handleListarReceituarios(consulta)}>
          Receituário Médico
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleListarAtestados(consulta)}>
          Atestado Médico
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleListarRequisicoes(consulta)}>
          Requisição de Exames
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => handleNovosExamesLaboratoriais(consulta)}>
          Novos Exames Laboratoriais
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleListarExamesLaboratoriais(consulta)}
        >
          Listar Exames Laboratoriais
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const handleNovosExamesLaboratoriais = async consulta => {
    setToggleValor(document.getElementsByName('toggleValor'));
    setToggleResultado(document.getElementsByName('toggleResultado'));

    const listExames = tipoexame.map(tipo => ({
      CODPAC: consulta.CODPAC,
      CODCONSULTA: consulta.CODCONSULTA,
      CODEXAME: tipo.CODEXAME,
      VALOR: 0,
      RESULTADO: '',
      DATALABOR: new Date(),
    }));

    if (listExames.length > 0) {
      try {
        await api.post(`/listexamelab`, listExames);
        dispatch(getExameLabByPac(consulta)).then(res => setLaboratoriais(res));
        setShowExamesLab(true);
        toast.success(listExames.length + ' Exames gerados com sucesso!');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    } else {
      toast.info('Nenhum Exame gerado. Consulte a administração!');
    }
  };

  const handleListarExamesLaboratoriais = async consulta => {
    dispatch(loadingOpen());
    await dispatch(getViewExameLabByPac(consulta));
    dispatch(getExameLabByPac(consulta)).then(res => {
      setLaboratoriais(res);
      dispatch(getViewLabResultsByPac(consulta)).then(res => {
        if (res.length > 0) {
          const lastTen = [];
          const labs = res && res.filter(e => e.CODEXAME === res[0].CODEXAME);

          labs.forEach(ex => lastTen.push([ex.dataExame, ex.VALOR]));
          const last10Exames = lastTen.slice(-10);
          last10Exames.unshift(['DATA DO EXAME', res[0].EXAME]);
          setChartExames(last10Exames);
          setToggleValor(document.getElementsByName('toggleValor'));
          setToggleResultado(document.getElementsByName('toggleResultado'));
        }
      });

      dispatch(getTabelaExameLabByPac(consulta)).then(res => {
        if (res.length > 0) {
          const lastTen = [];
          const labs = res && res.filter(e => e.CODEXAME === res[0].CODEXAME);
          const unique =
            res &&
            res.filter(
              (obj, idx) => idx === res.findIndex(o => obj.EXAME === o.EXAME)
            );

          labs.forEach(ex => lastTen.push({ ...ex }));
          const last10Exames = lastTen.slice(-10);
          setUniqueExames(unique);
          setTableExames(last10Exames);
        }
      });
      setShowExamesLab(true);
      toast.success('Lista de Exames localizada com sucesso!');
      dispatch(loadingClose());
    });
  };

  const handleExibirResultadoExames = exame => {
    const lastTen = [];
    const labs = viewlabresults?.filter(res => res.CODEXAME === exame.CODEXAME);
    labs.forEach(ex => lastTen.push([ex.dataExame, ex.VALOR]));
    const last10Exames = lastTen.slice(-10);
    last10Exames.unshift(['DATA DO EXAME', exame.EXAME]);
    setChartExames(last10Exames);
  };

  const handleExibirTabelaExames = exame => {
    const lastTen = [];
    const filterLabresults =
      tabelaexames && tabelaexames.filter(e => e.CODEXAME === exame.CODEXAME);
    const unique =
      tabelaexames &&
      tabelaexames.filter(
        (obj, idx) => idx === tabelaexames.findIndex(o => obj.EXAME === o.EXAME)
      );

    filterLabresults.forEach(ex => lastTen.push({ ...ex }));
    const last10Exames = lastTen
      .slice(-10)
      .sort((a, b) => a.DATALABOR.localeCompare(b.DATALABOR))
      .reverse();
    setUniqueExames(unique);
    setTableExames(last10Exames);
  };

  const handleExcluirZerados = async () => {
    try {
      const updExames = laboratoriais.filter(
        ex => ex.VALOR !== 0 || ex.RESULTADO !== ''
      );
      const delExames = laboratoriais.filter(lab => !updExames.includes(lab));
      setLaboratoriais(updExames);
      await api.put(`/examelab`, updExames);
      if (delExames.length > 0) {
        await api.delete(`/examelab`, { data: delExames });
      }
      toast.info('Exames Gravados com sucesso!');
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const renderTreeMedicamento = nodes => (
    <TreeItem key={nodes.id} itemId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children) &&
        nodes.children.map(node => renderTreeMedicamento(node))}
    </TreeItem>
  );

  const renderTreeExames = nodes => (
    <TreeItem key={nodes.id} itemId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children) &&
        nodes.children.map(node => renderTreeExames(node))}
    </TreeItem>
  );

  const handleSelectMedicamento = (event, nodeId, elmID) => {
    dbprontuario.medicamentos.children.forEach(classe => {
      classe.children.forEach(e => {
        if (e.id === nodeId) {
          setFieldValues({
            ...fieldValues,
            medicamentos: (fieldValues.medicamentos ?? '') + '  ' + e.name, // changing the state of *changed value*
          });
          document.getElementById(elmID).focus();
          event.stopPropagation();
        }
      });
    });
  };

  const handleSelectExames = (event, nodeId) => {
    dbprontuario.complementares.children.forEach(classe => {
      classe.children.forEach(e => {
        if (e.id === nodeId) {
          setRequisicao({
            ...requisicao,
            descricao: (requisicao.descricao ?? '') + '\n        - ' + e.name,
          });
          event.stopPropagation();
        }
      });
    });
  };

  const handleSelectMedicamentoReceita = (event, nodeId, elmID) => {
    dbprontuario.medicamentos.children.forEach(classe => {
      classe.children.forEach(e => {
        if (e.id === nodeId) {
          setReceitaCounter(receitaCounter + 1);
          setReceita({
            ...receita,
            descricao:
              (receita.descricao ?? '') +
              '\n' +
              getPrescricao(e.name, receitaCounter),
          });
          document.getElementById(elmID).focus();
          event.stopPropagation();
        }
      });
    });
  };

  const handleMenu = (event, id) => {
    event.preventDefault();
    show(event, { id });
  };

  const handleMenuClick = ({ event }, elmID) => {
    switch (elmID) {
      case 'editQueixas':
        setFieldValues({
          ...fieldValues,
          queixas: (fieldValues.queixas ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editHabitos':
        setFieldValues({
          ...fieldValues,
          habitos: (fieldValues.habitos ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editISDA':
        setFieldValues({
          ...fieldValues,
          isda: (fieldValues.isda ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editPessoais':
        setFieldValues({
          ...fieldValues,
          pessoais:
            (fieldValues.pessoais ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editFamiliares':
        setFieldValues({
          ...fieldValues,
          familiares:
            (fieldValues.familiares ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editFisico':
        setFieldValues({
          ...fieldValues,
          fisico: (fieldValues.fisico ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editConduta':
        setFieldValues({
          ...fieldValues,
          conduta: (fieldValues.conduta ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editHipotese':
        setFieldValues({
          ...fieldValues,
          hipotese:
            (fieldValues.hipotese ?? '') + '  ' + event.currentTarget.id,
        });
        event.stopPropagation();
        break;
      case 'editMedicamentos':
        setShowMedicamento(true);
        document.getElementById(elmID).focus();
        break;
      case 'editMedicamentosReceita':
        setShowMedicamentoReceita(true);
        document.getElementById(elmID).focus();
        event.stopPropagation();
        break;
      default:
    }
  };

  function handleAtividades(consulta) {
    dispatch(loadingOpen());
    let c = 0;
    if (consulta.CODPAC !== 0) {
      api
        .get(`paciente/atividades?id=${consulta.CODPAC}`)
        .then(result => {
          const data = result.data.map(atividade => ({
            ...atividade,
            dataFormatada: format(
              addDays(parseISO(atividade.DATALAUDO), 1),
              'dd/MM/yyyy',
              { locale: pt }
            ),
            counter: (c += 1),
          }));
          dispatch(prontuarioSuccess({ paciente }));
          setAtividades(data);
          setTotal(c);
        })
        .catch(err => toast.error(err.response.data.error))
        .finally(() => dispatch(loadingClose()));
    }
  }

  async function handleProntuario(atividade) {
    dispatch(loadingOpen());
    setShowLaudoModal(true);
    if (atividade.CODLAUDO !== 0) {
      const p = await api.get(`paciente/prontuario/${atividade.CODLAUDO}`);
      setLaudo(p.data[0]);
    } else {
      toast.warn('Prontuario não identificado!');
    }
    dispatch(loadingClose());
  }

  async function handleEcg(atividade) {
    dispatch(loadingOpen());
    dispatch(selectEcgById(atividade.CODLAUDO));
    setLaudo(ecg);
    setShowEletroModal(true);
    dispatch(loadingClose());
  }

  function handleStress(atv) {
    setShowStressModal(true);
    dispatch(loadingOpen());
    if (atv.CODLAUDO > 81610) dispatch(selectStressById(atv.CODLAUDO));
    else dispatch(selectStressByIdOLD(atv.CODLAUDO));
    dispatch(loadingClose());
  }

  function handleHolter(atv) {
    setShowHolterModal(true);
    dispatch(loadingOpen());
    if (atv.GUIA1 === 'SIM') dispatch(selectHolterByID(atv.CODLAUDO));
    else toast.warn('Laudo não autorizado!');
    dispatch(loadingClose());
  }

  async function handleTrans(atividade) {
    dispatch(loadingOpen());
    setShowTransModal(true);
    dispatch(selectTransById(atividade.CODLAUDO));
    dispatch(loadingClose());
  }

  async function handleErgo(atividade) {
    setShowErgoModal(true);
    dispatch(loadingOpen());
    await dispatch(selectErgoById(atividade.CODLAUDO));
    dispatch(loadingClose());
  }

  async function handleMapa(atividade) {
    setShowMapaModal(true);
    dispatch(loadingOpen());
    if (atividade.GUIA1 === 'SIM') dispatch(selectMapaByID(atividade.CODPAC));
    else toast.warn('Laudo não Autorizado!');
    dispatch(loadingClose());
  }

  async function handleEco(atividade) {
    dispatch(loadingOpen());
    const eco = await dispatch(selectEcoById(atividade.CODLAUDO));
    if (eco.TIPOECO === 'EcoC') setShowEcocongModal(true);
    else setShowEcodopplerModal(true);
    dispatch(loadingClose());
  }

  async function handleFetal(atividade) {
    setShowFetalModal(true);
    dispatch(loadingOpen());
    await dispatch(selectFetalById(atividade.CODLAUDO));
    dispatch(loadingClose());
  }

  const handleEvolucao = consulta => {
    const textareaElem = document.getElementsByClassName('textAreaDestaque');
    let i = 0;
    setShowConsulta(false);
    setFieldValues({
      ...fieldValues,
      codconsulta: consulta?.CODCONSULTA || '',
      queixas: consulta?.QUEIXAS || '',
      familiares: consulta?.ANTECEDENTES_FLIA || '',
      pessoais: consulta?.ANTECEDENTES_PESSOAIS || '',
      conduta: consulta?.CONDUTA || '',
      complementares: consulta?.EXAMES_COMPLEMENTARES || '',
      fisico: consulta?.EXAME_FISICO || '',
      habitos: consulta?.HABITOS || '',
      hipotese: consulta?.HIPOTESE_DIAGNOSTICA || '',
      isda: consulta?.ISDA || '',
      medicamentos: consulta?.MEDICAMENTOS || '',
      obs: consulta?.OBS || '',
      observacao: consulta?.OBSERVACOES || '',
      peso: consulta?.PESOPAC || '',
      cid: consulta?.CID || '',
      cid2: consulta?.CID2 || '',
      cid3: consulta?.CID3 || '',
      gravado: consulta?.GRAVADO || '',
      tipo: consulta?.TIPO || '',
      dataFormatada: consulta?.dataFormatada || '',
      idadePAC: consulta?.idadePAC || '',
      convenio: consulta?.SIGLACONV || '',
      procedimento: consulta?.DESCPROCEDI || '',
      realizou: consulta?.REALIZOU || '',
    });
    if (
      consulta?.TIPO?.trim() === 'PCP' &&
      consulta?.GRAVADO?.trim() === 'SIM'
    ) {
      for (i = 0; i < textareaElem.length; i++) {
        textareaElem[i].style.backgroundColor = '';
      }
      setShowConsulta(true);
      setAlert({
        variant: 'primary',
        mensagem: 'Consulta Principal já Gravada!',
      });
      setInputDisabled(true);
    } else if (
      consulta?.TIPO?.trim() === 'SEC' &&
      consulta?.GRAVADO?.trim() === 'SIM'
    ) {
      for (i = 0; i < textareaElem.length; i++) {
        textareaElem[i].style.backgroundColor = '#bdf294';
      }
      setShowConsulta(true);
      setAlert({
        variant: 'primary',
        mensagem: 'Consulta Complementar já Gravada!',
      });
      setInputDisabled(true);
    } else if (
      consulta?.TIPO?.trim() === 'SEC' &&
      consulta?.GRAVADO?.trim() === 'NAO'
    ) {
      for (i = 0; i < textareaElem.length; i++) {
        textareaElem[i].style.backgroundColor = '#bdf294';
      }
      setShowConsulta(true);
      setAlert({
        variant: 'primary',
        mensagem: 'Consulta Complementar não foi Gravada ainda!',
      });
      setInputDisabled(false);
    } else if (
      consulta?.TIPO?.trim() === 'PCP' &&
      consulta?.GRAVADO?.trim() === 'PAR'
    ) {
      for (i = 0; i < textareaElem.length; i++) {
        textareaElem[i].style.backgroundColor = '';
      }
      setShowConsulta(true);
      setAlert({
        variant: 'primary',
        mensagem: 'Consulta Principal Gravada Parcialmente!',
      });
      setInputDisabled(false);
    } else if (
      consulta?.TIPO?.trim() === 'SEC' &&
      consulta?.GRAVADO?.trim() === 'PAR'
    ) {
      for (i = 0; i < textareaElem.length; i++) {
        textareaElem[i].style.backgroundColor = '#bdf294';
      }
      setShowConsulta(true);
      setAlert({
        variant: 'primary',
        mensagem: 'Consulta Complementar Gravada Parcialmente!',
      });
      setInputDisabled(false);
    } else if (!consulta?.TIPO && !consulta?.GRAVADO) {
      for (i = 0; i < textareaElem.length; i++) {
        textareaElem[i].style.backgroundColor = '';
      }
      setShowConsulta(true);
      setAlert({
        variant: 'warning',
        mensagem: 'Consulta ou Retorno - tipo não definido!',
      });
      setInputDisabled(false);
    }
  };

  const handlePrincipal = (consulta, event) => {
    event.stopPropagation();
    const result = prontuario?.anteriores?.filter(
      principal => principal?.TIPO?.trim() === 'PCP'
    );
    if (result) {
      if (result.length > 0) {
        setInputDisabled(true);
        setShowConsulta(true);
        setAlert({
          variant: 'danger',
          mensagem:
            'Consulta Principal já existe! Data: ' + result[0].dataFormatada,
        });
      } else {
        setShowConsulta(true);
        setAlert({
          variant: 'primary',
          mensagem:
            'Digite a Consulta Principal do Paciente: ' + consulta.NOMEPAC,
        });
        let textareaElements = document.getElementsByClassName(
          'textAreaDestaque'
        );
        for (let i = 0; i < textareaElements.length; i++) {
          textareaElements[i].style.backgroundColor = '';
        }
        setFieldValues({
          codconsulta: consulta?.CODCONSULTA || '',
          queixas: consulta?.QUEIXAS || '',
          familiares: consulta?.ANTECEDENTES_FLIA || '',
          pessoais: consulta?.ANTECEDENTES_PESSOAIS || '',
          conduta: consulta?.CONDUTA || '',
          complementares: consulta?.EXAMES_COMPLEMENTARES || '',
          fisico: consulta?.EXAME_FISICO || '',
          habitos: consulta?.HABITOS || '',
          hipotese: consulta?.HIPOTESE_DIAGNOSTICA || '',
          isda: consulta?.ISDA || '',
          medicamentos: consulta?.MEDICAMENTOS || '',
          obs: consulta?.OBS || '',
          observacao: consulta?.OBSERVACOES || '',
          peso: consulta?.PESOPAC || '',
          cid: consulta?.CID || '',
          cid2: consulta?.CID2 || '',
          cid3: consulta?.CID3 || '',
          gravado: consulta?.GRAVADO || '',
          tipo: 'PCP',
          dataFormatada: consulta?.dataFormatada || '',
          idadePAC: consulta?.idadePAC || '',
          convenio: consulta?.SIGLACONV || '',
          procedimento: consulta?.DESCPROCEDI || '',
          realizou: consulta?.REALIZOU || '',
        });
      }
    }
  };

  const handleComplementar = (consulta, event) => {
    event.stopPropagation();
    if (consulta.TIPO?.trim() === 'PCP' && consulta.GRAVADO?.trim() === 'SIM') {
      setInputDisabled(true);
      setShowConsulta(true);
      setAlert({
        variant: 'primary',
        mensagem: 'Consulta Principal já Gravada!',
      });
      setFieldValues({
        codconsulta: consulta?.CODCONSULTA || '',
        queixas: consulta?.QUEIXAS || '',
        familiares: consulta?.ANTECEDENTES_FLIA || '',
        pessoais: consulta?.ANTECEDENTES_PESSOAIS || '',
        conduta: consulta?.CONDUTA || '',
        complementares: consulta?.EXAMES_COMPLEMENTARES || '',
        fisico: consulta?.EXAME_FISICO || '',
        habitos: consulta?.HABITOS || '',
        hipotese: consulta?.HIPOTESE_DIAGNOSTICA || '',
        isda: consulta?.ISDA || '',
        medicamentos: consulta?.MEDICAMENTOS || '',
        obs: consulta?.OBS || '',
        observacao: consulta?.OBSERVACOES || '',
        peso: consulta?.PESOPAC || '',
        cid: consulta?.CID || '',
        cid2: consulta?.CID2 || '',
        cid3: consulta?.CID3 || '',
        gravado: consulta?.GRAVADO || '',
        tipo: 'PCP',
        dataFormatada: consulta?.dataFormatada || '',
        idadePAC: consulta?.idadePAC || '',
        convenio: consulta?.SIGLACONV || '',
        procedimento: consulta?.DESCPROCEDI || '',
        realizou: consulta?.REALIZOU || '',
      });
    } else if (
      consulta.TIPO?.trim() === 'SEC' &&
      consulta.GRAVADO?.trim() === 'SIM'
    ) {
      setInputDisabled(true);
      setShowConsulta(true);
      setAlert({
        variant: 'danger',
        mensagem: 'Consulta Complementar já Gravada!',
      });
      setFieldValues({
        codconsulta: consulta?.CODCONSULTA || '',
        queixas: consulta?.QUEIXAS || '',
        familiares: consulta?.ANTECEDENTES_FLIA || '',
        pessoais: consulta?.ANTECEDENTES_PESSOAIS || '',
        conduta: consulta?.CONDUTA || '',
        complementares: consulta?.EXAMES_COMPLEMENTARES || '',
        fisico: consulta?.EXAME_FISICO || '',
        habitos: consulta?.HABITOS || '',
        hipotese: consulta?.HIPOTESE_DIAGNOSTICA || '',
        isda: consulta?.ISDA || '',
        medicamentos: consulta?.MEDICAMENTOS || '',
        obs: consulta?.OBS || '',
        observacao: consulta?.OBSERVACOES || '',
        peso: consulta?.PESOPAC || '',
        cid: consulta?.CID || '',
        cid2: consulta?.CID2 || '',
        cid3: consulta?.CID3 || '',
        gravado: consulta?.GRAVADO || '',
        tipo: 'PCP',
        dataFormatada: consulta?.dataFormatada || '',
        idadePAC: consulta?.idadePAC || '',
        convenio: consulta?.SIGLACONV || '',
        procedimento: consulta?.DESCPROCEDI || '',
        realizou: consulta?.REALIZOU || '',
      });
    } else {
      let textareaElements = document.getElementsByClassName(
        'textAreaDestaque'
      );
      for (let i = 0; i < textareaElements.length; i++) {
        textareaElements[i].style.backgroundColor = '#bdf294';
      }
      setInputDisabled(false);
      setShowConsulta(true);
      setAlert({
        variant: 'success',
        mensagem:
          'Registrar Evolução da Consulta Anterior Datada de ' +
          anterior?.dataFormatada,
      });
      setFieldValues({
        ...fieldValues,
        codconsulta: consulta?.CODCONSULTA || '',
        queixas: anterior?.QUEIXAS || '',
        familiares: anterior?.ANTECEDENTES_FLIA || '',
        pessoais: anterior?.ANTECEDENTES_PESSOAIS || '',
        conduta: anterior?.CONDUTA || '',
        complementares: anterior?.EXAMES_COMPLEMENTARES || '',
        fisico: anterior?.EXAME_FISICO || '',
        habitos: anterior?.HABITOS || '',
        hipotese: anterior?.HIPOTESE_DIAGNOSTICA || '',
        isda: anterior?.ISDA || '',
        medicamentos: anterior?.MEDICAMENTOS || '',
        obs: anterior?.OBS || '',
        observacao: anterior?.OBSERVACOES || '',
        peso: anterior?.PESOPAC || '',
        cid: anterior?.CID || '',
        cid2: anterior?.CID2 || '',
        cid3: anterior?.CID3 || '',
        gravado: '',
        tipo: 'SEC',
        dataFormatada: consulta?.dataFormatada || '',
        idadePAC: consulta?.idadePAC || '',
        convenio: consulta?.SIGLACONV || '',
        procedimento: consulta?.DESCPROCEDI || '',
        realizou: consulta?.REALIZOU || '',
      });
    }
    setShowConsulta(true);
  };

  const handleTelefone = event => {
    event.stopPropagation();
    setFieldValues({
      ...fieldValues,
      codMedico: medico ? medico?.codMed : anterior?.CODREALIZOU,
      codFunc: medico ? medico?.idFunc : funcionario?.codFunc,
      codProcedi: 800,
      codConv: anterior?.CODCONV,
      codPac: anterior?.CODPAC,
      codEncaminhou: anterior?.CODENCAMINHOU,
      codRealizou: medico ? medico?.codMed : anterior?.CODREALIZOU,
      codFaturamento: anterior?.CODFATURAMENTO || '',
      queixas: anterior?.QUEIXAS || '',
      familiares: anterior?.ANTECEDENTES_FLIA || '',
      pessoais: anterior?.ANTECEDENTES_PESSOAIS || '',
      conduta: anterior?.CONDUTA || '',
      complementares: anterior?.EXAMES_COMPLEMENTARES || '',
      fisico: anterior?.EXAME_FISICO || '',
      habitos: anterior?.HABITOS || '',
      hipotese: anterior?.HIPOTESE_DIAGNOSTICA || '',
      isda: anterior?.ISDA || '',
      medicamentos: anterior?.MEDICAMENTOS || '',
      obs: anterior?.OBS || '',
      observacao: anterior?.OBSERVACOES || '',
      peso: anterior?.PESOPAC || '',
      cid: anterior?.CID || '',
      cid2: anterior?.CID2 || '',
      cid3: anterior?.CID3 || '',
      gravado: '',
      tipo: 'SEC',
      dataFormatada: atual?.dataFormatada || '',
      idadePAC: atual?.idadePAC || '',
      convenio: atual?.SIGLACONV || '',
      procedimento: atual?.DESCPROCEDI || '',
      realizou: atual?.REALIZOU || '',
    });
    setInputDisabled(false);
    setShowConsultaTelefone(true);
  };

  const handleGerarConsultaTelefone = async event => {
    event.preventDefault();
    dispatch(loadingOpen());
    setShowConsulta(true);
    setAlert({
      variant: 'success',
      mensagem:
        'Registrar Evolução (Telefone) da Consulta Anterior Datada de ' +
        anterior?.dataFormatada,
    });

    dispatch(insertProntuarioById({ ...fieldValues }))
      .then(result => {
        setFieldValues({
          ...fieldValues,
          codconsulta: result.atual?.CODCONSULTA,
          codMedico: medico ? medico?.codMed : result.atual?.CODREALIZOU,
          codFunc: medico ? medico?.idFunc : funcionario?.codFunc,
          codProcedi: result.atual?.CODPROCEDI,
          codConv: result.atual?.CODCONV,
          codPac: result.atual?.CODPAC,
          codEncaminhou: result.atual?.CODENCAMINHOU,
          codRealizou: medico?.codMed,
          codFaturamento: result.atual?.CODFATURAMENTO || '',
          queixas: result.atual?.QUEIXAS || '',
          familiares: result.atual?.ANTECEDENTES_FLIA || '',
          pessoais: result.atual?.ANTECEDENTES_PESSOAIS || '',
          conduta: result.atual?.CONDUTA || '',
          complementares: result.atual?.EXAMES_COMPLEMENTARES || '',
          fisico: result.atual?.EXAME_FISICO || '',
          habitos: result.atual?.HABITOS || '',
          hipotese: result.atual?.HIPOTESE_DIAGNOSTICA || '',
          isda: result.atual?.ISDA || '',
          medicamentos: result.atual?.MEDICAMENTOS || '',
          obs: result.atual?.OBS || '',
          observacao: result.atual?.OBSERVACOES || '',
          peso: result.atual?.PESOPAC || '',
          cid: result.atual?.CID || '',
          cid2: result.atual?.CID2 || '',
          cid3: result.atual?.CID3 || '',
          gravado: result.atual?.GRAVADO || '',
          tipo: result.atual?.TIPO || '',
          dataFormatada: result.atual?.dataFormatada || '',
          idadePAC: result.atual?.idadePAC || '',
          convenio: result.atual?.SIGLACONV || '',
          procedimento: result.atual?.DESCPROCEDI || '',
          realizou: result.atual?.REALIZOU || '',
        });
        setShowConsulta(true);
        setShowConsultaTelefone(false);
        if (prontuario) {
          setAlert({ variant: 'success', mensagem: result?.message });
        } else {
          setAlert({
            variant: 'danger',
            mensagem: 'Falha ao Inserir Consulta Telefone!',
          });
        }
      })
      .then(() => document.getElementById('editQueixas').focus());

    dispatch(loadingClose());
  };

  const handleGravarProntuario = event => {
    event.preventDefault();
    dispatch(loadingOpen());
    dispatch(updateProntuarioById(fieldValues));
    dispatch(
      updateAgendaAtendido({ CODPAC: atual.CODPAC, IDFUNC: medico?.idFunc })
    );
    toast.success('Prontuário gravado!');
    document.getElementById('editQueixas').focus();
    dispatch(loadingClose());
  };

  const handleGravarProntuarioParcial = event => {
    event.preventDefault();
    dispatch(loadingOpen());
    dispatch(updateProntuarioById(fieldValues));
    dispatch(loadingClose());
  };

  function handleReceitas(paciente) {
    setSearchReceitas(false);
    let c = 0;
    if (paciente.codPac !== 0) {
      dispatch(loadingOpen());
      api
        .get(`paciente/${paciente.codPac}/receitas`)
        .then(result => {
          if (result.data.length > 0) {
            const data = result.data.map(receita => ({
              ...receita,
              dataFormatada: format(
                parseISO(receita.DATARECEITA),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              counter: (c += 1),
            }));
            setReceitas(data);
            handleVisualizarReceita(data[0]);
            setTotal(c);
            setSearchReceitas(true);
          } else {
            setReceitas(result.data);
            setSearchReceitas(true);
            setShowAlertReceitas(true);
            setAlert({
              variant: 'danger',
              mensagem: 'Não existe Receita para esse Paciente!',
            });
          }
          dispatch(loadingClose());
        })
        .catch(err => toast.error(err.response.data.error));
    } else {
      setShowAlertReceitas(true);
      setAlert({ variant: 'danger', mensagem: 'Paciente não localizado!' });
    }
  }

  function handleAtestados(paciente) {
    setSearchAtestados(false);
    let c = 0;
    if (paciente.codPac !== 0) {
      dispatch(loadingOpen());
      // /paciente/:cod/atestados
      api
        .get(`paciente/${paciente.codPac}/atestados`)
        .then(result => {
          if (result.data.length > 0) {
            const data = result.data.map(atestado => ({
              ...atestado,
              dataFormatada: format(
                parseISO(atestado.DATAATESTADO),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              counter: (c += 1),
            }));
            handleVisualizarAtestado(data[0]);
            setAtestados(data);
            setTotal(c);
            setSearchAtestados(true);
          } else {
            setAtestados(result.data);
            setSearchAtestados(true);
            setShowAlertAtestados(true);
            setAlert({
              variant: 'danger',
              mensagem: 'Não existe Atestado para esse Paciente!',
            });
          }
          dispatch(loadingClose());
        })
        .catch(err => toast.error(err.response.data.error));
    } else {
      setShowAlertAtestados(true);
      setAlert({ variant: 'danger', mensagem: 'Paciente não localizado!' });
    }
  }

  function handleRequisicoes(paciente) {
    setSearchRequisicoes(false);
    let c = 0;
    if (paciente.codPac !== 0) {
      dispatch(loadingOpen());
      // /paciente/:cod/requisicoes
      api
        .get(`paciente/${paciente.codPac}/requisicoes`)
        .then(result => {
          if (result?.data.length > 0) {
            const data = result.data.map(requisicao => ({
              ...requisicao,
              dataFormatada: format(
                parseISO(requisicao?.DATAREQUISICAO),
                'dd/MM/yyyy',
                { locale: pt }
              ),
              dataAtual: format(subDays(new Date(), 1), 'dd/MM/yyyy', {
                locale: pt,
              }),
              counter: (c += 1),
            }));
            handleVisualizarRequisicao(data[0]);
            setRequisicoes(data);
            setTotal(c);
            setSearchRequisicoes(true);
          } else {
            setRequisicoes(result?.data);
            setSearchRequisicoes(true);
            setShowAlertRequisicoes(true);
            setAlert({
              variant: 'danger',
              mensagem: 'Não existe Requisição para esse Paciente!',
            });
          }
          dispatch(loadingClose());
        })
        .catch(err => toast.error(err.response.data.error));
    } else {
      setShowAlertRequisicoes(true);
      setAlert({ variant: 'danger', mensagem: 'Paciente não localizado!' });
    }
  }

  const handlePrescreverNovaReceita = () => {
    const dataExtenso = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
      locale: pt,
    });
    const enderecoPac = `${patient?.LOGRA_PAC} ${patient?.ENDRESPAC}, ${patient?.ENDERECO_NR} - ${patient?.BAIRROPAC} - ${patient?.CIDADEPAC}/${patient?.ESTADOPAC}`;
    const cabecalho = `
Marília/SP, ${dataExtenso}.\n\n
Nome: ${patient?.NOMEPAC} - CPF: ${patient?.CPF ?? patient?.ENDMARPAC}
Endereço: ${enderecoPac}\n
${
  usoReceita === 'oral-continuo' ? 'USO ORAL CONTÍNUO' : 'USO EXTERNO CONTÍNUO'
}\n`;

    const payload = {
      codConsulta: receita.codConsulta,
      codMedico: medico.codMed,
      codPaciente: receita.codPaciente,
      tipo: 'Normal',
      descricao: cabecalho,
      status: 'ONLINE',
    };

    dispatch(insertReceita(payload)).then(response => {
      setShowAlertGravada(true);
      setAlert({
        variant: 'success',
        mensagem: 'Gerada Nova Receita. Selecione os Medicamentos da Listagem!',
      });
      handleVisualizarReceita(response[0]);
      setReceitaCounter(1);
      setPaciente({ ...paciente, codPac: patient.CODPAC });
      handleReceitas(paciente);
    });
  };

  const handleRepetirNovaReceita = () => {
    const dataExtenso = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
      locale: pt,
    });
    const linhas = receita.descricao.split('\n');

    for (let i = 0; i < linhas.length; i++) {
      if (linhas[i].includes('Marília')) {
        linhas[i] = `Marília, ${dataExtenso}.`;
        break;
      }
    }

    const payload = {
      codConsulta: receita.codConsulta,
      codMedico: medico ? medico?.codMed : receita.codMedico,
      codPaciente: receita.codPaciente,
      tipo: 'Normal',
      descricao: linhas.join('\n'),
      status: 'Clonada',
    };

    dispatch(insertReceita(payload)).then(response => {
      setShowAlertGravada(true);
      setAlert({
        variant: 'success',
        mensagem: 'Gerada Nova Receita. Selecione os Medicamentos da Listagem!',
      });
      handleVisualizarReceita(response[0]);
      setPaciente({ ...paciente, codPac: patient.CODPAC });
      handleReceitas(paciente);
    });
  };

  const handleRepetirAtestado = () => {
    const dataExtenso = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
      locale: pt,
    });
    const linhas = atestadoMedico.descricao.split('\n');

    for (let i = 0; i < linhas.length; i++) {
      if (linhas[i].includes('Marília')) {
        linhas[i] = `Marília, ${dataExtenso}.`;
        break;
      }
    }

    const payload = {
      codConsulta: atestadoMedico.codConsulta,
      codMedico: medico ? medico?.codMed : atestadoMedico.codMedico,
      codPaciente: atestadoMedico.codPaciente,
      tipo: atestadoMedico.tipo,
      descricao: linhas.join('\n'),
    };

    setShowRadioAtestado(true);

    dispatch(insertAtestado(payload)).then(response => {
      handleVisualizarAtestado(response[0]);
      setPaciente({ ...paciente, codPac: patient.CODPAC });
      handleAtestados(paciente);
      setShowAlertAtestadoGravado(true);
      setAlert({ variant: 'success', mensagem: 'Gerado Novo Atestado.' });
    });
  };

  const handleRepetirRequisicao = () => {
    const dataExtenso = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
      locale: pt,
    });
    const linhas = requisicao.descricao.split('\n');

    for (let i = 0; i < linhas.length; i++) {
      if (linhas[i].includes('Marília')) {
        linhas[i] = `Marília, ${dataExtenso}.`;
        break;
      }
    }

    const payload = {
      codConsulta: requisicao.codConsulta,
      codMedico: medico ? medico?.codMed : requisicao.codMedico,
      codPaciente: requisicao.codPaciente,
      tipo: requisicao.tipo,
      descricao: linhas.join('\n'),
      cid: requisicao.cid,
      indicacao: requisicao.indicacao,
    };

    setShowRadioRequisicao(true);
    dispatch(insertRequisicao(payload)).then(response => {
      handleVisualizarRequisicao(response[0]);
      setPaciente({ ...paciente, codPac: patient.CODPAC });
      handleRequisicoes(paciente);
      setShowAlertRequisicaoGravada(true);
      setAlert({
        variant: 'success',
        mensagem: 'Cópia Gerada de uma Nova Requisição.',
      });
    });
  };

  const handlePrescreverNovoAtestado = () => {
    const payload = {
      codConsulta: atestadoMedico.codConsulta,
      codMedico: medico.codMed,
      codPaciente: atestadoMedico.codPaciente,
      tipo: '',
      descricao: '',
    };
    setShowRadioAtestado(true);

    dispatch(insertAtestado(payload)).then(response => {
      handleVisualizarAtestado(response[0]);
      setPaciente({ ...paciente, codPac: patient.CODPAC });
      handleAtestados(paciente);
      setShowAlertAtestadoGravado(true);
      setAlert({
        variant: 'success',
        mensagem: 'Gerado Novo Atestado. Selecione um Tipo de Atestado!',
      });
    });
  };

  const handlePrescreverNovaRequisicao = () => {
    const payload = {
      codConsulta: requisicao.codConsulta,
      codMedico: medico.codMed,
      codPaciente: requisicao.codPaciente,
      tipo: '',
      descricao: '',
      cid: pedido?.cid ?? '',
      indicacao: pedido?.indicacao ?? '',
    };
    setShowRadioRequisicao(true);

    dispatch(insertRequisicao(payload)).then(response => {
      handleVisualizarRequisicao(response[0]);
      setPaciente({ ...paciente, codPac: patient?.CODPAC });
      handleRequisicoes(paciente);
      setShowAlertRequisicaoGravada(true);
      setAlert({
        variant: 'success',
        mensagem: 'Gerada Nova Requisição. Preencha CID e Indicação!',
      });
    });
  };

  function handleVisualizarReceita(rec) {
    const receita = {
      codReceita: rec.CODRECEITA,
      codPaciente: rec.CODPAC,
      codMedico: rec.CODMEDICO,
      nomeMedico: rec.NOMEMED,
      crm: rec.CRMMED,
      cpf: rec.CPFMED,
      rqe: rec.RQE,
      nomePaciente: rec.NOMEPAC,
      codConsulta: rec.CODCONSULTA,
      dataRec: rec.dataFormatada,
      tipo: rec.TIPORECEITA,
      descricao: rec.DESCRICAO,
      status: rec.STATUS,
    };
    setReceita(Object.assign(receita));
  }

  function handleVisualizarAtestado(atestado) {
    const objAtestado = {
      codAtestado: atestado.CODATESTADO,
      codPaciente: atestado.CODPAC,
      codMedico: atestado.CODMEDICO,
      nomeMedico: atestado.NOMEMED,
      crm: atestado.CRMMED,
      cpf: atestado.CPFMED,
      rqe: atestado.RQE,
      nomePaciente: atestado.NOMEPAC,
      codConsulta: atestado.CODCONSULTA,
      dataAtestado: atestado.dataFormatada,
      tipo: atestado.TIPOATESTADO,
      descricao: atestado.DESCRICAO,
    };
    setAtestadoMedico(Object.assign(objAtestado));
  }

  function handleVisualizarRequisicao(requisicao) {
    const req = {
      codRequisicao: requisicao?.CODREQUISICAO,
      codPaciente: requisicao?.CODPAC,
      codMedico: requisicao?.CODMEDICO,
      nomeMedico: requisicao?.NOMEMED,
      crm: requisicao?.CRMMED,
      cpf: requisicao?.CPFMED,
      rqe: requisicao?.RQE,
      nomePaciente: requisicao?.NOMEPAC,
      codConsulta: requisicao?.CODCONSULTA,
      dataRequisicao: requisicao?.dataFormatada,
      tipo: requisicao?.TIPOREQUISICAO,
      descricao: requisicao?.DESCRICAO,
      cid: requisicao?.CID,
      indicacao: requisicao?.INDICACAO,
    };
    setShowRadioRequisicao(true);
    setShowAlertRequisicaoGravada(false);
    setRequisicao(Object.assign(req));
  }

  const handleGravarReceita = async () => {
    if (receita?.codReceita) {
      await updateReceita(receita);
      setShowMedicamentoReceita(false);
      setShowAlertGravada(true);
      setReceitaCounter(1);
      setAlert({ variant: 'success', mensagem: 'Receita gravada!' });
    } else {
      toast.warn('Selecione uma receita!');
    }
  };

  const handleGravarAtestado = async () => {
    if (atestadoMedico.codAtestado) {
      await updateAtestado(atestadoMedico);
      setShowAlertAtestadoGravado(true);
      setAlert({ variant: 'success', mensagem: 'Atestado gravado!' });
    } else {
      toast.warn('Selecione um atestado!');
    }
  };

  const handleGravarRequisicao = async () => {
    if (requisicao.codRequisicao) {
      await updateRequisicao(requisicao);
      setShowAlertRequisicaoGravada(true);
      setAlert({ variant: 'success', mensagem: 'Requisição gravada!' });
    } else {
      toast.warn('Selecione uma requisição de exame!');
    }
  };

  const handleListarReceituarios = consulta => {
    const date = format(new Date(), "dd 'de' MMMM 'de' yyyy", { locale: pt });
    setReceita({
      codPaciente: consulta?.CODPAC,
      nomePaciente: consulta?.NOMEPAC,
      codMedico: consulta?.CODREALIZOU,
      nomeMedico: consulta?.REALIZOU,
      codConsulta: consulta?.CODCONSULTA,
      dataRec: format(new Date(), 'dd/MM/yyyy'),
      tipo: 'Normal',
      status: 'EMITIDA',
      descricao: `Marília/SP, ${date}.\n \n \nNome: ${
        paciente?.nome
      } - CPF: ${paciente?.cpf ?? ''}\nEndereço: ${paciente?.enderecoPac ??
        ''}\n \nUSO ORAL CONTÍNUO \n \n`,
    });
    setPaciente({ ...paciente, codPac: consulta?.CODPAC });
    handleReceitas(paciente);
    setShowReceita(true);
  };

  const handleListarAtestados = async consulta => {
    setAtestadoMedico({
      codPaciente: consulta?.CODPAC,
      codMedico: consulta?.CODREALIZOU,
      nomeMedico: consulta?.REALIZOU,
      nomePaciente: consulta?.NOMEPAC,
      codConsulta: consulta?.CODCONSULTA,
      dataAtestado: format(new Date(), 'dd/MM/yyyy'),
      tipo: '',
      descricao: '',
    });
    dispatch(prontuarioSuccess({ atestadoMedico }));
    setPaciente({ ...paciente, codPac: consulta?.CODPAC });
    handleAtestados(paciente);
    setShowAtestado(true);
  };

  const handleListarRequisicoes = async consulta => {
    setShowAlertRequisicaoGravada(false);
    const requisicaoCopy = { ...requisicao };
    const pedidoExame = Object.assign(requisicaoCopy, {
      codPaciente: consulta?.CODPAC,
      codMedico: consulta?.CODREALIZOU,
      nomeMedico: consulta?.REALIZOU,
      nomePaciente: consulta?.NOMEPAC,
      codConsulta: consulta?.CODCONSULTA,
      dataRequisicao: format(new Date(), 'dd/MM/yyyy'),
      tipo: '',
      descricao: '',
      indicacao: consulta?.CID3,
      cid: consulta?.CID,
    });
    dispatch(prontuarioSuccess({ pedidoExame }));
    setPaciente({ ...paciente, codPac: consulta?.CODPAC });
    handleRequisicoes(paciente);
    setShowRequisicao(true);
  };

  const handleImprimirReceita = async (receita, timbrado) => {
    const styles = {
      title: { fontSize: 12, textAlign: 'center', marginTop: 20 },
      content: { fontSize: 11, marginLeft: timbrado ? 20 : 60, marginTop: 20 },
      footer: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
      },
    };

    const doc = new jsPDF();

    const qrCodeCanvas = document.createElement('canvas');
    await QRCode.toCanvas(qrCodeCanvas, QRCODE_RECEITA);
    const qrCodeDataUrl = qrCodeCanvas.toDataURL('image/jpeg');
    const qrCodeImageWidth = 50;
    const qrCodeImageHeight = 50;
    const qrCodeImageX =
      (doc.internal.pageSize.getWidth() - qrCodeImageWidth) / 2;
    const qrCodeImageY =
      doc.internal.pageSize.getHeight() -
      styles.footer.marginTop -
      qrCodeImageHeight +
      20;

    if (assinaturaDigital) {
      dispatch(signingOpen());

      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;
      const linhas = doc.splitTextToSize(receita.descricao, 180);

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25);

      doc.setFontSize(styles.title.fontSize);
      doc.setFontSize(styles.content.fontSize);
      linhas.forEach((linha, indice) => {
        doc.text(
          `${linha}`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          styles.title.marginTop + 40 + indice * 5,
          { align: 'justify', charSpace: 0 }
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado digitalmente`,
        x,
        doc.internal.pageSize.getHeight() - 60,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(receita))}`,
        x,
        doc.internal.pageSize.getHeight() - 55,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(receita)} ${receita.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${receita.cpf} - CRM: ${receita.crm} ${
          receita.rqe ? `- RQE: ${receita.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `* A assinatura digital deste documento poderá ser verificada em https://validar.iti.gov.br/ *`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );
      doc.text(
        `* FARMÁCIA: Realizar a dispensação da RECEITA: ${receita.codReceita} - PACIENTE: ${receita.codPaciente} usando o QRCode *`,
        x,
        doc.internal.pageSize.getHeight() - 35,
        { align: 'center' }
      );

      doc.addImage(
        icp,
        'PNG',
        qrCodeImageX - 40,
        qrCodeImageY - 18,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 35
      );

      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 70,
        qrCodeImageY - 18,
        qrCodeImageWidth - 35,
        qrCodeImageHeight - 35
      );

      const fileReceita = doc.output('dataurlstring');
      await handleVidaasAssinar(fileReceita);
    } else {
      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;
      const linhas = doc.splitTextToSize(receita.descricao, 180); // Quebra o conteúdo em linhas de até 180 caracteres

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25); // x, y, w, h

      doc.setFontSize(styles.title.fontSize);
      doc.setFontSize(styles.content.fontSize);
      linhas.forEach((linha, indice) => {
        doc.text(
          `${linha}`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          styles.title.marginTop + 40 + indice * 5,
          { align: 'justify', charSpace: 0 }
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado eletronicamente`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(receita))}`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(receita)} ${receita.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${receita.cpf} - CRM: ${receita.crm} ${
          receita.rqe ? `- RQE: ${receita.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 35,
        { align: 'center' }
      );
      doc.text(
        `* VALIDAÇÃO: Aponte a câmera do celular usando o QRCode ao lado *`,
        x,
        doc.internal.pageSize.getHeight() - 30,
        { align: 'center' }
      );
      doc.text(
        `* Realizar a dispensação da RECEITA: ${receita.codReceita} - PACIENTE: ${receita.codPaciente} *`,
        x,
        doc.internal.pageSize.getHeight() - 25,
        { align: 'center' }
      );
      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 85,
        qrCodeImageY + 7,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 30
      );
      doc.output('dataurlnewwindow');
    }
  };

  function alteraFont(e) {
    let fonte = fonteSize;
    if (e === 'a') fonte++;
    if (e === 'd') fonte--;
    setFonteSize(fonte);
  }

  const handleImprimirAtestado = async (atestado, timbrado) => {
    const doc = new jsPDF(('p', 'mm', 'a4'));

    const styles = {
      content: {
        fontSize: fonteSize,
        marginLeft: timbrado ? 20 : 50,
        marginTop: 22,
        marginRight: 15,
      },
      footer: {
        fontSize: fonteSize - 2,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
      },
    };

    const qrCodeCanvas = document.createElement('canvas');
    await QRCode.toCanvas(qrCodeCanvas, QRCODE_ATESTADO);
    const qrCodeDataUrl = qrCodeCanvas.toDataURL('image/jpeg');
    const qrCodeImageWidth = 50;
    const qrCodeImageHeight = 50;
    const qrCodeImageX =
      (doc.internal.pageSize.getWidth() - qrCodeImageWidth) / 2;
    const qrCodeImageY =
      doc.internal.pageSize.getHeight() -
      styles.footer.marginTop -
      qrCodeImageHeight +
      20;

    if (assinaturaDigital) {
      dispatch(signingOpen());

      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;
      let linhas = '';

      if (fonteSize < 12) {
        linhas = doc.splitTextToSize(atestado.descricao, 180 + fonteSize);
      } else {
        linhas = doc.splitTextToSize(atestado.descricao, 180 - fonteSize);
      }

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25); // x, y, w, h

      doc.setFontSize(styles.content.fontSize);
      linhas.forEach((linha, indice) => {
        doc.text(
          `${linha}`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          50 + indice * (fonteSize - 7)
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado digitalmente`,
        x,
        doc.internal.pageSize.getHeight() - 60,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(atestado))}`,
        x,
        doc.internal.pageSize.getHeight() - 55,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(atestado)} ${atestado.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${atestado.cpf} - CRM: ${atestado.crm} ${
          atestado.rqe ? `- RQE: ${atestado.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `* A assinatura digital deste documento poderá ser verificada em https://validar.iti.gov.br/ *`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );

      doc.addImage(
        icp,
        'PNG',
        qrCodeImageX - 40,
        qrCodeImageY - 18,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 35
      );

      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 70,
        qrCodeImageY - 18,
        qrCodeImageWidth - 35,
        qrCodeImageHeight - 35
      );

      const file = doc.output('dataurlstring');

      await handleVidaasAssinarAtestado(file);
    } else {
      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25); // x, y, w, h

      let linhas = '';
      if (fonteSize < 12) {
        linhas = doc.splitTextToSize(atestado.descricao, 180 + fonteSize);
      } else {
        linhas = doc.splitTextToSize(atestado.descricao, 180 - fonteSize);
      }

      doc.setFontSize(styles.content.fontSize);

      linhas.forEach((linha, indice) => {
        doc.text(
          `${linha}`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          50 + indice * (fonteSize - 7)
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado eletronicamente`,
        x,
        doc.internal.pageSize.getHeight() - 60,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(atestado))}`,
        x,
        doc.internal.pageSize.getHeight() - 55,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(atestado)} ${atestado.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${atestado.cpf} - CRM: ${atestado.crm} ${
          atestado.rqe ? `- RQE: ${atestado.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `* VALIDAÇÃO: Aponte a câmera do celular usando o QRCode ao lado *`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );
      doc.text(
        `* ATESTADO: ${atestado.codAtestado} - PACIENTE: ${atestado.codPaciente} *`,
        x,
        doc.internal.pageSize.getHeight() - 35,
        { align: 'center' }
      );
      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 85,
        qrCodeImageY - 2,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 30
      );

      doc.output('dataurlnewwindow');
    }
  };

  const handleImprimirRequisicao = async (requisicao, timbrado) => {
    const doc = new jsPDF();

    const styles = {
      content: { fontSize: 8.5, marginLeft: timbrado ? 20 : 50, marginTop: 20 },
      footer: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,
      },
    };

    const qrCodeCanvas = document.createElement('canvas');
    await QRCode.toCanvas(qrCodeCanvas, QRCODE_REQUISICAO);
    const qrCodeDataUrl = qrCodeCanvas.toDataURL('image/jpeg');
    const qrCodeImageWidth = 50;
    const qrCodeImageHeight = 50;
    const qrCodeImageX =
      (doc.internal.pageSize.getWidth() - qrCodeImageWidth) / 2;
    const qrCodeImageY =
      doc.internal.pageSize.getHeight() -
      styles.footer.marginTop -
      qrCodeImageHeight +
      20;

    if (assinaturaDigital) {
      dispatch(signingOpen());

      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;
      const linhas = doc.splitTextToSize(requisicao.descricao, 250);

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25);

      doc.setFontSize(styles.content.fontSize);

      linhas.forEach((linha, indice) => {
        doc.text(
          `${
            linha.includes('INDICAÇÃO') ||
            linha.includes('CONVÊNIO') ||
            linha.includes('NOME') ||
            linha.includes('CID10') ||
            linha.includes('EXAMES')
              ? linha.trim()
              : linha
          }`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          65 + indice * 4
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado digitalmente`,
        x,
        doc.internal.pageSize.getHeight() - 60,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(requisicao))}`,
        x,
        doc.internal.pageSize.getHeight() - 55,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(requisicao)} ${requisicao.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${requisicao.cpf} - CRM: ${requisicao.crm} ${
          requisicao.rqe ? `- RQE: ${requisicao.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `* A assinatura digital deste documento poderá ser verificada em https://validar.iti.gov.br/ *`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );

      doc.addImage(
        icp,
        'PNG',
        qrCodeImageX - 40,
        qrCodeImageY - 18,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 35
      );

      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 70,
        qrCodeImageY - 18,
        qrCodeImageWidth - 35,
        qrCodeImageHeight - 35
      );

      const file = doc.output('dataurlstring');

      await handleVidaasAssinarReq(file);
    } else {
      const imageX = (doc.internal.pageSize.getWidth() - 150) / 2;

      if (timbrado) doc.addImage(header, 'JPEG', imageX, 5, 150, 25);

      const linhas = doc.splitTextToSize(requisicao.descricao, 250);

      doc.setFontSize(styles.content.fontSize);

      linhas.forEach((linha, indice) => {
        doc.text(
          `${
            linha.includes('INDICAÇÃO') ||
            linha.includes('CONVÊNIO') ||
            linha.includes('NOME') ||
            linha.includes('CID10') ||
            linha.includes('EXAMES')
              ? linha.trim()
              : linha
          }`,
          timbrado ? styles.content.marginLeft + 35 : styles.content.marginLeft,
          65 + indice * 4
        );
      });

      doc.setFontSize(styles.footer.fontSize);
      doc.setFont('helvetica', 'bold');

      const x = doc.internal.pageSize.getWidth() / 2;

      doc.text(
        `Documento assinado eletronicamente`,
        x,
        doc.internal.pageSize.getHeight() - 60,
        { align: 'center' }
      );
      doc.text(
        `Autenticação: ${md5(JSON.stringify(requisicao))}`,
        x,
        doc.internal.pageSize.getHeight() - 55,
        { align: 'center' }
      );
      doc.text(
        `Médico: ${prefixo(requisicao)} ${requisicao.nomeMedico}`,
        x,
        doc.internal.pageSize.getHeight() - 50,
        { align: 'center' }
      );
      doc.text(
        `CPF: ${requisicao.cpf} - CRM: ${requisicao.crm} ${
          requisicao.rqe ? `- RQE: ${requisicao.rqe}` : ''
        }`,
        x,
        doc.internal.pageSize.getHeight() - 45,
        { align: 'center' }
      );
      doc.text(
        `* VALIDAÇÃO: Aponte a câmera do celular usando o QRCode ao lado *`,
        x,
        doc.internal.pageSize.getHeight() - 40,
        { align: 'center' }
      );
      doc.text(
        `* REQUISIÇÃO: ${requisicao.codRequisicao} - PACIENTE: ${requisicao.codPaciente} *`,
        x,
        doc.internal.pageSize.getHeight() - 35,
        { align: 'center' }
      );

      doc.addImage(
        qrCodeDataUrl,
        'JPEG',
        qrCodeImageX + 85,
        qrCodeImageY - 2,
        qrCodeImageWidth - 30,
        qrCodeImageHeight - 30
      );

      doc.output('dataurlnewwindow');
    }
  };

  const handleTipoAtestado = tipo => {
    let descricao = '';
    switch (tipo) {
      case 'Admissional':
        descricao = dbprontuario.atestadosicm[0].Admissional.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Admissional',
            descricao: descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Admissional',
          descricao,
        });
        break;
      case 'AtestadoDia':
        descricao = dbprontuario.atestadosicm[1].AtestadoDia.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Atestado Dia',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Atestado Dia',
          descricao,
        });
        break;
      case 'AtestadoHora':
        descricao = dbprontuario.atestadosicm[2].AtestadoHora.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Atestado Hora',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Atestado Hora',
          descricao,
        });
        break;
      case 'AtestadoFutebol':
        descricao = dbprontuario.atestadosicm[3].AtestadoFutebol.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Atestado Futebol',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Atestado Futebol',
          descricao,
        });
        break;
      case 'AtividadeFisica':
        descricao = dbprontuario.atestadosicm[4].AtividadeFisica.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Atividade Fisica',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Atividade Fisica',
          descricao,
        });
        break;
      case 'CarteiraHAS':
        descricao = dbprontuario.atestadosicm[5].CarteiraHAS.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Carteira HAS',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Carteira HAS',
          descricao,
        });
        break;
      case 'Cate':
        descricao = dbprontuario.atestadosicm[6].Cate.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Cateterismo',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Cateterismo',
          descricao,
        });
        break;
      case 'Cirurgia':
        descricao = dbprontuario.atestadosicm[7].Cirurgia.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Cirurgia',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Cirurgia',
          descricao,
        });
        break;
      case 'ConcursoPublico1':
        descricao = dbprontuario.atestadosicm[8].ConcursoPublico1.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Concurso Publico1',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Concurso Publico1',
          descricao,
        });
        break;
      case 'ConcursoPublico2':
        descricao = dbprontuario.atestadosicm[9].ConcursoPublico2.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Concurso Publico2',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Concurso Publico2',
          descricao,
        });
        break;
      case 'CriancaEsporte':
        descricao = dbprontuario.atestadosicm[10].CriancaEsporte.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Crianca Esporte',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Crianca Esporte',
          descricao,
        });
        break;
      case 'Declaracao':
        descricao = dbprontuario.atestadosicm[11].Declaracao.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Declaracao',
            descricao,
          })
        );
        setAtestadoMedico({ ...atestadoMedico, tipo: 'Declaracao', descricao });
        break;
      case 'AltoCusto':
        descricao = dbprontuario.atestadosicm[12].AltoCusto.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Declaracao COVID',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Declaracao COVID',
          descricao,
        });
        break;
      case 'Fisioterapia':
        descricao = dbprontuario.atestadosicm[13].Fisioterapia.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Fisioterapia',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Fisioterapia',
          descricao,
        });
        break;
      case 'Hidroginastica':
        descricao = dbprontuario.atestadosicm[14].Hidroginastica.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Hidroginastica',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Hidroginastica',
          descricao,
        });
        break;
      case 'Internacao':
        descricao = dbprontuario.atestadosicm[15].Internacao.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Internacao',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Internacao',
          descricao,
        });
        break;
      case 'Marcapasso':
        descricao = dbprontuario.atestadosicm[16].Marcapasso.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Marcapasso',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Marcapasso',
          descricao,
        });
        break;
      case 'Nutricionista':
        descricao = dbprontuario.atestadosicm[17].Nutricionista.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Nutricionista',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Nutricionista',
          descricao,
        });
        break;
      case 'Psicologa':
        descricao = dbprontuario.atestadosicm[18].Psicologa.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({ ...atestadoMedico, tipo: 'Psicóloga', descricao })
        );
        setAtestadoMedico({ ...atestadoMedico, tipo: 'Psicóloga', descricao });
        break;
      case 'Vacina':
        descricao = dbprontuario.atestadosicm[19].Vacina.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({ ...atestadoMedico, tipo: 'Vacina', descricao })
        );
        setAtestadoMedico({ ...atestadoMedico, tipo: 'Vacina', descricao });
        break;
      case 'PeriopAltoRisco':
        descricao = dbprontuario.atestadosicm[20].PeriopAltoRisco.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Pre Operatório Alto Risco',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Pre Operatório Alto Risco',
          descricao,
        });
        break;
      case 'PeriopBaixoRisco':
        descricao = dbprontuario.atestadosicm[21].PeriopBaixoRisco.replace(
          '@PAC',
          atual.NOMEPAC
        ).replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        );
        dispatch(
          prontuarioSuccess({
            ...atestadoMedico,
            tipo: 'Pre Operatório Baixo Risco',
            descricao,
          })
        );
        setAtestadoMedico({
          ...atestadoMedico,
          tipo: 'Pre Operatório Baixo Risco',
          descricao,
        });
        break;
      default:
    }
  };

  const handleTipoRequisicao = tipo => {
    let descricao = '';

    if (requisicao.cid === '' || requisicao.indicacao === '') {
      setShowAlertRequisicaoGravada(true);
      setAlert({
        variant: 'danger',
        mensagem: 'Campos CID e Indicação estão em branco!',
      });
    }

    switch (tipo) {
      case 'LaboratoriaisSimples':
        descricao = dbprontuario.requisicoes[0].LaboratoriaisSimples.replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        )
          .replace('@PAC', atual.NOMEPAC)
          .replace('@CONV', atual.SIGLACONV)
          .replace('@CID10', requisicao.cid)
          .replace('@INDICA', requisicao.indicacao);
        dispatch(
          prontuarioSuccess({
            ...requisicao,
            tipo: 'Laboratoriais Simples',
            descricao,
          })
        );
        setRequisicao({
          ...requisicao,
          tipo: 'Laboratoriais Simples',
          descricao,
        });
        break;
      case 'LaboratoriaisEspeciais':
        descricao = dbprontuario.requisicoes[1].LaboratoriaisEspeciais.replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        )
          .replace('@PAC', atual.NOMEPAC)
          .replace('@CONV', atual.SIGLACONV)
          .replace('@CID10', requisicao.cid)
          .replace('@INDICA', requisicao.indicacao);
        dispatch(
          prontuarioSuccess({
            ...requisicao,
            tipo: 'Laboratoriais Especiais',
            descricao,
          })
        );
        setRequisicao({
          ...requisicao,
          tipo: 'Laboratoriais Especiais',
          descricao,
        });
        break;
      case 'LaboratoriaisCompleto':
        descricao = dbprontuario.requisicoes[2].LaboratoriaisCompleto.replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        )
          .replace('@PAC', atual.NOMEPAC)
          .replace('@CONV', atual.SIGLACONV)
          .replace('@CID10', requisicao.cid)
          .replace('@INDICA', requisicao.indicacao);
        dispatch(
          prontuarioSuccess({
            ...requisicao,
            tipo: 'Laboratoriais Completo',
            descricao: descricao,
          })
        );
        setRequisicao({
          ...requisicao,
          tipo: 'Laboratoriais Completo',
          descricao: descricao,
        });
        break;
      case 'OutrosExames':
        descricao = dbprontuario.requisicoes[3].OutrosExames.replace(
          '@MARILIA',
          `Marília, ${dataExtenso(new Date().toDateString())}`
        )
          .replace('@PAC', atual.NOMEPAC)
          .replace('@CONV', atual.SIGLACONV)
          .replace('@CID10', requisicao.cid)
          .replace('@INDICA', requisicao.indicacao);
        dispatch(
          prontuarioSuccess({ ...requisicao, tipo: 'Outros Exames', descricao })
        );
        setRequisicao({ ...requisicao, tipo: 'Outros Exames', descricao });
        break;
      default:
    }
  };

  const handleChangeDescricaoAtestado = e => {
    setAtestadoMedico({ ...atestadoMedico, descricao: e.target.value });
    dispatch(
      prontuarioSuccess({ ...atestadoMedico, descricao: e.target.value })
    );
  };

  const handleChangeDescricaoRequisicao = e => {
    setRequisicao({ ...requisicao, descricao: e.target.value });
    dispatch(prontuarioSuccess({ ...requisicao, descricao: e.target.value }));
  };

  const handleChangeDescricaoIndicacao = e => {
    const updated = requisicao.descricao.split('\n').map(linha => {
      if (linha.includes('INDICAÇÃO'))
        return 'INDICAÇÃO: ' + requisicao.indicacao;

      if (linha.includes('CID')) return 'CID: ' + requisicao.cid;

      return linha;
    });

    setRequisicao({
      ...requisicao,
      indicacao: e.target.value,
      descricao: updated.join('\n'),
    });

    dispatch(prontuarioSuccess({ ...requisicao, indicacao: e.target.value }));
  };

  const handleChange = e => {
    e.preventDefault();
    setFieldValues({ ...fieldValues, [e.target.name]: e.target.value });
  };

  function handleChangeCID(option) {
    const updated = requisicao.descricao.split('\n').map(linha => {
      if (linha.includes('INDICAÇÃO')) {
        return 'INDICAÇÃO: ' + option.value;
      } else if (linha.includes('CID')) {
        return 'CID: ' + option.label;
      } else {
        return linha;
      }
    });

    setSelectedOption(option.label.split(' - ')[0]);
    setRequisicao({
      ...requisicao,
      cid: option.label.split(' - ')[0],
      indicacao: option.value,
      descricao: updated.join('\n'),
    });
  }

  return (
    <>
      <Tabs
        defaultActiveKey="prontuario"
        id="tabs-prontuario"
        activeKey={activeKey}
        onSelect={key => setActiveKey(key)}
        className="mb-3"
        fill
      >
        <Tab eventKey="prontuario" title="Prontuário">
          <Row>
            <Col sm={2}>
              <Card.Header className="text-center font-weight-bold">
                CONSULTAS
              </Card.Header>
              <Table responsive="sm" striped bordered size="sm">
                <thead>
                  <tr className="text-center">
                    <th>
                      {'...::: '}Data{' :::...'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {prontuario?.anteriores?.map(consulta => (
                    <tr key={consulta.counter} className="text-center">
                      <td>
                        {consulta?.TIPO?.trim() === 'SEC'
                          ? getDropDownMenu(consulta, 'outline-danger')
                          : consulta?.TIPO?.trim() === 'PCP'
                          ? getDropDownMenu(consulta, 'outline-primary')
                          : getDropDownMenu(consulta, 'outline-success')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col sm={10}>
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <Button size="sm" onClick={() => history.goBack()}>
                    <FaArrowLeft />
                  </Button>
                  <h5 className="m-0 text-center">PRONTUÁRIO ELETRÔNICO</h5>
                </Card.Header>
                <Card.Body>
                  <Form noValidate>
                    <strong className="mr-auto">Nome do Paciente: </strong>
                    {`${atual?.CODPAC} - ${atual?.NOMEPAC}`}
                    <br></br>
                    <strong className="mr-auto">Data de Nasc: </strong>
                    {`${atual?.dataNasc}`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong className="mr-auto">Idade: </strong>
                    {`${fieldValues?.idadePAC}  anos`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br></br>
                    <strong className="mr-auto">Convênio: </strong>
                    {fieldValues?.convenio}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong className="mr-auto">Procedimento: </strong>
                    {fieldValues?.procedimento}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br></br>
                    <strong className="mr-auto">Código do Prontuário: </strong>
                    {fieldValues?.codconsulta}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong className="mr-auto">Data do Prontuário: </strong>
                    <strong style={{ color: 'blue', fontWeight: 'bold' }}>
                      {fieldValues?.dataFormatada}{' '}
                    </strong>
                    <br></br>
                    <strong className="mr-auto">Médico que Realizou: </strong>
                    {`${fieldValues?.realizou}`}
                    <hr></hr>
                    <Alert
                      show={showConsultaTelefone}
                      variant="warning"
                      style={{ textAlign: 'right' }}
                    >
                      <Alert.Heading>
                        Confirma a Geração de Consulta Telefone?
                      </Alert.Heading>
                      <p>Consulta TELEFONE.</p>
                      <hr />
                      <div className="text-right">
                        <Button
                          onClick={e => handleGerarConsultaTelefone(e)}
                          variant="warning"
                        >
                          Confirma Gravação TELEFONE?
                        </Button>
                      </div>
                    </Alert>
                    {showConsulta && (
                      <Alert
                        variant={alert.variant}
                        style={{ textAlign: 'right' }}
                      >
                        {alert.mensagem}
                      </Alert>
                    )}
                    <Form.Row>
                      <Col sm>
                        <Form.Group size="sm" controlId="editQueixas">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Queixa Principal e História </strong>
                            <OverlayTrigger
                              overlay={<Tooltip>Queixas</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idQueixa')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.queixas?.split('\n').length + 3 ||
                                  5
                                }
                                name="queixas"
                                className="textAreaDestaque"
                                value={fieldValues.queixas || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu className={styles.styleMenu} id={'idQueixa'}>
                              {queixasSorted.map(queixa => (
                                <Item
                                  selected
                                  key={Math.random()}
                                  id={queixa.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editQueixas')
                                  }
                                >
                                  {queixa.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editMedicamentos">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Medicações em Uso</strong>{' '}
                            <OverlayTrigger
                              overlay={<Tooltip>Lista de Medicações</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  variant="info"
                                  onClick={e =>
                                    handleMenuClick(e, 'editMedicamentos')
                                  }
                                >
                                  Medicamentos
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            disabled={inputDisabled}
                            as="textarea"
                            rows={
                              fieldValues.medicamentos?.split('\n').length +
                                1 || 3
                            }
                            name="medicamentos"
                            value={fieldValues.medicamentos || ''}
                            onBlur={e => handleGravarProntuarioParcial(e)}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        {showMedicamento && (
                          <Box px={2}>
                            <Box sx={{ mb: 1 }}>
                              <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={() => {
                                  setShowMedicamento(false);
                                  setShowMedicamentoReceita(false);
                                }}
                              >
                                Fechar
                              </Button>{' '}
                            </Box>
                            <SimpleTreeView
                              aria-label="rich object"
                              defaultExpanded={['root']}
                              slots={{
                                expandIcon: FaArrowRight,
                                collapseIcon: FaAngleDown,
                              }}
                              onSelectedItemsChange={(e, nodeId) =>
                                handleSelectMedicamento(
                                  e,
                                  nodeId,
                                  'editMedicamentos'
                                )
                              }
                            >
                              {renderTreeMedicamento(dbprontuario.medicamentos)}
                            </SimpleTreeView>
                          </Box>
                        )}
                        <Form.Group size="sm" controlId="editHabitos">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Hábitos</strong>
                            <OverlayTrigger
                              overlay={<Tooltip>Hábitos</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idHabitos')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.habitos?.split('\n').length + 1 ||
                                  3
                                }
                                name="habitos"
                                value={fieldValues.habitos || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu className={styles.styleMenu} id={'idHabitos'}>
                              {habitosSorted.map(habito => (
                                <Item
                                  selected
                                  key={Math.random()}
                                  id={habito.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editHabitos')
                                  }
                                >
                                  {habito.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editISDA">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>ISDA</strong>
                            <OverlayTrigger overlay={<Tooltip>ISDA</Tooltip>}>
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idISDA')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.isda?.split('\n').length + 1 || 3
                                }
                                name="isda"
                                value={fieldValues.isda || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu className={styles.styleMenu} id="idISDA">
                              {isdaSorted.map(isda => (
                                <Item
                                  key={Math.random()}
                                  id={isda.sintoma}
                                  onClick={e => handleMenuClick(e, 'editISDA')}
                                >
                                  {isda.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editPessoais">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Antecedentes Pessoais</strong>
                            <OverlayTrigger
                              overlay={<Tooltip>Antecedentes Pessoais</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idPessoais')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.pessoais?.split('\n').length +
                                    1 || 3
                                }
                                name="pessoais"
                                value={fieldValues.pessoais || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu className={styles.styleMenu} id="idPessoais">
                              {pessoaisSorted.map(pessoal => (
                                <Item
                                  key={Math.random()}
                                  id={pessoal.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editPessoais')
                                  }
                                >
                                  {pessoal.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editFamiliares">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Antecedentes Familiares</strong>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>Antecedentes Familiares</Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idFamiliares')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.familiares?.split('\n').length +
                                    1 || 3
                                }
                                name="familiares"
                                value={fieldValues.familiares || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu
                              className={styles.styleMenu}
                              id={'idFamiliares'}
                            >
                              {dbprontuario.familiares.map(familiar => (
                                <Item
                                  key={Math.random()}
                                  id={familiar.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editFamiliares')
                                  }
                                >
                                  {familiar.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editComplementares">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Exames Complementares</strong>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>Lista de Exames Realizados</Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  variant="info"
                                  onClick={() => {
                                    setActiveKey('atv');
                                    handleAtividades(prontuario.atual);
                                  }}
                                >
                                  Atividades
                                </Button>
                              </span>
                            </OverlayTrigger>{' '}
                          </Form.Label>
                          <Form.Control
                            disabled={inputDisabled}
                            as="textarea"
                            rows={
                              fieldValues.complementares?.split('\n').length +
                                1 || 3
                            }
                            name="complementares"
                            value={fieldValues.complementares || ''}
                            onBlur={e => handleGravarProntuarioParcial(e)}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group size="sm" controlId="editFisico">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Exame Físico</strong>
                            <OverlayTrigger
                              overlay={<Tooltip>Exame Físico</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idFisico')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.fisico?.split('\n').length + 1 ||
                                  3
                                }
                                name="fisico"
                                className="textAreaDestaque"
                                value={fieldValues.fisico || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu className={styles.styleMenu} id={'idFisico'}>
                              {dbprontuario.fisico.map(fisico => (
                                <Item
                                  key={Math.random()}
                                  id={fisico.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editFisico')
                                  }
                                >
                                  {fisico.sintoma.substring(0, 50)}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editHipotese">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Hipótese Diagnóstica</strong>
                            <OverlayTrigger
                              overlay={<Tooltip>Hipótese Diagnóstica</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idHipotese')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.hipotese?.split('\n').length +
                                    1 || 3
                                }
                                name="hipotese"
                                value={fieldValues.hipotese || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu
                              className={styles.styleMenu}
                              id={'idHipotese'}
                            >
                              {hipotesesSorted.map(hipotese => (
                                <Item
                                  key={Math.random()}
                                  id={hipotese.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editHipotese')
                                  }
                                >
                                  {hipotese.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editConduta">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Conduta</strong>
                            <OverlayTrigger
                              overlay={<Tooltip>Conduta</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <Button
                                  size="sm"
                                  onClick={e => handleMenu(e, 'idConduta')}
                                >
                                  <FaBars style={{ width: 18, height: 18 }} />
                                </Button>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <p>
                              <Form.Control
                                disabled={inputDisabled}
                                as="textarea"
                                rows={
                                  fieldValues.conduta?.split('\n').length + 1 ||
                                  3
                                }
                                name="conduta"
                                className="textAreaDestaque"
                                value={fieldValues.conduta || ''}
                                onBlur={e => handleGravarProntuarioParcial(e)}
                                onChange={handleChange}
                              />
                            </p>
                            <Menu className={styles.styleMenu} id={'idConduta'}>
                              {condutasSorted.map(conduta => (
                                <Item
                                  key={Math.random()}
                                  id={conduta.sintoma}
                                  onClick={e =>
                                    handleMenuClick(e, 'editConduta')
                                  }
                                >
                                  {conduta.sintoma}
                                </Item>
                              ))}
                            </Menu>
                          </div>
                        </Form.Group>
                        <Form.Group size="sm" controlId="editobs">
                          <Form.Label className="d-flex justify-content-between">
                            <strong>Observações</strong>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled={inputDisabled}
                            rows={fieldValues.obs?.split('\n').length + 1 || 2}
                            name="obs"
                            value={fieldValues.obs}
                            onBlur={e => handleGravarProntuarioParcial(e)}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={3} controlId="cid1">
                        <strong>CID1</strong>
                        <Creatable
                          name="cid"
                          menuPlacement="top"
                          placeholder={fieldValues.cid || 'Selecione o CID'}
                          isDisabled={inputDisabled}
                          options={cidValueLabelSorted}
                          value={dbprontuario.cid.filter(
                            opt => opt.label === selectedOption
                          )}
                          onCreateOption={option =>
                            setFieldValues({ ...fieldValues, cid: option })
                          }
                          onBlur={e => handleGravarProntuarioParcial(e)}
                          onChange={option => {
                            setSelectedOption(option.label.split(' - ')[0]);
                            setFieldValues({
                              ...fieldValues,
                              cid: option.label.split(' - ')[0],
                              cid3: option.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="cid2">
                        <strong>CID2</strong>
                        <Form.Control
                          disabled={inputDisabled}
                          name="cid2"
                          value={fieldValues.cid2 || ''}
                          onBlur={e => handleGravarProntuarioParcial(e)}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="cid3">
                        <strong>INDICAÇÃO</strong>
                        <Form.Control
                          disabled={inputDisabled}
                          name="cid3"
                          value={fieldValues.cid3 || ''}
                          onBlur={e => handleGravarProntuarioParcial(e)}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="peso">
                        <strong>PESO</strong>
                        <Form.Control
                          disabled={inputDisabled}
                          type="number"
                          name="peso"
                          value={fieldValues.peso || ''}
                          onBlur={e => handleGravarProntuarioParcial(e)}
                          onChange={handleChange}
                        />{' '}
                      </Form.Group>
                    </Form.Row>
                    <Alert show={showGravaParcial} variant="warning">
                      <Alert.Heading>
                        Confirma a Gravação PARCIAL da Consulta?
                      </Alert.Heading>
                      <p>Consulta PARCIAL.</p>
                      <hr />
                      <div className="d-flex justify-content-">
                        <Button
                          onClick={e => handleGravarProntuario(e)}
                          variant="warning"
                        >
                          Confirma Gravação PARCIAL?
                        </Button>
                      </div>
                    </Alert>
                    <Alert show={showGravaTotal} variant="success">
                      <Alert.Heading>
                        Confirma a Gravação DEFINITIVA da Consulta?
                      </Alert.Heading>
                      <p>Consulta DEFINITIVA.</p>
                      <hr />
                      <div className="d-flex justify-content-">
                        <Button
                          onClick={e => handleGravarProntuario(e)}
                          variant="success"
                        >
                          Confirma Gravação DEFINITIVA?
                        </Button>
                      </div>
                    </Alert>
                    <Card className="mt-3 text-center">
                      <Card.Footer className="text-muted">
                        <Button
                          variant="warning"
                          disabled={fieldValues.gravado.trim() === 'SIM'}
                          onClick={() => {
                            setFieldValues({ ...fieldValues, gravado: 'PAR' });
                            setShowConsulta(false);
                            setShowGravaParcial(true);
                            setShowGravaTotal(false);
                            setInputDisabled(false);
                          }}
                        >
                          Gravar Parcial
                        </Button>{' '}
                        <Button
                          variant="success"
                          disabled={fieldValues.gravado.trim() === 'SIM'}
                          onClick={() => {
                            setFieldValues({ ...fieldValues, gravado: 'SIM' });
                            setShowConsulta(false);
                            setShowGravaParcial(false);
                            setShowGravaTotal(true);
                            setInputDisabled(true);
                          }}
                        >
                          Gravar Definitivo
                        </Button>{' '}
                        <Button
                          variant="secondary"
                          onClick={() => history.push('/reportProntuario')}
                        >
                          Imprimir
                        </Button>{' '}
                        <Button onClick={() => history.push('/agenda')}>
                          Agenda
                        </Button>
                        <Button
                          variant="link"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                          }
                        >
                          Retornar ao topo
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey="atv"
          title="Atividades"
          onEnter={() => handleAtividades(prontuario.atual)}
        >
          <Card>
            <Card.Header className="text-center">
              <Card.Title>
                ATIVIDADES DE {prontuario?.atual?.NOMEPAC}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                responsive="sm"
                striped
                bordered
                hover
                size="sm"
                variant="success"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Proced</th>
                    <th>Realizou</th>
                    <th>Convênio</th>
                    <th>Data</th>
                    <th>Laudo</th>
                    <th>Guia</th>
                    <th>Laudado</th>
                  </tr>
                </thead>
                <tbody>
                  {atividades.map(a => (
                    <tr key={a.counter}>
                      <td>{a.counter}</td>
                      {a.CODPROCEDI === 1 ||
                      a.CODPROCEDI === 58 ||
                      a.CODPROCEDI === 800 ? (
                        <td>
                          <button
                            onClick={() => handleProntuario(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 13 ? (
                        <td>
                          <button
                            onClick={() => handleStress(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 14 || a.CODPROCEDI === 15 ? (
                        <td>
                          <button
                            onClick={() =>
                              history.push({
                                pathname: '/reportCarotida',
                                state: { codLaudo: a.CODLAUDO },
                              })
                            }
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 17 ? (
                        <td>
                          <button
                            onClick={() => handleFetal(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 3 ? (
                        <td>
                          <button
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => handleEcg(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 4 ? (
                        <td>
                          <button
                            onClick={() => handleErgo(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 12 ? (
                        <td>
                          <button
                            onClick={() => handleTrans(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 11 ||
                        a.CODPROCEDI === 712 ||
                        a.CODPROCEDI === 713 ||
                        a.CODPROCEDI === 714 ? (
                        <td>
                          <button onClick={() => handleEco(a)} style={btnStyle}>
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 7 ? (
                        <td>
                          <button
                            style={btnStyle}
                            onClick={() => handleMapa(a)}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : a.CODPROCEDI === 6 ? (
                        <td>
                          <button
                            onClick={() => handleHolter(a)}
                            style={btnStyle}
                          >
                            {a.DESCPROCEDI}
                          </button>
                        </td>
                      ) : (
                        <td>{a.DESCPROCEDI}</td>
                      )}
                      <td>{a.CODREALIZOU}</td>
                      <td>{a.SIGLACONV}</td>
                      <td>{a.dataFormatada}</td>
                      <td>{a.CODLAUDO}</td>
                      <td>{a.GUIA}</td>
                      <td>{a.GUIA1}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td style={{ textAlign: 'right' }} colSpan="3">
                      TOTAL DE ATIVIDADES
                    </td>
                    <td style={{ textAlign: 'left' }} colSpan="2">
                      {total}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey="pac" title="Paciente">
          <Card>
            <Card.Header className="text-center">
              <Card.Title>Paciente {prontuario?.atual?.NOMEPAC}</Card.Title>
            </Card.Header>
            <PacienteInfo patient={patient} />
          </Card>
        </Tab>
      </Tabs>

      {showReceita && (
        <Modal
          show={showReceita}
          size="xl"
          centered
          onHide={() => {
            setAssinaturaDigital(false);
            setShowReceita(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>RECEITUÁRIO MÉDICO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={2}>
                <>
                  {searchReceitas ? (
                    <>
                      {receitas.length > 0 ? (
                        <ButtonGroup vertical>
                          {receitas.map((rec, index) => (
                            <Button
                              size="md"
                              variant="outline-primary"
                              key={index}
                              onClick={() => handleVisualizarReceita(rec)}
                            >
                              {rec.dataFormatada}
                            </Button>
                          ))}
                        </ButtonGroup>
                      ) : (
                        <>
                          {showAlertReceitas && (
                            <Alert variant={alert.variant}>
                              {alert.mensagem}
                            </Alert>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {showAlertReceitas && (
                        <Alert variant={alert.variant}>{alert.mensagem}</Alert>
                      )}
                    </>
                  )}
                </>
              </Col>
              <Col sm={10}>
                <Card className="p-1">
                  <Form noValidate>
                    <Form.Row>
                      <Form.Group as={Col} sm controlId="editNomePAC">
                        <Form.Label>
                          <strong>Nome do Paciente</strong>
                        </Form.Label>
                        <Form.Control readOnly value={receita.nomePaciente} />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm controlId="editNOMEMED">
                        <Form.Label>
                          <strong>Nome do Médico</strong>
                        </Form.Label>
                        <Form.Control readOnly value={receita.nomeMedico} />
                      </Form.Group>
                    </Form.Row>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                    >
                      <Button size="sm" onClick={handlePrescreverNovaReceita}>
                        Nova Receita
                      </Button>{' '}
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={handleRepetirNovaReceita}
                      >
                        Repetir Receita
                      </Button>{' '}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() =>
                          setShowMedicamentoReceita(!showMedicamentoReceita)
                        }
                      >
                        Medicamentos
                      </Button>
                      <div>
                        <Form.Check
                          inline
                          className="ml-5"
                          label="Normal"
                          checked={receita.tipo === 'Normal'}
                          name="groupRec"
                          type="radio"
                          id="Normal"
                          onClick={e =>
                            setReceita({ ...receita, tipo: e.target.id })
                          }
                        />
                        <Form.Check
                          inline
                          className="ml-5"
                          label="Controlada"
                          checked={receita.tipo === 'Controlada' ? true : false}
                          name="groupRec"
                          type="radio"
                          id="Controlada"
                          onClick={e =>
                            setReceita({ ...receita, tipo: e.target.id })
                          }
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          className="ml-5"
                          label="Uso oral contínuo"
                          checked={usoReceita === 'oral-continuo'}
                          name="uso-receita"
                          type="radio"
                          onClick={() => setUsoReceita('oral-continuo')}
                        />
                        <Form.Check
                          inline
                          className="ml-5"
                          label="Uso externo contínuo"
                          checked={usoReceita === 'oral-externo'}
                          name="uso-receita"
                          type="radio"
                          onClick={() => setUsoReceita('oral-externo')}
                        />
                      </div>
                    </div>
                    <hr></hr>
                    <Form.Row>
                      <Col sm>
                        <Form.Group
                          size="sm"
                          controlId="editMedicamentosReceita"
                        >
                          <Form.Label>
                            <strong>
                              Descrição da Receita - {receita?.codReceita} -{' '}
                              {receita?.dataRec} - {receita?.uuid}
                            </strong>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ fontSize: '12px' }}
                            rows={20}
                            value={receita.descricao ?? ''}
                            onChange={e =>
                              setReceita({
                                ...receita,
                                descricao: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Col>
                      {showMedicamentoReceita && (
                        <Box>
                          <SimpleTreeView
                            aria-label="rich object"
                            defaultCollapseIcon={<FaAngleDown size={10} />}
                            defaultExpanded={['root']}
                            slots={{
                              expandIcon: FaArrowRight,
                              collapseIcon: FaAngleDown,
                            }}
                            onSelectedItemsChange={(event, nodeId) =>
                              handleSelectMedicamentoReceita(
                                event,
                                nodeId,
                                'editMedicamentosReceita'
                              )
                            }
                          >
                            {renderTreeMedicamento(dbprontuario.medicamentos)}
                          </SimpleTreeView>
                        </Box>
                      )}
                    </Form.Row>
                  </Form>
                  {showAlertGravada && (
                    <Alert variant={alert.variant}>{alert.mensagem}</Alert>
                  )}
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    defaultChecked={assinaturaDigital}
                    label="Assinatura Digital"
                    onChange={() => setAssinaturaDigital(!assinaturaDigital)}
                  />
                  <Card.Footer className="text-center">
                    <Button onClick={handleGravarReceita}>Gravar</Button>{' '}
                    <Button
                      variant="warning"
                      onClick={() => handleImprimirReceita(receita, false)}
                    >
                      Imprimir
                    </Button>{' '}
                    <Button
                      variant="info"
                      onClick={() => handleImprimirReceita(receita, true)}
                    >
                      Timbrado
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setAssinaturaDigital(false);
                        setShowReceita(false);
                      }}
                    >
                      Fechar
                    </Button>{' '}
                    {signing && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className=""> Assinando Receita...</span>
                      </>
                    )}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}

      {showAtestado && (
        <Modal
          show={showAtestado}
          size="xl"
          centered
          onHide={() => {
            setAssinaturaDigital(false);
            setShowAtestado(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>ATESTADO MÉDICO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={2}>
                <>
                  {searchAtestados ? (
                    <>
                      {atestados.length > 0 ? (
                        <ButtonGroup vertical>
                          {atestados.map((atestado, index) => (
                            <Button
                              variant="outline-primary"
                              key={index}
                              onClick={() => handleVisualizarAtestado(atestado)}
                            >
                              {atestado.dataFormatada}
                            </Button>
                          ))}
                        </ButtonGroup>
                      ) : (
                        <>
                          {showAlertAtestados && (
                            <Alert variant={alert.variant}>
                              {alert.mensagem}
                            </Alert>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {showAlertAtestados && (
                        <Alert variant={alert.variant}>{alert.mensagem}</Alert>
                      )}
                    </>
                  )}
                </>
              </Col>
              <Col sm={10}>
                <Card className="p-1">
                  <Form noValidate>
                    <Form.Row>
                      <Form.Group as={Col} sm controlId="editNomePAC">
                        <Form.Label>
                          <strong>Nome do Paciente</strong>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          value={atestadoMedico.nomePaciente}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm controlId="editNOMEMED">
                        <Form.Label>
                          <strong>Nome do Médico</strong>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          value={atestadoMedico.nomeMedico}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Button size="md" onClick={handlePrescreverNovoAtestado}>
                      Novo Atestado
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      size="md"
                      onClick={handleRepetirAtestado}
                    >
                      Repetir Atestado
                    </Button>{' '}
                    <hr></hr>
                    {showRadioAtestado ? (
                      <Card>
                        <Form.Label>
                          <strong>Tipos de Atestado</strong>
                        </Form.Label>
                        <div
                          key={`radio`}
                          className="m-1"
                          style={{ fontSize: 11 }}
                        >
                          <Row xs={12} sm={4} md={4} lg={4}>
                            <Col sm={3}>
                              <Form.Check
                                label="Admissional"
                                name="groupAtestado"
                                type="radio"
                                id="Admissional"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Atestado Dia"
                                name="groupAtestado"
                                type="radio"
                                id="AtestadoDia"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Atestado Hora"
                                name="groupAtestado"
                                type="radio"
                                id="AtestadoHora"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Atestado Futebol"
                                name="groupAtestado"
                                type="radio"
                                id="AtestadoFutebol"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Atividade Fisica"
                                name="groupAtestado"
                                type="radio"
                                id="AtividadeFisica"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Carteira HAS"
                                name="groupAtestado"
                                type="radio"
                                id="CarteiraHAS"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                            </Col>
                            <Col sm={3} style={{ align: 'left' }}>
                              <Form.Check
                                label="Cateterismo"
                                name="groupAtestado"
                                type="radio"
                                id="Cate"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Cirurgia"
                                name="groupAtestado"
                                type="radio"
                                id="Cirurgia"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Concurso Público 1"
                                name="groupAtestado"
                                type="radio"
                                id="ConcursoPublico1"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Concurso Público 2"
                                name="groupAtestado"
                                type="radio"
                                id="ConcursoPublico2"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Crianca Esporte"
                                name="groupAtestado"
                                type="radio"
                                id="CriancaEsporte"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Declaracao"
                                name="groupAtestado"
                                type="radio"
                                id="Declaracao"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                            </Col>
                            <Col sm={3}>
                              <Form.Check
                                label="Alto Custo"
                                name="groupAtestado"
                                type="radio"
                                id="AltoCusto"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Fisioterapia"
                                name="groupAtestado"
                                type="radio"
                                id="Fisioterapia"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Hidroginastica"
                                name="groupAtestado"
                                type="radio"
                                id="Hidroginastica"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Internacao"
                                name="groupAtestado"
                                type="radio"
                                id="Internacao"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Marcapasso"
                                name="groupAtestado"
                                type="radio"
                                id="Marcapasso"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Nutricionista"
                                name="groupAtestado"
                                type="radio"
                                id="Nutricionista"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                            </Col>
                            <Col sm={3}>
                              <Form.Check
                                label="Psicóloga"
                                name="groupAtestado"
                                type="radio"
                                id="Psicologa"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Vacina"
                                name="groupAtestado"
                                type="radio"
                                id="Vacina"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Periop Alto Risco"
                                name="groupAtestado"
                                type="radio"
                                id="PeriopAltoRisco"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                              <Form.Check
                                label="Periop Baixo Risco"
                                name="groupAtestado"
                                type="radio"
                                id="PeriopBaixoRisco"
                                onClick={e => handleTipoAtestado(e.target.id)}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                    <Form.Row>
                      <Col sm>
                        <div
                          id="btnFonte"
                          className="d-flex justify-content-end"
                        >
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => alteraFont('a')}
                          >
                            A+
                          </Button>
                          <Button size="sm" variant="outline-secondary">
                            <strong>{fonteSize}</strong>
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => alteraFont('d')}
                          >
                            A-
                          </Button>
                        </div>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm>
                        <Form.Group size="sm" controlId="obs">
                          <Form.Label>
                            <strong>
                              Descrição do Atestado -{' '}
                              {atestadoMedico.codAtestado} -{' '}
                              {atestadoMedico.dataAtestado}
                            </strong>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ fontSize: '12px' }}
                            rows={15}
                            value={atestadoMedico.descricao ?? ''}
                            onChange={handleChangeDescricaoAtestado}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                  {showAlertAtestadoGravado && (
                    <Alert variant={alert.variant}>{alert.mensagem}</Alert>
                  )}
                  <Form.Check
                    type="switch"
                    id="atestado-switch"
                    defaultChecked={assinaturaDigital}
                    label="Assinatura Digital"
                    onChange={() => setAssinaturaDigital(!assinaturaDigital)}
                  />
                  <Card.Footer className="text-center">
                    <Button size="md" onClick={handleGravarAtestado}>
                      Gravar
                    </Button>{' '}
                    <Button
                      variant="warning"
                      onClick={() =>
                        handleImprimirAtestado(atestadoMedico, false)
                      }
                    >
                      Imprimir
                    </Button>{' '}
                    <Button
                      variant="info"
                      onClick={() =>
                        handleImprimirAtestado(atestadoMedico, true)
                      }
                    >
                      Timbrado
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setAssinaturaDigital(false);
                        setShowAtestado(false);
                      }}
                    >
                      Fechar
                    </Button>{' '}
                    {signing && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className=""> Assinando atestado...</span>
                      </>
                    )}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}

      {showRequisicao && (
        <Modal
          show={showRequisicao}
          size="xl"
          onHide={() => {
            setAssinaturaDigital(false);
            setShowRequisicao(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>REQUISIÇÃO DE EXAMES</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={2}>
                <>
                  {searchRequisicoes ? (
                    <>
                      {requisicoes.length > 0 ? (
                        <ButtonGroup vertical>
                          {requisicoes.map((req, index) => (
                            <Button
                              variant="outline-primary"
                              key={index}
                              onClick={() => handleVisualizarRequisicao(req)}
                            >
                              {req.dataFormatada}
                            </Button>
                          ))}
                        </ButtonGroup>
                      ) : (
                        <>
                          {showAlertRequisicoes && (
                            <Alert variant={alert.variant}>
                              {alert.mensagem}
                            </Alert>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {showAlertRequisicoes && (
                        <Alert variant={alert.variant}>{alert.mensagem}</Alert>
                      )}
                    </>
                  )}
                </>
              </Col>
              <Col sm={10}>
                <Card className="p-1">
                  <Form noValidate>
                    <Form.Row>
                      <Form.Group as={Col} sm controlId="editNomePAC">
                        <Form.Label>
                          <strong>Nome do Paciente</strong>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={requisicao.nomePaciente}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm controlId="editNOMEMED">
                        <Form.Label>
                          <strong>Nome do Médico</strong>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          defaultValue={requisicao?.nomeMedico}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Button onClick={handlePrescreverNovaRequisicao}>
                      Nova Requisição
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      onClick={handleRepetirRequisicao}
                    >
                      Repetir Requisição
                    </Button>{' '}
                    <hr></hr>
                    {showRadioRequisicao && (
                      <>
                        <Form.Row>
                          <Form.Group as={Col} sm={2} controlId="editCID">
                            <Form.Label>
                              <strong>CID</strong>
                            </Form.Label>
                            <Creatable
                              name="cid"
                              menuPlacement="top"
                              placeholder={requisicao?.cid}
                              options={cidValueLabelSorted}
                              value={dbprontuario.cid.filter(
                                opt => opt.label === selectedOption
                              )}
                              onCreateOption={opt =>
                                setRequisicao({ ...requisicao, cid: opt })
                              }
                              onChange={o => handleChangeCID(o)}
                            />
                          </Form.Group>
                          <Form.Group as={Col} sm={10} controlId="editINDICA">
                            <Form.Label>
                              <strong>INDICAÇÃO</strong>
                            </Form.Label>
                            <Form.Control
                              value={requisicao.indicacao || ''}
                              onChange={handleChangeDescricaoIndicacao}
                              onBlur={handleChangeDescricaoIndicacao}
                              onKeyUp={event => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  setShowRadioRequisicao(true);
                                }
                              }}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Card>
                          <Form.Label>
                            <strong>Tipos de Requisição</strong>
                          </Form.Label>
                          <div className="m-1" style={{ fontSize: 11 }}>
                            <Row>
                              <Col className="m-1" sm={6}>
                                <Form.Check
                                  label="Laboratoriais Simples"
                                  checked={
                                    requisicao.tipo === 'Laboratoriais Simples'
                                  }
                                  name="groupRequisicao"
                                  type="radio"
                                  id="LaboratoriaisSimples"
                                  onClick={e =>
                                    handleTipoRequisicao(e.target.id)
                                  }
                                />
                                <Form.Check
                                  label="Laboratoriais Especiais"
                                  checked={
                                    requisicao.tipo ===
                                    'Laboratoriais Especiais'
                                  }
                                  name="groupRequisicao"
                                  type="radio"
                                  id="LaboratoriaisEspeciais"
                                  onClick={e =>
                                    handleTipoRequisicao(e.target.id)
                                  }
                                />
                                <Form.Check
                                  label="Laboratoriais Completo"
                                  checked={
                                    requisicao.tipo === 'Laboratoriais Completo'
                                  }
                                  name="groupRequisicao"
                                  type="radio"
                                  id="LaboratoriaisCompleto"
                                  onClick={e =>
                                    handleTipoRequisicao(e.target.id)
                                  }
                                />
                                <Form.Check
                                  label="Outros Exames"
                                  checked={requisicao.tipo === 'Outros Exames'}
                                  name="groupRequisicao"
                                  type="radio"
                                  id="OutrosExames"
                                  onClick={e =>
                                    handleTipoRequisicao(e.target.id)
                                  }
                                />
                              </Col>
                            </Row>
                            <Button
                              variant="success"
                              onClick={() =>
                                setShowExamesCompl(!showExamesCompl)
                              }
                            >
                              {!showExamesCompl
                                ? 'Lista Exames'
                                : 'Fechar Lista'}
                            </Button>
                          </div>
                        </Card>
                      </>
                    )}
                    <Form.Row style={{ marginTop: 10 }}>
                      <Col sm>
                        <Form.Group
                          size="sm"
                          controlId="editExamesComplementares"
                        >
                          <Form.Label>
                            <strong>
                              Descrição da Requisição -{' '}
                              {requisicao.codRequisicao} -{' '}
                              {requisicao.dataRequisicao}
                            </strong>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ fontSize: '12px' }}
                            rows={
                              requisicao?.descricao === ''
                                ? 15
                                : requisicao?.descricao?.split('\n').length
                            }
                            value={requisicao.descricao ?? ''}
                            onChange={handleChangeDescricaoRequisicao}
                          />
                        </Form.Group>
                      </Col>
                      {showExamesCompl && (
                        <Box px={2}>
                          <Box sx={{ mb: 1 }}>
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() =>
                                setShowExamesCompl(!showExamesCompl)
                              }
                            >
                              Fechar Exames
                            </Button>{' '}
                          </Box>
                          <SimpleTreeView
                            aria-label="rich object"
                            defaultExpanded={['root']}
                            slots={{
                              expandIcon: FaArrowRight,
                              collapseIcon: FaAngleDown,
                            }}
                            getItemId={item => item.id} // Specify how to get the id for each item
                            onSelectedItemsChange={(e, itemId) =>
                              handleSelectExames(e, itemId)
                            }
                          >
                            {renderTreeExames(dbprontuario.complementares)}
                          </SimpleTreeView>
                        </Box>
                      )}
                    </Form.Row>
                  </Form>
                  {showAlertRequisicaoGravada && (
                    <Alert variant={alert.variant}>{alert.mensagem}</Alert>
                  )}
                  <Form.Check
                    type="switch"
                    id="req-switch"
                    defaultChecked={assinaturaDigital}
                    label="Assinatura Digital"
                    onChange={() => setAssinaturaDigital(!assinaturaDigital)}
                  />
                  <Card.Footer className="text-center">
                    <Button size="md" onClick={handleGravarRequisicao}>
                      Gravar
                    </Button>{' '}
                    <Button
                      variant="warning"
                      size="md"
                      onClick={() =>
                        handleImprimirRequisicao(requisicao, false)
                      }
                    >
                      Imprimir
                    </Button>{' '}
                    <Button
                      variant="info"
                      size="md"
                      onClick={() => handleImprimirRequisicao(requisicao, true)}
                    >
                      Timbrado
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      size="md"
                      onClick={() => {
                        setAssinaturaDigital(false);
                        setShowRequisicao(false);
                      }}
                    >
                      Fechar
                    </Button>{' '}
                    {signing && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className=""> Assinando exame...</span>
                      </>
                    )}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}

      {showLaudoModal ? (
        <Modal
          show={showLaudoModal}
          onHide={() => setShowLaudoModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>CONSULTA {laudo?.CODCONSULTA}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate className="my-3">
              <Form.Row>
                <Form.Group as={Col} controlId="formNomePaciente">
                  <Form.Label className="font-weight-bold">
                    Nome do Paciente
                  </Form.Label>
                  <Form.Control readOnly defaultValue={laudo?.NOMEPAC} />
                </Form.Group>
                <Form.Group as={Col} controlId="formDataNasc">
                  <Form.Label className="font-weight-bold">
                    Data de Nasc
                  </Form.Label>
                  <Form.Control readOnly defaultValue={laudo?.DTNPAC} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formConvenio">
                  <Form.Label className="font-weight-bold">Convênio</Form.Label>
                  <Form.Control readOnly defaultValue={laudo?.SIGLACONV} />
                </Form.Group>
                <Form.Group as={Col} controlId="formCodigo">
                  <Form.Label className="font-weight-bold">Código</Form.Label>
                  <Form.Control readOnly defaultValue={laudo?.CODCONSULTA} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formDataProntuario">
                  <Form.Label className="font-weight-bold">
                    Data do Prontuário
                  </Form.Label>
                  <Form.Control readOnly defaultValue={laudo?.DATACONSULTA} />
                </Form.Group>
                <Form.Group as={Col} controlId="formMedico">
                  <Form.Label className="font-weight-bold">
                    Médico que Realizou
                  </Form.Label>
                  <Form.Control readOnly defaultValue={laudo?.REALIZOU} />
                </Form.Group>
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} controlId="formQueixa">
                  <Form.Label className="font-weight-bold">
                    Queixa Principal e História
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={laudo?.QUEIXAS?.split('\n').length}
                    readOnly
                    defaultValue={laudo?.QUEIXAS}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formMedicacoes">
                  <Form.Label className="font-weight-bold">
                    Medicações em Uso
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={laudo?.MEDICAMENTOS?.split('\n').length}
                    readOnly
                    defaultValue={laudo?.MEDICAMENTOS}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formHabitos">
                  <Form.Label className="font-weight-bold">Hábitos</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={laudo?.HABITOS?.split('\n').length}
                    readOnly
                    defaultValue={laudo?.HABITOS}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formISDA">
                  <Form.Label className="font-weight-bold">ISDA</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={laudo?.ISDA?.split('\n').length}
                    readOnly
                    defaultValue={laudo?.ISDA}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formAntecedentesPessoais">
                  <Form.Label className="font-weight-bold">
                    Antecedentes Pessoais
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    rows={laudo?.ANTECEDENTES_PESSOAIS?.split('\n').length}
                    defaultValue={laudo?.ANTECEDENTES_PESSOAIS}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formAntecedentesFamiliares">
                  <Form.Label className="font-weight-bold">
                    Antecedentes Familiares
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={laudo?.ANTECEDENTES_FLIA?.split('\n').length}
                    readOnly
                    defaultValue={laudo?.ANTECEDENTES_FLIA}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formExameComplementares">
                  <Form.Label className="font-weight-bold">
                    Exame Complementares
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    rows={laudo?.EXAMES_COMPLEMENTARES?.split('\n').length + 1}
                    defaultValue={laudo?.EXAMES_COMPLEMENTARES}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formExameFisico">
                  <Form.Label className="font-weight-bold">
                    Exame Físico
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    rows={laudo?.EXAME_FISICO?.split('\n').length + 1}
                    defaultValue={laudo?.EXAME_FISICO}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formHipoteseDiagnostica">
                  <Form.Label className="font-weight-bold">
                    Hipótese Diagnóstica
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    rows={laudo?.HIPOTESE_DIAGNOSTICA?.split('\n').length + 1}
                    defaultValue={laudo?.HIPOTESE_DIAGNOSTICA}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formConduta">
                  <Form.Label className="font-weight-bold">Conduta</Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    rows={laudo?.CONDUTA?.split('\n').length}
                    defaultValue={laudo?.CONDUTA}
                  />
                </Form.Group>
              </Form.Row>
              {laudo?.CID && (
                <Form.Row>
                  <Form.Group as={Col} controlId="formObservacoes">
                    <Form.Label className="font-weight-bold">
                      Observações
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      readOnly
                      rows={laudo?.OBSERVACOES?.split('\n').length}
                      defaultValue={laudo?.OBSERVACOES}
                    />
                  </Form.Group>
                </Form.Row>
              )}
              {laudo?.CID && (
                <Form.Row>
                  <Form.Group as={Col} controlId="formCID1">
                    <Form.Label className="font-weight-bold">CID1</Form.Label>
                    <Form.Control readOnly defaultValue={laudo?.CID} />
                  </Form.Group>
                  {laudo?.CID2 && (
                    <Form.Group as={Col} controlId="formCID2">
                      <Form.Label className="font-weight-bold">CID2</Form.Label>
                      <Form.Control readOnly defaultValue={laudo?.CID2} />
                    </Form.Group>
                  )}
                  {laudo?.CID3 && (
                    <Form.Group as={Col} controlId="formCID3">
                      <Form.Label className="font-weight-bold">CID3</Form.Label>
                      <Form.Control readOnly defaultValue={laudo.CID3} />
                    </Form.Group>
                  )}
                </Form.Row>
              )}
              <Card className="mt-3 text-center">
                <Card.Body>
                  <Card.Text>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:
                    </i>
                    <strong className="mr-auto">
                      {' '}
                      {prefixo(laudo)} {laudo?.REALIZOU}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(laudo || ''))}
                    </i>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}

      {showEletroModal && (
        <div
          style={{
            position: 'fixed',
            zIndex: 1,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            padding: 0,
            width: '100vw',
            height: '100vh',
            maxWidth: 'none !important',
            overflow: 'auto',
          }}
        >
          <div
            className="modal-content"
            style={{ width: '100% !important', height: '100vh' }}
          >
            <FormEcg
              ecg={laudo}
              isFromTab={true}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              setShowEletroModal={setShowEletroModal}
              showEletroModal={showEletroModal}
            />
          </div>
        </div>
      )}

      {showStressModal && (
        <Modal
          show={showStressModal}
          onHide={() => setShowStressModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportStress />
          </Modal.Body>
        </Modal>
      )}

      {showHolterModal && (
        <Modal
          show={showHolterModal}
          onHide={() => setShowHolterModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportHolter />
          </Modal.Body>
        </Modal>
      )}

      {showEcocongModal && (
        <Modal
          show={showEcocongModal}
          onHide={() => setShowEcocongModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportCongTXT />
          </Modal.Body>
        </Modal>
      )}

      {showErgoModal && (
        <Modal
          show={showErgoModal}
          onHide={() => setShowErgoModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportErgo />
          </Modal.Body>
        </Modal>
      )}

      {showMapaModal && (
        <Modal
          show={showMapaModal}
          onHide={() => setShowMapaModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportMapa />
          </Modal.Body>
        </Modal>
      )}

      {showFetalModal && (
        <Modal
          show={showFetalModal}
          onHide={() => setShowFetalModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportFetal />
          </Modal.Body>
        </Modal>
      )}

      {showTransModal && (
        <Modal
          show={showTransModal}
          onHide={() => setShowTransModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportTrans />
          </Modal.Body>
        </Modal>
      )}

      {showEcodopplerModal && (
        <Modal
          show={showEcodopplerModal}
          onHide={() => setShowEcodopplerModal(false)}
          size="xl"
          scrollable
        >
          <Modal.Body>
            <ReportDoppler />
          </Modal.Body>
        </Modal>
      )}

      {showExamesLab ? (
        <Modal
          show={showExamesLab}
          onHide={() => setShowExamesLab(false)}
          size="xl"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>
              EXAMES LABORATORIAIS - CONSULTA: {exames[0]?.CODCONSULTA}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <strong>
                NOME: {atual?.NOMEPAC} - DT NASC: {atual?.dataNasc}
              </strong>
            </div>
            <hr></hr>
            <div>
              <Alert variant="warning">
                <Alert.Heading>
                  Clique no botão para visualizar o Gráfico/Tabela dos exames e
                  selecione um exame!
                </Alert.Heading>
                <div className="d-flex justify-content-center">
                  <Button
                    variant="outline-primary"
                    onClick={() =>
                      setShowGraficoExamesLab(!showGraficoExamesLab)
                    }
                  >
                    {!showGraficoExamesLab
                      ? 'Gráfico de Exames Laboratoriais'
                      : 'Fechar Gráfico de Exames'}
                  </Button>
                  {' . '}
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShowTabelaExamesLab(!showTabelaExamesLab);
                    }}
                  >
                    {!showTabelaExamesLab
                      ? 'Tabela de Exames Laboratoriais'
                      : 'Fechar Tabela de Exames'}
                  </Button>{' '}
                </div>
              </Alert>
            </div>
            {showGraficoExamesLab ? (
              <Row>
                <Col sm={2}>
                  {viewexames.length > 0 ? (
                    <ButtonGroup vertical>
                      {viewexames.map((exame, index) => (
                        <Button
                          size="sm"
                          variant="outline-primary"
                          key={index}
                          onClick={() => handleExibirResultadoExames(exame)}
                        >
                          {exame.EXAME}
                        </Button>
                      ))}
                    </ButtonGroup>
                  ) : null}
                </Col>
                <Col sm={10}>
                  <>
                    <Col>
                      {viewexames.length > 0 && (
                        <Chart
                          chartType="LineChart"
                          width="100%"
                          height="600px"
                          data={chartExames}
                          options={{ series: [{ color: '#F0011F' }] }}
                        />
                      )}
                    </Col>
                    <hr></hr>
                    <Col></Col>
                  </>
                </Col>
              </Row>
            ) : null}
            {showTabelaExamesLab ? (
              <Card>
                {uniqueExames.length > 0 ? (
                  <ButtonGroup size="sm" style={{ flexWrap: 'wrap' }}>
                    {uniqueExames.map((exame, index) => (
                      <Button
                        size="sm"
                        variant="outline-primary"
                        key={index}
                        onClick={() => handleExibirTabelaExames(exame)}
                      >
                        {exame.EXAME}
                      </Button>
                    ))}
                  </ButtonGroup>
                ) : null}
                <Table responsive="sm" striped bordered hover size="sm">
                  <thead>
                    <tr className="text-center">
                      <th>EXAME</th>
                      <th>DATA</th>
                      <th>RESULTADO</th>
                      <th>OBSERVAÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableExames?.map((lab, index) => (
                      <tr
                        key={index}
                        className="text-center"
                        style={{ backgroundColor: getRandomColor(lab) }}
                      >
                        <td>{lab?.EXAME}</td>
                        <td>{lab?.dataExame}</td>
                        <td>{lab?.VALOR}</td>
                        <td>{lab?.RESULTADO}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            ) : null}

            <Form className="my-1">
              <Row>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 5,
                    }}
                  >
                    <Button
                      onClick={handleExcluirZerados}
                      size="sm"
                      variant="warning"
                    >
                      Limpar Zerados e Gravar Exames
                    </Button>
                    <Button onClick={() => setShowExamesLab(false)} size="sm">
                      Fechar Lista de Exames
                    </Button>
                  </div>
                </Col>
              </Row>

              <hr />
              <Table responsive="sm" striped bordered hover size="sm">
                <thead>
                  <tr className="text-center">
                    <th>CODLAB</th>
                    <th>CODEXAME</th>
                    <th>DATA</th>
                    <th>EXAME</th>
                    <th>RESULTADO</th>
                    <th>OBSERVAÇÃO</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {laboratoriais?.map((lab, index) => (
                    <tr key={lab.CODLABOR} className="text-center">
                      <td>{lab?.CODLABOR}</td>
                      <td>{lab?.CODEXAME}</td>
                      <td>
                        {format(parseISO(lab?.DATALABOR), 'dd/MM/yyyy', {
                          locale: pt,
                        })}
                      </td>
                      <td>{lab?.EXAME}</td>
                      <td>
                        <Form.Control
                          id={index}
                          name="toggleValor"
                          autoComplete="off"
                          pattern="[0-9]*"
                          type="number"
                          onBlur={e => {
                            e.preventDefault();
                            if (isNaN(e.target.value)) {
                              toast.error('Campo aceita somente Números!');
                              toggleValor[index].select();
                            } else {
                              setValor(Number(e.target.value));
                              const updValor = laboratoriais.map(labor =>
                                labor.CODLABOR === lab.CODLABOR
                                  ? { ...labor, VALOR: Number(e.target.value) }
                                  : labor
                              );
                              setLaboratoriais(updValor);
                            }
                          }}
                          onWheel={e => e.currentTarget.blur()}
                          onChange={e => setValor(Number(e.target.value))}
                          onKeyDown={e => {
                            if (
                              [
                                'ArrowUp',
                                'ArrowDown',
                                'ArrowLeft',
                                'ArrowRight',
                              ].includes(e.key)
                            ) {
                              e.preventDefault();
                            }

                            if (e.key === 'ArrowUp') {
                              if (index === 0) {
                                toggleValor[index].focus();
                                toggleValor[index].select();
                              } else {
                                toggleValor[index - 1].focus();
                                toggleValor[index - 1].select();
                              }
                            } else if (
                              e.key === 'ArrowDown' &&
                              index < laboratoriais.length - 1
                            ) {
                              if (index === 0) {
                                toggleValor[index + 1].focus();
                                toggleValor[index + 1].select();
                              } else {
                                toggleValor[index + 1].focus();
                                toggleValor[index + 1].select();
                              }
                            } else if (e.key === 'ArrowLeft') {
                              toggleResultado[index].select();
                            } else if (e.key === 'ArrowRight') {
                              toggleResultado[index].select();
                            }
                          }}
                          defaultValue={lab?.VALOR === 0 ? '' : lab?.VALOR}
                        />
                      </td>
                      <td>
                        <Form.Control
                          id={lab?.CODLABOR}
                          name="toggleResultado"
                          autoComplete="off"
                          type="text"
                          onBlur={e => {
                            setResultado(e.target.value);
                            const updResultado = laboratoriais.map(l =>
                              l.CODLABOR === lab.CODLABOR
                                ? { ...l, RESULTADO: e.target.value }
                                : l
                            );
                            setLaboratoriais(updResultado);
                          }}
                          onChange={e => setResultado(e.target.value)}
                          onKeyDown={e => {
                            if (e.key === 'ArrowUp') {
                              if (index === 0) {
                                toggleResultado[index].focus();
                                toggleResultado[index].select();
                              } else {
                                toggleResultado[index - 1].focus();
                                toggleResultado[index - 1].select();
                              }
                            } else if (
                              e.key === 'ArrowDown' &&
                              index < laboratoriais.length - 1
                            ) {
                              if (index === 0) {
                                toggleResultado[index + 1].focus();
                                toggleResultado[index + 1].select();
                              } else {
                                toggleResultado[index + 1].focus();
                                toggleResultado[index + 1].select();
                              }
                            } else if (e.key === 'ArrowLeft') {
                              toggleValor[index].select();
                            } else if (e.key === 'ArrowRight') {
                              toggleValor[index].select();
                            }
                          }}
                          defaultValue={lab?.RESULTADO}
                        />
                      </td>
                      {lab.CODEXAME === 33 && (
                        <td>
                          <Button
                            onClick={() => {
                              let valorCT = 0;
                              let valorHDL = 0;
                              let valorTRI = 0;
                              let valorLDL = 0;
                              laboratoriais.forEach(labo => {
                                if (labo.CODEXAME === 1) valorCT = labo.VALOR;
                                if (labo.CODEXAME === 2) valorHDL = labo.VALOR;
                                if (labo.CODEXAME === 4) valorTRI = labo.VALOR;
                              });
                              valorLDL = valorCT - valorHDL - valorTRI / 5;

                              const updValor = laboratoriais.map(labor =>
                                labor.CODEXAME === 33
                                  ? { ...labor, VALOR: valorLDL }
                                  : labor
                              );
                              setLaboratoriais(updValor);
                            }}
                          >
                            Calc
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Card className="mt-3 text-center">
                <Card.Body>
                  <Card.Text>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:
                    </i>
                    <strong className="mr-auto">
                      {' '}
                      {prefixo(atual)} {atual?.REALIZOU}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital:{' '}
                      {md5(JSON.stringify(laboratoriais || ''))}
                    </i>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}

      {loading && <Loading />}
    </>
  );
}
